import React from 'react'
import {IonCard, IonCardContent, IonItem, IonLabel} from "@ionic/react";
import {Link} from "react-router-dom";

const DownloadBtns = (props: any) => {
    return (
        <IonCard>
            <IonItem color="light" lines={"full"}>
                <IonLabel className={'vertical-align m-0 card-title small-title'}>
                    Get the app
                </IonLabel>
            </IonItem>
            <IonCardContent>
                <div className={'download-btns'}>
                    <a href={'https://apps.apple.com/us/app/serveometer/id1556055486'} target={'_blank'}>
                        <img src={'assets/imgs/appstore.png'}
                             alt={'Download on the App Store'}
                             className={'mb-10'}
                             width={'100%'}/>
                    </a>
                    <a href={'https://play.google.com/store/apps/details?id=serveometer.com.client'} target={'_blank'}>
                        <img src={'assets/imgs/playstore.png'}
                             alt={'Download on Google Play'}
                             width={'100%'}/>
                    </a>
                </div>
            </IonCardContent>
        </IonCard>
    )
};

export default DownloadBtns;