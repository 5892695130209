import React, {useEffect, useState} from 'react';

import Serveometer from "../../components/Serveometer";
import Search from "../../assets/js/Search";
import Map from "../../components/Location/Map";
import LocationScores from "../../components/Location/Scores";

const Scores = (props: any) => {

    // Declare empty object
    let init: any = {};

    const [locationId] = useState(props.match.params.id);
    const [location, setLocation] = useState(init);
    const [scores, setScores] = useState(init);

    useEffect(() => {
        Search.searchById(locationId)
            .then(data => {
                setLocation(data.attributes);

            });
        Search.getScorableSubmissionsByLocationId(locationId, 50, 1)
            .then(data => {
                setScores(data);
            });
    }, [location.name]);

    return (
        <Serveometer showHeader={true} showBackButton={true} page={location.name + ' Scores'} breadcrumbTrail={
            [
                {pagetitle: location.name, 'pathname': '/location/' + locationId}
            ]
        } render={
            <div>
                <Map location={location} locationId={locationId}/>
                {
                    <LocationScores scores={scores}
                                    title={'All Scores'}
                                    locationId={locationId}
                                    isScoreIndex={1}/>
                }
            </div>
        }/>
    );
};

export default Scores;
