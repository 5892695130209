import React from 'react'
import {IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonLabel} from "@ionic/react";
import Helper from "../../assets/js/Helper";
import Chevrons from "../Score/Chevrons";
import {listCircleOutline} from "ionicons/icons";
import {Link} from "react-router-dom";

const Scores = (props: any) => {
    return (
        <IonCard className={'recent-subs'}>
            <IonItem color="light" lines={'full'} className={'ion-no-padding'}>
                <IonLabel className={'vertical-align m-0 ml-15 card-title small-title'}>
                    {props.title ? props.title : 'Scores'}
                </IonLabel>
            </IonItem>
            {
                props.scores.length ?
                    <IonCardContent className={'p-5'}>
                        {
                            props.scores.map((sub, sub_index) => {
                                let sub_score = Helper.calculateScore(sub.attributes.scores[0]);
                                return (
                                    <IonItem lines={'full'} className={'ion-no-padding ml-10 mr-10'}
                                             key={sub_index}>
                                        <Link to={'/location/' + props.locationId + '/scores/' + sub.id}>
                                            <IonIcon
                                                src={'/assets/imgs/smiley' + sub_score + '.svg'}
                                                className={'questionIcon mr-20'}/>
                                            <IonLabel className={'vertical-align'}>
                                                <div>
                                                    <strong>{sub.attributes.submitted_by}</strong>
                                                    {
                                                        sub.attributes.comment ?
                                                            <p><strong>&quot;
                                                                {
                                                                    sub.attributes.comment.length > 40
                                                                        ? sub.attributes.comment.substring(0, 40) + '...'
                                                                        : sub.attributes.comment
                                                                }
                                                                &quot;</strong></p>
                                                            : ''
                                                    }
                                                    <Chevrons score={sub_score} size={'small'}/>
                                                </div>
                                                <div className={'accordion__button'}/>
                                            </IonLabel>
                                        </Link>
                                    </IonItem>
                                )
                            })
                        }
                        {
                            !props.isScoreIndex ?
                                <IonButton data-cy="viewAllScoresButton" className={'action primary m-10'}
                                           routerLink={'/location/' + props.locationId + '/scores'}
                                           expand={'full'}>
                                    <IonIcon slot="start" icon={listCircleOutline}/>
                                    View All Scores
                                </IonButton>
                                : ''
                        }
                    </IonCardContent>
                    :
                    <IonCardContent>
                        <h3>
                            {props.locationName} has no scores yet. <a href={'/location/' + props.locationId + '/begin'}
                                                                       title={'Score ' + props.locationName}>Submit one now.</a>
                        </h3>
                    </IonCardContent>
            }
        </IonCard>
    );
};

export default Scores;