import axios from "axios";
import {SMR_ENDPOINT} from "../../constants";

export default {
    login: async function (email, password) {
        try {
            const response = await axios.post(SMR_ENDPOINT + '/auth/login', {
                email: email,
                password: password,
                remember_me: false
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            //console.log("login response", response);
            return response;
        } catch (error) {
            //console.log(error);
        }
    },

    isAuthenticated: function () {
        return window.localStorage.getItem("SMR_ACCESS_TOKEN") && window.localStorage.getItem("SMR_AUTH_USER_ID");
    },
    setAuthentication: function (data) {
        window.localStorage.setItem("SMR_TOKEN", data.token);
        window.localStorage.setItem("SMR_TOKEN_EXPIRES", data.expires_at);
        return true;
    },
    introSeen: function () {
        return !!window.localStorage.getItem('SMR_INTRO_SEEN');
    },
    setIntroSeen: function () {
        window.localStorage.setItem('SMR_INTRO_SEEN', '1');
        return true;
    },
    requestPasswordReset: function (email) {
        return axios.post(
            SMR_ENDPOINT + '/password/email',
            {
                email: email
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    },
    resetPassword: function (email, password, passwordConfirm, token) {
        return axios.post(
            SMR_ENDPOINT + '/password/reset',
            {
                email: email,
                password: password,
                password_confirmation: passwordConfirm,
                token: token
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    },
    getUser: async function () {
        const response = await axios.get(SMR_ENDPOINT + '/auth/user', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
            }
        });
        return response;
    },

    verify: function (pin) {
        return axios.post(
            SMR_ENDPOINT + '/auth/verify',
            {
                pin: pin
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                }
            });
    },

    resetPin: function () {
        return axios.get(SMR_ENDPOINT + '/auth/reset-pin', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
            }
        });
    }

}
