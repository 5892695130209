import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {IonCol, IonGrid, IonIcon, IonRow} from "@ionic/react";
import {home} from "ionicons/icons";

const Breadcrumbs = (props: any) => {
    const location = useLocation();

    return (
        <IonGrid className={'breadcrumbs container'}>
            <IonRow>
                <IonCol size={'12'} className={'vertical-align'}>
                    <div className={'crumb'}>
                        <Link to={'/'} title={'Home'}>
                            <IonIcon icon={home} color="primary"/>
                        </Link>
                    </div>
                    {
                        props.trail ?
                            props.trail.map((crumb: any, index: any) => {
                                if (index < props.trail.length) {
                                    return (
                                        <div className={'crumb'} key={index}>
                                            <a key={index} style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }} onClick={() => {
                                                window.history.go(-1);
                                            }} title={crumb.pagetitle}>
                                                {crumb.pagetitle}
                                            </a>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={'crumb'} key={index}>
                                            <Link key={index} to={crumb.pathname} title={crumb.pagetitle}>
                                                {crumb.pagetitle}
                                            </Link>
                                        </div>
                                    )
                                }
                            })
                            : ''
                    }
                    <div className={'crumb'}>
                        <Link to={location.pathname} title={props.pagetitle}>
                            {props.pagetitle}
                        </Link>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
};

export default Breadcrumbs;