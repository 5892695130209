import React, {useContext, useEffect, useState} from 'react';
import {
    getPlatforms,
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonRow,
    IonText,
    IonToolbar
} from "@ionic/react";
import axios from "axios";
import {eye, handRightSharp, person} from "ionicons/icons";
import {SMR_ENDPOINT} from '../../constants';

import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers";
import {Link} from "react-router-dom";
import Pages from "../../assets/js/Pages";
import {AppContext} from "../../State";
import Auth from "../../assets/js/Auth";
import { Analytics } from 'aws-amplify';
import Serveometer from "../../components/Serveometer";
import RegisterForm from "../../components/RegisterFrom";
import Locations from "../../assets/js/Locations";

const ProposeLocation = (props: any) => {
    const {state, dispatchState} = useContext(AppContext);


    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showLoading, setShowLoading] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [showTerms, setShowTerms] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [placeRegistered, setPlaceRegistered] = useState(false);
    const [registeredId, setRegisteredId] = useState('');

    const togglePassword = (type) => {
        if (type === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    }


    const {handleSubmit, register} = useForm();

    const updateTerms = (val) => {
        var els = document.getElementsByName("terms");
        for (var i = 0; i < els.length; i++) {
            let checkbox = (els[i] as HTMLInputElement)
            checkbox.value = (val ? "checked" : "0");
        }
    }

    let platforms = getPlatforms();

    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'terms-and-conditions';

    let url = props.match.url;

    let registerData: any = {};

    const onSubmit = (data: any) => {
        setSubmitting(true);
        setShowLoading(true);

        axios.post(SMR_ENDPOINT + '/auth/register', {
            email: data.email,
            password: data.password,
            password_confirmation: data.passwordConfirmation,
            firstname: data.firstName,
            lastname: data.lastName,
            receive_marketing: data.marketing === 'on' ? "1" : "0",
            platforms: JSON.stringify(platforms)

        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                setSubmitting(false);
                window.localStorage.removeItem('SMR_IS_NEW_USER');
                if (response.status === 201) {
                    window.localStorage.setItem('SMR_ACCESS_TOKEN', response.data.access_token);
                    window.localStorage.setItem('SMR_AUTH_USER_ID', response.data.user_id);
                    dispatchState({type: 'setUser', user: response.data});

                    //TODO Move this to a single helper  SAME code is duplicated in Login.tsx
                    Auth.getUser()
                        .then(resp => {
                            if (resp.data.email !== undefined && 'undefined') {
                                Analytics.updateEndpoint({
                                    address: resp.data.email, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
                                    attributes: {
                                        // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                                        //hobbies: ['piano', 'hiking'],
                                        //TODO add disablility
                                    },
                                    channelType: 'APNS', // The channel type. Valid values: APNS, GCM
                                    demographic: {
                                        appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
                                        locale: 'en_GB', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
                                        make: state.device.manufacturer, // The manufacturer of the endpoint device, such as Apple or Samsung.
                                        model: state.device.model, // The model name or number of the endpoint device, such as iPhone.
                                        modelVersion: state.device.uuid, // The model version of the endpoint device.
                                        platform: state.device.osVersion, // The platform of the endpoint device, such as iOS or Android.
                                        platformVersion: state.device.operatingSystem, // The platform version of the endpoint device.
                                        timezone: 'xxxxxx' // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
                                    },
                                    location: {
                                        //city: 'xxxxxx', // The city where the endpoint is located.
                                        //country: 'xxxxxx', // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
                                        latitude: state.myLatLng.lat, // The latitude of the endpoint location, rounded to one decimal place.
                                        longitude: state.myLatLng.lng, // The longitude of the endpoint location, rounded to one decimal place.
                                        // postalCode: 'xxxxxx', // The postal code or zip code of the endpoint.
                                        // region: 'xxxxxx' // The region of the endpoint location. For example, in the United States, this corresponds to a state.
                                    },
                                    metrics: {
                                        // Custom metrics that your app reports to Amazon Pinpoint.
                                    },
                                    /** Indicates whether a user has opted out of receiving messages with one of the following values:
                                     * ALL - User has opted out of all messages.
                                     * NONE - Users has not opted out and receives all messages.
                                     */
                                    optOut: resp.data.receive_marketing ? 'NONE' : 'ALL',
                                    // Customized userId
                                    userId: resp.data.id,
                                    // User attributes
                                    userAttributes: {
                                        firstName: resp.data.firstName,
                                        disability: [resp.data.disability]
                                        // ...
                                    }
                                }).then((resp) => {
                                    Analytics.record('_userauth.sign_up');
                                });
                            }
                        }).catch(err => {

                    });

                    window.location.href = '/verify';
                    setShowLoading(false);

                } else {
                    setShowLoading(false);
                    setAlertText('Something went wrong. Please check the fields and try again.');
                    setShowAlert(true);
                }
            }, (error) => {
                if (error.response.status === 422) {
                    if (error.response.data.errors.password !== undefined && 'undefined') {
                        setAlertText(error.response.data.errors.password[0]);
                        setShowAlert(true);
                    } else if (error.response.data.errors.email !== undefined && 'undefined') {
                        setAlertText(error.response.data.errors.email[0]);
                        setShowAlert(true);
                    } else {
                        setAlertText('Something went wrong. Please check the fields and try again.');
                        setShowAlert(true);
                    }
                }
                if (error.response.status === 500) {
                    setAlertText("Something has gone wrong. Please try again or contact us to let us know.");
                    setShowAlert(true);
                }
                setShowLoading(false);
                setSubmitting(false);

            });
    };

    const submitPlace = (data) => {
        setShowLoading(true);
        Locations.registerLocation(data).then((response) => {
            if(response){
                setShowLoading(false);
                setPlaceRegistered(true);
                setRegisteredId(response.place.id);
            } else {
                setShowLoading(false);
            }
        }).catch((err) => {
            setShowLoading(false);
        })
    }

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={'Propose location'} showBackButton={true} showHeader={'true'} render={
            <div className={'container'}>
                <IonCard className={'auth-form'}>
                    <IonCardContent>
                        {
                            !showSuccess ?
                                <>
                                    <h1 style={{fontWeight: 'bold'}}>SUGGEST A NEW PLACE</h1>
                                    <IonGrid className={'container'}>
                                        <IonRow>
                                            <IonCol size={'4'}>
                                                <IonImg src={'assets/imgs/holder.png'}></IonImg>
                                            </IonCol>
                                            <IonCol size={'8'}>
                                                Good news! If you are unable to find the location you expect, you can submit it here.
                                            </IonCol>
                                        </IonRow>
                                        {
                                            Auth.isAuthenticated() && state.userVerified ?
                                                placeRegistered ?
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className={'action primary'} routerLink={'/location/' + registeredId + '/claim'} expand={'full'} >
                                                                    Claim this place
                                                                </IonButton>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonButton className={'action primary'} onClick={() => {
                                                                    localStorage.setItem('SMR_SCORE_PENDING', '1');
                                                                    window.location.href = '/location/' + registeredId + '/score';
                                                                }} expand={'full'} >
                                                                    Score this place
                                                                </IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                    :
                                                <>
                                                    <form onSubmit={handleSubmit(submitPlace)}>
                                                        <IonRow>
                                                            <IonCol>
                                                                Welcome back.
                                                            </IonCol>
                                                        </IonRow>

                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'name'} placeholder={'Place Name'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'address'} placeholder={'Address Line 1'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'address2'} placeholder={'Address Line 2'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'city'} placeholder={'Town'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'province'} placeholder={'Province'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'postalCode'} placeholder={'Post Code'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'country'} placeholder={'Country'} value={'UK'} readonly/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'description'} placeholder={'Description'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'website'} placeholder={'Website'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'phone'} placeholder={'Phone'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'domain'} placeholder={'Web address'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'twitter'} placeholder={'Twitter'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'facebook'} placeholder={'Facebook'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        {/*<IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'categories'} placeholder={'Categories'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput autocapitalize={'on'} name={'primaryCategory'} placeholder={'Primary Category'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem lines={'full'}>
                                                                    <IonInput ref={register} autocapitalize={'on'} name={'submissionComment'} placeholder={'Submission Comment'}/>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>*/}
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonButton className={'action primary'} expand={"full"} type={'submit'} >Submit</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    </form>
                                                </>
                                                :
                                                <>
                                                    <IonRow>
                                                        <IonCol>
                                                            To suggest a new location, you simply need to be a registered user of ServeOmeter.
                                                            <br/><br/>
                                                            Choose an option below...
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className={'action secondary'} expand={"full"} onClick={(e) => {
                                                                localStorage.setItem('SMR_PROPOSING', '1');
                                                                window.location.href = '/login';
                                                            }}>Login</IonButton>
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonButton className={'action primary'} expand={"full"} onClick={() => {
                                                                setShowRegistration(true);
                                                            }}>Create your free account</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                        }

                                    </IonGrid>
                                    {
                                        !showRegistration ?
                                            ''
                                            :
                                            <RegisterForm registerFrom={'/register/form'} loginCheck={false} location={false} />
                                    }
                                </>
                                :
                                <>
                                    <h1 style={{fontWeight: 'bold'}}>SUGGEST A NEW PLACE</h1>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size={'4'}>
                                                <IonImg src={'assets/imgs/holder.png'}></IonImg>
                                            </IonCol>
                                            <IonCol size={'8'}>
                                                Good news! If you are unable to find the location you expect, you can submit it here.
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </>
                        }

                    </IonCardContent>
                </IonCard>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Register'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
                <IonModal
                    isOpen={showTerms}
                    cssClass='my-custom-class'
                    onDidDismiss={() => setShowTerms(false)}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot={'end'}>
                                <IonButton slot={'end'} onClick={() => setShowTerms(false)}>&times;</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div>
                            {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                            <div className="ion-padding">
                                <div className="ion-padding" dangerouslySetInnerHTML={{
                                    __html: pageDetails.content
                                }}/>
                            </div>


                        </div>
                    </IonContent>
                </IonModal>
            </div>
        }/>
    )
};

export default ProposeLocation;
