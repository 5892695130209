import React, {useEffect, useState} from 'react';
import {IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonImg, IonItem, IonLabel, IonModal, IonRow} from '@ionic/react';
import Serveometer from "../../components/Serveometer";
import Search from "../../assets/js/Search";

const LocationDetailImages = (props: any) => {

    // Declare empty object
    let init: any = {};

    const [location, setLocation] = useState(init);

    const [imageToView, setImageToView] = useState('');
    const [showImage, setShowImage] = useState(false);

    const idSearch = (locationId: any) => {
        Search.searchById(locationId)
            .then(data => {
                setLocation(data.attributes);
                window.localStorage.setItem('current_location', JSON.stringify(data.attributes));
            });
    };

    useEffect(() => {
        if (window.localStorage.getItem('current_location')) {
            // @ts-ignore
            let loc = JSON.parse(window.localStorage.getItem('current_location'));
            if (loc.id === props.match.params.id) {
                setLocation(loc);
            } else {
                idSearch(props.match.params.id);
            }
        }
    }, [location.name, props.match.params.id]);

    return (
        <Serveometer page={'All images for ' + location.name + ', ' + location.address} showHeader={true} showBackButton={true} render={
            <div>
                <IonCard>
                    <IonItem>
                        <IonLabel> Location Photos for {location.name + ', ' + location.address}</IonLabel>
                    </IonItem>

                    <IonCardContent>
                        <IonGrid>
                            <IonRow>
                                {
                                    location.imageURLs.map(img => {
                                        return (
                                            <IonCol size={'6'}>
                                                <IonImg src={img} onClick={() => {
                                                    setImageToView(img);
                                                    setShowImage(true);
                                                }}/>
                                            </IonCol>
                                        )
                                    })
                                }
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonModal
                    isOpen={showImage}
                    cssClass={'image-modal'}
                    swipeToClose={true}
                    backdropDismiss={true}
                    onDidDismiss={() => setShowImage(false)}>
                    <IonCard>
                        <IonCardTitle>
                            <IonItem onClick={() => {
                                setShowImage(false)
                            }} class={'ion-float-right'}>
                                &times;
                            </IonItem>
                        </IonCardTitle>
                        <IonImg src={imageToView}/>
                        <IonItem/>
                    </IonCard>
                </IonModal>
            </div>
        }/>
    );
};

export default LocationDetailImages;
