import React, {useEffect, useState} from 'react';
import Serveometer from "../../../components/Serveometer";
import {getPlatforms, IonAlert, IonButton, IonCard, IonCardContent, IonCheckbox, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonLoading, IonRow, IonText} from "@ionic/react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import axios from "axios";
import {SMR_ENDPOINT} from "../../../constants";
import DisabilityQuestion from "../../../components/DisabilityQuestion";
import Auth from "../../../assets/js/Auth";

const Edit: React.FC = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [disability, setDisability] = useState('');

    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const validationSchema = yup.object().shape({
        firstname: yup.string().nullable().notRequired(),
        lastname: yup.string().nullable().notRequired(),
        email: yup.string().nullable().notRequired().email(),
        passwordVerify: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,30}$/, 'The password must be 7–30 characters. Please include at least 1 lower case letter, at least 1 upper case letter and at least 1 number'),
    });

    const {handleSubmit, errors, register} = useForm({
        criteriaMode: "all",
        resolver: yupResolver(validationSchema)
    });

    let platforms = getPlatforms();

    const buildUpdate = (data: any) => {
        setShowLoading(true)
        let obj = {
            platforms: JSON.stringify(platforms)
        }
        let d = Object.keys(data);
        for (let i = 0; i < d.length; i++) {
            if (data[d[i]] !== "") {
                obj[d[i]] = data[d[i]];
            }
        }
        onSubmit(obj);
    }

    const onSubmit = (data: any) => {
        axios.patch(SMR_ENDPOINT + '/auth/update', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem('SMR_ACCESS_TOKEN')
            }
        })
            .then((response) => {
                if (response.status === 202) {
                    setAlertText('Your details have been updated.')
                    setShowAlert(true);
                    setShowLoading(false)
                } else {
                    setAlertText('Something went wrong. Please try again.')
                    setShowAlert(true);
                    setShowLoading(false)
                }
            })
            .catch(error => {
                setAlertText('Something went wrong. Please try again.')
                setShowAlert(true);
                setShowLoading(false)
            });
    }

    useEffect(() => {
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    if (resp.data.email !== undefined && 'undefined') {
                        setMyEmail(resp.data.email);
                        setFirstName(resp.data.firstname);
                        setLastName(resp.data.lastname);
                        setDisability(resp.data.disability);
                    }
                }).catch(err => {
                setAlertText('Something has gone wrong. Please try again.');
                setShowAlert(true);
            });
        }
    })

    return (
        <Serveometer page={'Edit Profile'} showBackButton={true} showHeader={'true'} render={
            <div>
                <IonCard>
                    <IonCardContent>
                        Edit your profile details below.
                    </IonCardContent>
                </IonCard>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton className={'action secondary'} expand={"full"} routerLink={'/extras/my-profile/change-password'}>Change Password</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <form onSubmit={handleSubmit(buildUpdate)}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem lines={'full'} className={`${errors && errors['firstname'] ? "has-error" : ""}`}>
                                    <IonInput autocapitalize={'on'} name={'firstname'} placeholder={'First Name'} value={firstName} ref={register} autocomplete="given-name"/>
                                </IonItem>
                                {errors && errors['firstname'] && (
                                    <IonText color="danger" className="ion-padding-start">
                                        <small>{errors['firstname'].message}</small>
                                    </IonText>
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['lastname'] ? "has-error" : ""}`}>
                                        <IonInput autocapitalize={'on'} name={'lastname'} placeholder={'Last Name'} value={lastName} ref={register} autocomplete="family-name"/>
                                    </IonItem>
                                    {errors && errors['lastname'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['lastname'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem lines={'full'} className={`${errors && errors['email'] ? "has-error" : ""}`}>
                                    <IonInput name={'email'} type={'email'} placeholder={'Email'} value={myEmail} ref={register} autocomplete="email"/>
                                </IonItem>
                                {errors && errors['email'] && (
                                    <IonText color="danger" className="ion-padding-start">
                                        <small>{errors['email'].message}</small>
                                    </IonText>
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['passwordVerify'] ? "has-error" : ""}`}>
                                        <IonLabel position={'floating'}>Confirm you password</IonLabel>
                                        <IonInput name={'passwordVerify'} type={'password'} placeholder={'Password'} ref={register}/>
                                    </IonItem>
                                    {errors && errors['passwordVerify'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['passwordVerify'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <DisabilityQuestion disability={disability} ></DisabilityQuestion>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem lines={'full'}>
                                    <IonLabel className="ion-text-wrap">Would you like to receive updates and news from us?</IonLabel>
                                    <IonCheckbox name={'receive_marketing'} ref={register} slot="start"/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand={'full'} type={'submit'} className={'action primary'}>Update details</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Edit profile'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            window.history.go(-1);
                        }
                    }]}
                />
            </div>
        }/>
    )
}

export default Edit;