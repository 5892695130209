import React, {useContext} from 'react'
import {IonChip, IonIcon, IonLabel, isPlatform} from "@ionic/react";
import {location, shareSocialOutline} from "ionicons/icons";
import {MAP_IMG_URL, MAP_IMG_URL_MOB} from "../../constants";
import Helper from "../../assets/js/Helper";
import {SocialSharing} from "@ionic-native/social-sharing";
import {AppContext} from "../../State";

const Map = (props: any) => {

    const {state} = useContext(AppContext);

    const sharingOptions = {
        //message: 'share this', // not supported on some apps (Facebook, Instagram)
        //subject: 'the subject', // fi. for email
        //files: ['', ''], // an array of filenames either locally or remotely
        url: 'https://serveometer.com/location/' + props.location.id,
        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        iPadCoordinates: '0,0,0,0'
    };

    const AppShareNow = () => {
        //TODO: set sharing options
        SocialSharing.shareWithOptions(sharingOptions).then(res => {

        }).catch(err => {

        })
    };

    return (
        <div className={'inline-header'}>
            <div className={'map bg-img'} style={{
                background: 'url("' +
                    (props.location.longitude !== undefined || 'undefined' ?
                        isPlatform('desktop') ?
                            MAP_IMG_URL(props.location.latitude, props.location.longitude)
                            :
                            MAP_IMG_URL_MOB(props.location.latitude, props.location.longitude)
                        : '')
                    + '") no-repeat center center / cover'
            }}>
                <IonChip onClick={() => {
                    if (isPlatform('desktop') || isPlatform('mobileweb')) {
                        window.open('https://www.google.com/maps/search/?api=1&query=' + props.location.latitude + ',' + props.location.longitude, '_blank')
                    } else {
                        window.location.href = 'https://www.google.com/maps/search/?api=1&query=' + props.location.latitude + ',' + props.location.longitude
                    }
                }} className={'distance'}>
                    <IonIcon icon={location} color="primary"/>
                    <IonLabel>
                        {
                            state.permissionSet ?
                                Helper.getDistanceFromLatLon(state.myLatLng.lat, state.myLatLng.lng, props.location.latitude, props.location.longitude, 'M').toFixed(2) + ' mi'
                                :
                                'Get Directions'
                        }
                    </IonLabel>
                </IonChip>
            </div>
            <div className={'location-details vertical-align'}>
                <div className={'inner'}>
                    <IonIcon icon={shareSocialOutline} className={'share'} onClick={() => {
                        const element = document.getElementById('pack-actions');
                        element.scrollIntoView({behavior: 'smooth'});
                        if (!isPlatform('desktop') && !isPlatform('mobileweb')) {
                            AppShareNow();
                        }
                    }}/>
                    {props.render}
                </div>
            </div>
        </div>
    )
};

export default Map;