import React, {createContext, useEffect, useReducer} from "react";
import {useLocalStorage} from "./useLocalStorage";

const initialState = {
    user: {},
    locations: [],
    searchResults: [],
    myLatLng: {lat: 0.00, lng: 0.00},
    searchNear: 'me',
    permissionSet: true,
    searchTerm: '',
    searchOrderBy: 'distance',
    searchRange: 20,
    searchLimit: 8,
    searchResultCount: 0,
    searchAppendResults: false,
    searchOrigin: {},
    userVerified: false,
    device: {},
    submission: {},
    lastContentUpdate: 0
};

let AppContext = createContext(initialState as any);

let reducer = (state, action) => {
    switch (action.type) {
        case "setUser": {
            return {...state, user: action.user}
        }
        case "setLocation": {
            return {...state, myLatLng: action.myLatLng}
        }
        case "setPermission": {
            return {...state, permissionSet: action.permissionSet}
        }
        case "setLocations": {
            return {...state, locations: action.locations}
        }
        case "setSearchTerm": {
            return {...state, searchTerm: action.searchTerm}
        }
        case "setSearchResults": {
            let data = [];
            if (state.searchAppendResults) {
                data = state.searchResults.concat(action.searchResults);
            } else {
                data = action.searchResults;
            }
            return {...state, searchResults: data}
        }
        case "setSearchOrderBy": {
            return {...state, searchOrderBy: action.searchOrderBy}
        }
        case "setSearchRange": {
            return {...state, searchRange: action.searchRange}
        }
        case "setSearchLimit": {
            return {...state, searchLimit: action.searchLimit}
        }
        case "setSearchResultCount": {
            return {...state, searchResultCount: action.searchResultCount}
        }
        case "setSearchOrigin": {
            return {...state, searchOrigin: action.searchOrigin}
        }
        case "setSearchAppendResults": {
            return {...state, searchAppendResults: action.searchAppendResults}
        }
        case "setVerifiedUser": {
            return {...state, userVerified: action.userVerified}
        }
        case "setDevice": {
            return {...state, device: action.device}
        }
        case "setSubmission": {
            return {...state, submission: action.submission}
        }
        case "setSearchNear": {
            return {...state, searchNear: action.searchNear}
        }
        case "setLastContentUpdate": {
            return {...state, lastContentUpdate: action.time}
        }
    }
    return state;
};


const logger = (reducer) => {
    const reducerWithLogger = (state, action) => {
        // console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
        // console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
        // console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
        return reducer(state, action);
    };
    return reducerWithLogger;
};

const loggerReducer = logger(reducer);

function AppContextProvider(props) {

    const [data, setData] = useLocalStorage('data', initialState);

    let [state, dispatchState] = useReducer(loggerReducer, data);

    let value = {state, dispatchState};

    useEffect(() => {
        setData(state);
    }, [state, setData]);

    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export {AppContext, AppContextProvider, AppContextConsumer};