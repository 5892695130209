import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Questions from "../../components/Score/Questions";
import Search from "../../assets/js/Search";
import * as _ from "underscore";
import {IonHeader, IonImg, IonLoading, IonToolbar} from "@ionic/react";

const Score: React.FC = (props: any) => {

    // Declare empty object
    let init: any = {};
    let locationId = props.match.params.id;

    const [location, setLocation] = useState(init);

    const [showLoading, setShowLoading] = useState(false);

    const [questionPacks, setQuestionPacks] = useState([]);
    const [packCount, setPackCount] = useState(0);

    useEffect(() => {
        setShowLoading(true);
        Search.searchById(locationId).then(data => {

            setLocation(data.attributes);
            let packs = data.relationships.packs.data;
            setPackCount(packs.length);

            let setPacks = 0;

            packs.forEach((pack: any, index: any) => {
                Search.getQuestionPackById(pack.id).then(res => {
                    let packAttrs = res.data.attributes;
                    let packQs = res.included;
                    setQuestionPacks(questionPacks => questionPacks.concat([{
                        'id': pack.id,
                        'attributes': packAttrs,
                        'sort_order': packAttrs.sort_order,
                        'questions': packQs,
                        'count': packQs.length
                    }]));
                    setPacks++;
                    if (packs.length === setPacks) {
                        setTimeout(function () {
                            setShowLoading(false)
                        }, 1500);
                    }
                    return;
                });
            });
        });
    }, []);

    return (
        <Serveometer page={'Serveometer - Score'}
                     showHeader={false}
                     hideFooter={true}
                     showBackButton={true}
                     hideBreadcrumbs={1} render={
            <div className={'score-wrapper'}>
                <div className={'sidebar'}>
                    <IonImg onClick={() => {
                        window.location.href = '/';
                    }} class="logo" src={'assets/imgs/ServeometerPop.png'}/>
                    <p>{
                        //TODO: add text fro each step.
                    }</p>
                </div>
                <div className={'main-container score'}>
                    <div className={'inner'}>
                        <IonHeader>
                            <IonToolbar color={'none'}></IonToolbar>
                        </IonHeader>
                        {
                            location && questionPacks.length && packCount && (questionPacks.length === packCount)
                                ? <Questions location={location} locationId={locationId} questionPacks={_.sortBy(questionPacks, 'sort_order')} packCount={packCount}/>
                                : ''
                        }
                    </div>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                        duration={20000}
                    />
                </div>
            </div>
        }/>
    )
};

export default Score;
