import React, {useContext} from 'react'
import {IonButton, IonIcon, IonItem, IonList, IonListHeader, IonModal, IonToolbar} from "@ionic/react";
import {closeCircle, shieldCheckmark, warning} from "ionicons/icons";
import Blocks from "../../assets/js/Blocks";
import {ContentContext} from "../../Content";


const IconDefinitions = (props: any) => {
    const {content} = useContext(ContentContext);

    return (
        <IonModal isOpen={props.showModal} cssClass='my-custom-class'>
            <IonToolbar>
                <IonIcon className={'close-icon'} slot={'end'} icon={closeCircle} size={'large'} onClick={() => {
                    props.setShowModal(false);
                }}/>
            </IonToolbar>

            <IonList>
                <IonListHeader>
                    <h1>Icon Definitions</h1>
                </IonListHeader>
                <IonItem>
                    <IonIcon slot="start" icon={shieldCheckmark} color="success"></IonIcon>
                    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'icon-safe-definition')}}/>
                </IonItem>
                <IonItem>
                    <IonIcon slot="start" icon={warning} color="warning"></IonIcon>
                    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'icon-warning-definition')}}/>
                </IonItem>
                <IonItem>
                    <IonIcon slot="start" icon={warning} color="danger"></IonIcon>
                    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'icon-danger-definition')}}/>
                </IonItem>
            </IonList>
            <IonButton onClick={() => props.setShowModal(false)}>Close Info</IonButton>
        </IonModal>
    )
};

export default IconDefinitions;