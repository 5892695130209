import React from 'react'
import DownloadBtns from "./Sidebar/DownloadBtns";
import {isPlatform} from "@ionic/react";
import SocialShareIcons from "./SocialShareIcons";

const Sidebar = (props: any) => {
    return (
        isPlatform('mobile') ?
            <></>
            : (
                <>
                    {
                        props.showShareIcons ?
                            <SocialShareIcons/>
                            : ''
                    }
                    <DownloadBtns/>
                </>
            )
    )
};

export default Sidebar;