import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Search from "../../assets/js/Search";
import _ from "underscore";
import {IonAlert, IonAvatar, IonButton, IonCard, IonCardContent, IonCol, IonIcon, IonItem, IonLabel, IonRow, isPlatform} from "@ionic/react";
import * as moment from 'moment-timezone';
import Blocks from "../../assets/js/Blocks";
import {ContentContext} from "../../Content";
import Helper from "../../assets/js/Helper";
import Actions from "../../components/Location/Actions";
import IconDefinitions from "../../components/Location/IconDefinitions";
import {informationCircleOutline} from "ionicons/icons";
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from "react-accessible-accordion";
import {Link} from "react-router-dom";
import Chevrons from "../../components/Score/Chevrons";
import MapContainer from "../../components/Score/MapContainer";
import Media from "react-media";

const Single: React.FC = (props: any) => {

    const {content, dispatch} = useContext(ContentContext);
    // Declare empty object
    let init: any = [];

    let momentjs: any;
    momentjs = moment;

    const [scoreDetails, setScoreDetails] = useState(init);
    const [location, setLocation] = useState(init);

    const [showAlert, setShowAlert] = useState(false);

    const QUESTION_TYPE_YESNO = 2;
    const PACK_COMMENTS = 6;

    const [showModal, setShowModal] = useState(false);

    const getAccordionItemIndexes = (answerCount) => {
        let indexes = [];
        for (let i = 0; i < answerCount; i++) {
            indexes.push('score_' + i);
        }
        return indexes;
    };

    useEffect(() => {
        Search.getSubmissionById(props.match.params.scoreId).then(submission => {
            //Remove Not Applicable OR Don't Know Responses
            submission.answers = _.reject(submission.answers, function (answer) {
                return answer.question_option.question.question_type_id === QUESTION_TYPE_YESNO && answer.question_option.value === null;
            });
            //Append answers to packs and sort by score Descending
            _.each(submission.packs, function (pack) {
                pack.answers = _.chain(submission.answers)
                    .filter(function (answer) {
                        return pack.id == answer.question_option.question.pack_id;
                    }).sortBy(function (answer) {
                        return answer.question_option.value;
                    }).reverse().value();
            });
            submission.comments = _.filter(submission.packs, function (pack) {
                return pack.id === PACK_COMMENTS;
            });

            setScoreDetails(submission);

            Search.searchById(submission.place_id).then(place => {
                setLocation(place.attributes);
            }).catch(err => {

            });

        }).catch(err => {
            // window.location.href = '/404';
        });
    }, [props.match.params.scoreId]);

    return (

        <Serveometer page={'A Score for ' + location.name} showHeader={'true'} showBackButton={true} breadcrumbTrail={
            [
                {pagetitle: location.name, 'pathname': '/location/' + location.id}
            ]
        } render={
            <div className={'single-score container'}>
                <Media query="(max-width: 768px)">
                    {matches =>
                        matches ? (
                            <MapContainer location={location} scoreDetails={scoreDetails} locationId={location.id}/>
                        ) : ''
                    }
                </Media>
                <IonRow>
                    <IonCol size={'12'} size-md={'9'}>
                        <Media query="(min-width: 768px)">
                            {matches =>
                                matches ? (
                                    <IonCard>
                                        <MapContainer location={location} scoreDetails={scoreDetails} locationId={location.id}/>
                                    </IonCard>
                                ) : ('')
                            }
                        </Media>
                        {
                            scoreDetails.comments && scoreDetails.comments.length ?
                                <IonCard className={'score-card'} id={'summary'}>
                                    <IonItem color="light" lines={'full'} className={'ion-no-padding'}>
                                        <IonLabel className={'vertical-align m-0 ml-15 card-title small-title'}>
                                            Comments
                                        </IonLabel>
                                    </IonItem>
                                    <IonCardContent className={'p-15'}>
                                        {
                                            scoreDetails.comments && scoreDetails.comments.length ?
                                                scoreDetails.comments[0].answers.map((comment: any, index: any) => {
                                                    return [
                                                        comment.response_text ?
                                                            <div className={'comment mb-10'} key={comment.id}>
                                                                <p className={'m-0 mb-5 light-text'}><strong>{comment.question_option.question.text}</strong></p>
                                                                <p className={'m-0 light-text'}>&quot;{comment.response_text}&quot;</p>
                                                            </div>
                                                            : ''
                                                    ]
                                                })

                                                : ''
                                        }
                                    </IonCardContent>
                                </IonCard>
                                :
                                <></>
                        }
                        {
                            scoreDetails.packs ?
                                scoreDetails.packs.map((pack: any) => {
                                    return [
                                        pack.id !== PACK_COMMENTS ?
                                            <IonCard className={'pack-answers'} key={pack.id} id={'pack_' + pack.id}>
                                                <IonItem color="light" lines={"full"}>
                                                    <IonLabel className={'vertical-align m-0 card-title small-title'}>{pack.name}</IonLabel>
                                                    {
                                                        !pack.is_scoreable ?
                                                            <IonButton slot="end" className={'action small info mt-5 mb-5'} onClick={() => setShowModal(true)}>
                                                                <IonIcon slot="start" icon={informationCircleOutline}/>
                                                                Info
                                                            </IonButton>
                                                            : ''
                                                    }
                                                </IonItem>
                                                <IonCardContent className={'p-10 pt-0'}>
                                                    {
                                                        pack.answers ?
                                                            pack.is_scoreable ?
                                                                <Accordion className={'answer-accordion'}
                                                                           allowMultipleExpanded
                                                                           allowZeroExpanded
                                                                           preExpanded={getAccordionItemIndexes(pack.answers.length)}>
                                                                    {
                                                                        pack.answers.map((answer: any, index: any) => {
                                                                            return [
                                                                                <AccordionItem key={index} uuid={'score_' + index} className={'answer-item'}>
                                                                                    <AccordionItemHeading>
                                                                                        <AccordionItemButton
                                                                                            className={'vertical-align p-5 pl-0'}>
                                                                                            <IonIcon
                                                                                                src={'/assets/imgs/smiley' + answer.question_option.value + '.svg'}
                                                                                                className={'questionIcon mr-15'}/>
                                                                                            <IonLabel>
                                                                                                <strong>{answer.question_option.question.label}</strong>
                                                                                                <Chevrons score={answer.question_option.value} size={'small'}/>
                                                                                            </IonLabel>
                                                                                            <AccordionItemButton
                                                                                                className={'accordion__button accordion__button_z'}/>
                                                                                        </AccordionItemButton>
                                                                                    </AccordionItemHeading>
                                                                                    <AccordionItemPanel className={'ion-no-padding'}>
                                                                                        <p>{answer.question_option.question.text}</p>
                                                                                        <p><strong>{answer.question_option.text}</strong></p>
                                                                                        {answer.response_text ?
                                                                                            <p>&quot;
                                                                                                <i>{answer.response_text}</i>&quot;</p>
                                                                                            : ''}
                                                                                    </AccordionItemPanel>
                                                                                </AccordionItem>
                                                                            ]
                                                                        })
                                                                    }
                                                                </Accordion>
                                                                : pack.answers.map((answer: any) => {
                                                                    return [
                                                                        <IonItem key={answer.id}
                                                                                 className={'answer-item yesno ion-no-padding'}>
                                                                            <IonAvatar className={'mr-10'}>
                                                                                <div className={'score yesno-' + answer.question_option.value}>
                                                                                    <div className={'inner vertical-align'}>
                                                                                        <IonIcon icon={Helper.yesNoIcons(answer.question_option.value)}
                                                                                                 color={Helper.yesNoColours(answer.question_option.value)}/>
                                                                                    </div>
                                                                                </div>
                                                                            </IonAvatar>
                                                                            <IonLabel>
                                                                                <h2>{answer.question_option.question.label}</h2>
                                                                                {
                                                                                    answer.response_text ?
                                                                                        <p>&quot;
                                                                                            <i>{answer.response_text}</i>&quot;</p>
                                                                                        : ''
                                                                                }
                                                                            </IonLabel>
                                                                        </IonItem>
                                                                    ]
                                                                })
                                                            : ''
                                                    }
                                                </IonCardContent>
                                            </IonCard>
                                            : ''
                                    ]
                                })
                                : ''
                        }
                    </IonCol>
                    <IonCol size={'12'} size-md={'3'}>
                        <Actions locationId={scoreDetails.id}/>
                        {
                            isPlatform('mobile') ?
                                <></>
                                :
                                <IonCard>
                                    <IonCardContent>
                                        <div className={'download-btns'}>
                                            <Link to={'/download/app/apple'}>
                                                <img src={'assets/imgs/appstore.png'}
                                                     alt={'Download on the App Store'}
                                                     className={'mb-10'}
                                                     width={'100%'}/>
                                            </Link>
                                            <Link to={'/download/app/android'}>
                                                <img src={'assets/imgs/playstore.png'}
                                                     alt={'Download on Google Play'}
                                                     width={'100%'}/>
                                            </Link>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                        }
                    </IonCol>
                </IonRow>

                <IconDefinitions showModal={showModal} setShowModal={setShowModal} content={content}/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Success!'}
                    subHeader={''}
                    message={Blocks.getBlock(content, 'report-single-score-not-logged-in')}
                    buttons={[{
                        text: 'Login',
                        handler: () => {
                            window.location.href = '/login';
                        }
                    }, {
                        text: 'Register',
                        handler: () => {
                            window.location.href = '/register';
                        }
                    }]}
                />
            </div>
        }/>
    )
};

export default Single;