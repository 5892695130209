import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Pages from "../../assets/js/Pages";
import {IonImg} from "@ionic/react";

const Terms: React.FC = () => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'terms-and-conditions';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={pageDetails.title} showHeader={'true'} showBackButton={true} render={
            <div className={'container'}>
                {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                <div className="ion-padding">
                    <div className="ion-padding" dangerouslySetInnerHTML={{
                        __html: pageDetails.content
                    }}/>
                </div>


            </div>
        }/>
    )
}

export default Terms;