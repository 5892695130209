import React, {useState} from 'react';
import Serveometer from "../../../components/Serveometer";
import {getPlatforms, IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonItem, IonLoading, IonRow, IonText} from "@ionic/react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import axios from "axios";
import {SMR_ENDPOINT} from "../../../constants";

const ChangePassword: React.FC = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const validationSchema = yup.object().shape({
        password: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,30}$/, 'The password must be 7–30 characters. Please include at least 1 lower case letter, at least 1 upper case letter and at least 1 number'),
        confirmPassword: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,30}$/, 'The password must be 7–30 characters. Please include at least 1 lower case letter, at least 1 upper case letter and at least 1 number'),
    });

    const {handleSubmit, errors, register} = useForm({
        criteriaMode: "all",
        resolver: yupResolver(validationSchema)
    });

    let platforms = getPlatforms();

    const buildUpdate = (data: any) => {
        setShowLoading(true)
        let obj = {
            platforms: JSON.stringify(platforms)
        }
        let d = Object.keys(data);
        for (let i = 0; i < d.length; i++) {
            if (data[d[i]] !== "") {
                if (d[i] === 'confirmPassword') {
                    obj['password_confirmation'] = data[d[i]];
                } else {
                    obj[d[i]] = data[d[i]];
                }
            }
        }
        onSubmit(obj);
    }

    const onSubmit = (data: any) => {
        axios.patch(SMR_ENDPOINT + '/auth/update', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem('SMR_ACCESS_TOKEN')
            }
        })
            .then((response) => {

                if (response.status === 202) {
                    setAlertText('Your password has been updated.')
                    setShowAlert(true);
                    setShowLoading(false)
                } else {
                    setAlertText('Something went wrong. Please try again.')
                    setShowAlert(true);
                    setShowLoading(false)
                }
            })
            .catch(error => {
                setAlertText('Something went wrong. Please try again.')
                setShowAlert(true);
                setShowLoading(false)
            });
    }

    return (
        <Serveometer page={'Edit Profile'} showBackButton={true} showHeader={'true'} render={
            <div>
                <IonCard>
                    <IonCardContent>
                        Change your password below.
                    </IonCardContent>
                </IonCard>
                <form onSubmit={handleSubmit(buildUpdate)}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem lines={'full'} className={`${errors && errors['password'] ? "has-error" : ""}`}>
                                    <IonInput name={'password'} type={'password'} placeholder={'Password'} ref={register}/>
                                </IonItem>
                                {errors && errors['password'] && (
                                    <IonText color="danger" className="ion-padding-start">
                                        <small>{errors['password'].message}</small>
                                    </IonText>
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['confirmPassword'] ? "has-error" : ""}`}>
                                        <IonInput name={'confirmPassword'} type={'password'} placeholder={'Confirm Password'} ref={register}/>
                                    </IonItem>
                                    {errors && errors['confirmPassword'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['confirmPassword'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand={'full'} type={'submit'} className={'action primary'}>Change Password</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Change Password'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            window.history.go(-1);
                        }
                    }]}
                />
            </div>
        }/>
    )
}

export default ChangePassword;