import React from 'react'
import {IonButton, IonCard, IonCardContent, IonChip, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, isPlatform} from "@ionic/react";
import {logoFacebook, logoLinkedin, logoTwitter, logoWhatsapp, mail,} from "ionicons/icons";


import {SocialSharing} from '@ionic-native/social-sharing';
import Media from "react-media";

const Actions = (props: any) => {

    const sharingOptions = {
        //message: 'share this', // not supported on some apps (Facebook, Instagram)
        //subject: 'the subject', // fi. for email
        //files: ['', ''], // an array of filenames either locally or remotely
        url: 'https://www.serveometer.com/location/' + props.locationId,
        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        iPadCoordinates: '0,0,0,0'
    };

    const AppShareNow = () => {
        //TODO: set sharing options
        SocialSharing.shareWithOptions(sharingOptions).then(res => {

        }).catch(err => {

        })
    };

    return (
        <IonCard className={'actions'} id={'pack-actions'}  style={{
            margin: '0',
            marginBottom: '10px'
        }}>
            {
                !props.hideHeader ?
                    <IonItem color="light" lines={"full"}>
                        <IonLabel className={'vertical-align m-0 card-title small-title'}>
                            <Media query="(max-width: 768px)">
                                {matches =>
                                    matches ? (
                                        <>
                                            Actions
                                        </>
                                    ) : (
                                        <>
                                            Share
                                        </>
                                    )
                                }
                            </Media>
                        </IonLabel>
                    </IonItem>
                    : ''
            }
            <IonCardContent className={'p-5'}>
                <IonGrid>
                    {
                        isPlatform('desktop') || isPlatform('mobileweb') ?
                            <div className={'social-share-icons ion-text-center'}>
                                <IonChip onClick={() => {
                                    window.open("mailto:?subject=&body=https://www.serveometer.com/location/" + props.locationId, '_blank');
                                }} className={'ion-text-center mail'}>
                                    <IonIcon icon={mail} className={'m-0'}/>
                                </IonChip>
                                <IonChip onClick={() => {
                                    window.open("https://www.facebook.com/sharer/sharer.php?u=https://www.serveometer.com/location/" + props.locationId, '_blank');
                                }} className={'ion-text-center facebook'}>
                                    <IonIcon icon={logoFacebook} className={'m-0'}/>
                                </IonChip>
                                <IonChip onClick={() => {
                                    window.open("https://twitter.com/intent/tweet?text=https://www.serveometer.com/location/" + props.locationId, '_blank');
                                }} className={'ion-text-center twitter'}>
                                    <IonIcon icon={logoTwitter} className={'m-0'}/>
                                </IonChip><br/>
                                <IonChip onClick={() => {
                                    window.open("https://api.whatsapp.com/send?text=https://www.serveometer.com/location/" + props.locationId, '_blank');
                                }} className={'ion-text-center whatsapp'}>
                                    <IonIcon icon={logoWhatsapp} className={'m-0'}/>
                                </IonChip>
                                <IonChip onClick={() => {
                                    window.open("https://www.linkedin.com/shareArticle?mini=true&url=https://www.serveometer.com/location/" + props.locationId + "&title=&summary=&source=", '_blank');
                                }} className={'ion-text-center linkedin'}>
                                    <IonIcon icon={logoLinkedin} className={'m-0'}/>
                                </IonChip>
                            </div>
                            :
                            <IonRow>
                                <IonCol>
                                    <div className={'socials mt-10'}>
                                        <p><strong>Share this location with others...</strong></p>
                                        <IonButton className={'mt-20'} size="small" expand={'full'} onClick={() => {
                                            AppShareNow();
                                        }} color={'primary'}>Share...</IonButton>
                                    </div>
                                </IonCol>
                            </IonRow>
                    }
                    <Media query="(max-width: 768px)">
                        {matches =>
                            matches ? (
                                <>
                                    <hr/>
                                    <IonRow>
                                        <IonCol size={'6'}>
                                            <IonButton data-cy="claimLocationButton2" className={'action success'} expand={'full'}
                                                       routerLink={'/location/' + props.locationId + '/claim'}>Claim</IonButton>
                                        </IonCol>
                                        <IonCol size={'6'}>
                                            <IonButton data-cy="reportLocationButton2" className={'action danger'} expand={'full'}
                                                       routerLink={'/location/' + props.locationId + '/flag'}>Report</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                            ) : ('')
                        }
                    </Media>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default Actions;