import React, {useContext} from 'react';

import Serveometer from "../../components/Serveometer";
import {IonButton, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonRow, IonSlide, IonSlides,getPlatforms} from "@ionic/react";
import {ContentContext} from "../../Content";
import * as moment from "moment-timezone";
import {arrowBackCircleSharp, arrowForwardCircleSharp} from "ionicons/icons";
import {platform} from "os";

import Blocks from "../../assets/js/Blocks";
import MetaTags from "../../components/MetaTags";

const Home: React.FC = () => {

    let momentjs: any;
    momentjs = moment;

    const {content} = useContext(ContentContext);

    let homeSlider: any;

    if (homeSlider === null || homeSlider === undefined || 'undefined') {
        homeSlider = document.getElementById('home-slider');
    }

    const next = () => {
        homeSlider.slideNext(400, false).then(() => {
        });
    };

    const prev = () => {
        homeSlider.slidePrev(400, false).then(() => {
        });
    };

    return (
        <Serveometer page={'Home'} showHeader={true} hideBreadcrumbs={1} render={
            <>
                <MetaTags title={'ServeOmeter | Shop Confidently'} description={Blocks.getBlock(content, 'meta-description-default').replace(/<[^>]+>/g, '')} />
                <div data-cy="logo" className={"welcome-header desktop-home"} style={{
                    background: "url('assets/imgs/serveometer-header.png') top left no-repeat"
                }}>
                    <IonGrid>
                        <IonRow>
                            <IonCol size={'12'} className={'vertical-align'}>
                                <div className={'intro-text'}>
                                    <h1>Welcome to serveOmeter!</h1>
                                    <h2>A <span className={'highlight'}>100% free</span> brand new app to help you shop safely and confidently!</h2>
                                    <p><strong>Now available on</strong></p>
                                    <div className={'download-btns mt-20'}>
                                        <img src={'assets/imgs/appstore.png'}
                                             alt={'Download on the App Store'}
                                             className={'mr-20'}
                                             width={'200px'}
                                             style={{
                                                 cursor: 'pointer'
                                             }}
                                             onClick={() => {
                                                 window.location.href = "https://apps.apple.com/us/app/serveometer/id1556055486";
                                             }}
                                        />
                                        <img src={'assets/imgs/playstore.png'}
                                             alt={'Download on Google Play'}
                                             width={'200px'}
                                             style={{
                                                 cursor: 'pointer'
                                             }}
                                             onClick={() => {
                                                 window.location.href = "https://play.google.com/store/apps/details?id=serveometer.com.client";
                                             }}/>
                                    </div>
                                </div>
                                {/*<img src={'assets/imgs/iphone-mock.png'} alt={'ServeOmeter App'} height={'400px'} className={'ion-float-right'}/>*/}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                <IonGrid className={'mt-20 mb-20'}>
                    <IonRow>
                        <IonCol>
                            <div className="col container">
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '44px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px'
                                }}>
                                    How does ServeOmeter work?
                                </div>
                                <div className="video">
                                    <iframe width="100%" height="400"
                                            src="https://www.youtube.com/embed/AKRji6genDw?autoplay=1&mute=1&loop=1&rel=0"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className={'mt-30 desktop-latest-teaser'}>
                    <div style={{
                        width: '100%',
                        textAlign: 'center',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif !important',
                        fontWeight: 'bold',
                        marginTop: '100px'
                    }}>
                        <h1>Our Latest News</h1>
                    </div>
                    <br/>
                    <IonRow style={{
                        marginBottom: '100px'
                    }}>
                        <IonCol className={'justify-content-center align-items-center'} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {
                                content.blogs.data.slice(0, 3).map(blog => {
                                    return (
                                        <IonCard routerLink={'/latest/blog/' + blog.id} key={blog.id} style={{
                                            display: 'inline-block',
                                            width: '25%',
                                            margin: '0 10px 0 10px'
                                        }}>
                                            <IonGrid className={'p-0'}>
                                                <IonRow>
                                                    <IonCol size={'12'} size-md={'12'} className={'p-0 desktop-featured-img'} style={{
                                                        background: "url('" + blog.attributes.featured_image + "') center center no-repeat"
                                                    }}>
                                                    </IonCol>
                                                    <IonCol size={'12'} size-md={'12'} className={'vertical-align'}>
                                                        <IonCardContent>
                                                            <IonCardTitle>{blog.attributes.title}</IonCardTitle>
                                                            <IonCardSubtitle
                                                                className={'mt-10 mb-10'}>{momentjs(blog.attributes.published_at).tz('Europe/London').format('dddd, MMMM Do YYYY')}</IonCardSubtitle>
                                                            <p>{blog.attributes.meta_description}</p>
                                                            <IonButton className={'action primary mt-10 ion-float-right'}>Read more</IonButton>
                                                        </IonCardContent>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCard>
                                    )
                                })
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className={'mb-20 desktop-offers-teaser'}>
                    <div style={{
                        width: '100%',
                        textAlign: 'center',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif !important',
                        fontWeight: 'bold',
                        marginTop: '100px'
                    }}>
                        <h1 style={{
                            color: '#fff'
                        }}>Our Latest Offers</h1>
                    </div>
                    <br/>
                    <IonRow style={{
                        marginBottom: '100px'
                    }}>
                        <IonCol className={'justify-content-center align-items-center'} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {
                                content.offers.data.slice(0, 3).map(offer => {
                                    return (
                                        <IonCard routerLink={'/latest/blog/' + offer.id} key={offer.id} style={{
                                            display: 'inline-block',
                                            width: '25%',
                                            margin: '0 10px 0 10px'
                                        }}>
                                            <IonGrid className={'p-0'}>
                                                <IonRow>
                                                    <IonCol size={'12'} size-md={'12'} className={'p-0 desktop-featured-img'} style={{
                                                        background: "url('" + offer.attributes.featured_image + "') center center no-repeat"
                                                    }}>
                                                    </IonCol>
                                                    <IonCol size={'12'} size-md={'12'} className={'vertical-align'}>
                                                        <IonCardContent>
                                                            <IonCardTitle>{offer.attributes.title}</IonCardTitle>
                                                            <IonCardSubtitle
                                                                className={'mt-10 mb-10'}>{momentjs(offer.attributes.published_at).tz('Europe/London').format('dddd, MMMM Do YYYY')}</IonCardSubtitle>
                                                            <p>{offer.attributes.meta_description}</p>
                                                            <IonButton className={'action primary mt-10 ion-float-right'}>Read more</IonButton>
                                                        </IonCardContent>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCard>
                                    )
                                })
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className={'mt-20 mb-20'}>
                    <IonRow>
                        <IonCol style={{
                            textAlign: 'center',
                            paddingTop: '100px',
                            paddingBottom: '100px'
                        }}>
                            <h1 style={{
                                fontSize: '44px'
                            }}>Have a question?</h1>
                            <br/>
                            <IonButton className={'action primary mt-10'} routerLink={'extras/contact'}>Get in touch</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </>
        }/>
    )
};

export default Home;
