import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonAlert, IonButton, IonCard, IonCardContent, IonCheckbox, IonInput, IonItem, IonLabel, IonLoading, IonTextarea} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";
import {FORM_MAX_CHARS, SMR_ENDPOINT} from "../../constants";
import Auth from "../../assets/js/Auth";
import {ContentContext} from "../../Content";

const Contact: React.FC = () => {

    const {content, dispatch} = useContext(ContentContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [messageText, setMessageText] = useState('');
    const [marketingChecked, setMarketingChecked] = useState(false);

    const [showDoneAlert, setShowDoneAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showLoading, setShowLoading] = useState(false);
    const [charsLeft, setCharsLeft] = useState(FORM_MAX_CHARS);

    const {executeRecaptcha} = useGoogleReCaptcha();

    const getToken = async () => {
        if (!executeRecaptcha) {
            return;
        }
        return await executeRecaptcha("contact");
    }

    const submitMessage = () => {
        setShowLoading(true);
        getToken().then(token => {
            if (messageText === '' || myEmail === '') {
                setShowLoading(false);
                setAlertText('Please make sure you have completed all fields.');
                setShowAlert(true);
                return false;
            } else {
                axios.post(
                    SMR_ENDPOINT + '/contact',
                    {
                        email: myEmail,
                        message: messageText,
                        firstname: firstName,
                        lastname: lastName,
                        recaptcha_token: token
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(
                    (response) => {
                        if (response.status === 201) {
                            setShowDoneAlert(true);
                        } else {
                            setAlertText('Something has gone wrong. Please try again.');
                            setShowAlert(true);
                        }
                        setShowLoading(false);
                    }, (error) => {
                        setAlertText('Something has gone wrong. Please try again.');
                        setShowAlert(true);
                        setShowLoading(false);
                    }
                );
            }
        });
    }

    const handleChange = (event) => {
        let input = event.target.value;
        if (charsLeft < 0) {
            input.substring(0, FORM_MAX_CHARS);
        }
        setMessageText(input);
        setCharsLeft(FORM_MAX_CHARS - input.length);
    }

    useEffect(() => {
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    if (resp.data.email !== undefined && 'undefined') {
                        setMyEmail(resp.data.email);
                        setFirstName(resp.data.firstname);
                        setLastName(resp.data.lastname);
                    }
                }).catch(err => {
                setAlertText('Something has gone wrong. Please try again.');
                setShowAlert(true);
            });
        }
    }, []);

    return (
        <Serveometer page={'Contact'} showBackButton={true} showHeader={'true'} render={
            <div className={'container'}>
                <IonCard>
                    <IonCardContent>
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'contact-us-intro')}}/>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardContent>
                        <IonItem lines={'full'}>
                            <IonLabel position="floating">First name</IonLabel>
                            <IonInput autocapitalize={'on'} value={firstName} onIonChange={e => setFirstName(e.detail.value!)}/>
                        </IonItem>
                        <IonItem lines={'full'}>
                            <IonLabel position="floating">Last name</IonLabel>
                            <IonInput autocapitalize={'on'} value={lastName} onIonChange={e => setLastName(e.detail.value!)}/>
                        </IonItem>
                        <IonItem lines={'full'}>
                            <IonLabel position="floating">Your email</IonLabel>
                            <IonInput value={myEmail} onIonChange={e => setMyEmail(e.detail.value!)}/>
                        </IonItem>
                        <IonItem lines={'full'} className={'ion-padding-bottom'}>
                            <IonLabel position="floating">Your message</IonLabel>
                            <IonTextarea autocapitalize={'on'} maxlength={2000} rows={6} cols={20} value={messageText} onIonChange={(e) => {
                                setMessageText(e.detail.value!);
                                handleChange(e!)
                            }}/>
                            <p>Characters Left: {charsLeft}</p>
                        </IonItem>
                        <IonItem lines={'full'}>
                            <IonLabel className="ion-text-wrap">Would you like to receive updates and news from us?</IonLabel>
                            <IonCheckbox checked={marketingChecked} onIonChange={e => setMarketingChecked(e.detail.checked)} slot="start"/>
                        </IonItem>
                        <IonButton expand={'full'} onClick={() => {
                            submitMessage()
                        }}>SUBMIT</IonButton>
                    </IonCardContent>
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Flag location'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
                <IonAlert
                    isOpen={showDoneAlert}
                    onDidDismiss={() => setShowDoneAlert(false)}
                    cssClass='my-custom-class'
                    header={'Success!'}
                    subHeader={''}
                    message={Blocks.getBlock(content, 'contact-thank-you')}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            window.history.go(-1);
                        }
                    }]}
                />
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
            </div>
        }/>
    )
}

export default Contact;