import React, {useContext, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLoading,
    IonRow
} from "@ionic/react";

import Auth from '../../assets/js/Auth';
import Blocks from "../../assets/js/Blocks";
import {ContentContext} from "../../Content";

const PasswordResetRequest: React.FC = () => {

    const {content} = useContext(ContentContext);

    const [email, setEmail] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showLoading, setShowLoading] = useState(false);

    const requestPasswordReset = () => {
        setShowLoading(true);
        if (email === '') {
            setShowLoading(false);
            //alert('Please check the email field is not empty and try again.');
            setAlertText('Please check the email field is not empty and try again.');
            setShowAlert(true);
        } else {
            Auth.requestPasswordReset(email)
                .then(res => {
                    if (res.status === 200) {
                        //console.log(res);
                        setShowLoading(false);
                        setAlertText('If that email exists a password reset link has been sent.  Please check your email and follow the link.');
                        setShowAlert(true);
                    } else {
                        return false;
                    }
                }).catch(e => {
                if (e.response.status === 422) {
                    //console.log(e.response);
                    setAlertText('If that email exists a password reset link has been sent.  Please check your email and follow the link.');
                    setShowAlert(true);
                    setShowLoading(false);
                    return false;
                }
            });
        }
    }

    return (
        <Serveometer page={'Password Reset Request'} showBackButton={true} showHeader={'true'} render={
            <div className={'container'}>
                <IonCard className={'auth-form'}>
                    <IonCardContent>
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'form-email-reset-request')}}/>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'}>
                                        <IonInput value={email} onIonChange={e => setEmail(e.detail.value!)} placeholder="Email" type={"email"}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton className={'action primary'} onClick={() => {
                                        requestPasswordReset();
                                    }} expand={"full"}>Reset Password</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Password'}
                    subHeader={'Reset email'}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            window.location.href = '/extras';
                        }
                    }]}
                />
            </div>
        }/>
    )
}

export default PasswordResetRequest;