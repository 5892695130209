import React, {useContext, useEffect, useState} from 'react';
import {IonButton, IonContent, IonFooter, IonSlide, IonSlides, IonToolbar} from "@ionic/react";
import Authenticate from '../assets/js/Auth';
import {ContentContext} from "../Content";

const Welcome: React.FC = () => {

    if (Authenticate.introSeen()) {
        window.location.href = '/search';
    }

    const {content, dispatch} = useContext(ContentContext);

    // Declare empty object
    let init: any = [];

    useEffect(() => {
        let tabs = document.getElementById('smr-tabs');
        // @ts-ignore
        tabs.style.display = 'none';
    }, []);

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };


    return (
        <IonContent data-cy="welcomeSlides" className={'welcome-slides'}>
            <IonSlides pager={true} options={slideOpts}>
                <IonSlide style={{
                    background: "url('assets/imgs/welcome-slide-1.jpg') center center no-repeat"
                }}>
                    {/*<div>*/}
                    {/*    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content,'welcome-title-1')}} />*/}
                    {/*    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content,'welcome-content-1')}} />*/}
                    {/*</div>*/}
                </IonSlide>
                <IonSlide style={{
                    background: "url('assets/imgs/welcome-slide-2.jpg') center center no-repeat"
                }}/>
                <IonSlide style={{
                    background: "url('assets/imgs/welcome-slide-3.jpg') center center no-repeat"
                }}/>
                <IonSlide style={{
                    background: "url('assets/imgs/welcome-slide-4.jpg') center center no-repeat"
                }}/>
                <IonSlide style={{
                    background: "url('assets/imgs/welcome-slide-5.jpg') center center no-repeat"
                }}/>
                <IonSlide style={{
                    background: "url('assets/imgs/welcome-slide-6.jpg') center center no-repeat"
                }}/>
            </IonSlides>
            <IonFooter translucent={true} className="ion-no-border footer-fix">
                <IonToolbar className={'clear-toolbar-bg p-5'}>
                    <IonButton slot={'start'} className={'action primary'} onClick={() => {
                        Authenticate.setIntroSeen();
                        window.location.href = '/login';
                    }}>Login</IonButton>
                    <IonButton slot={'end'} className={'action secondary'} routerLink={'/search'} onClick={() => {
                        Authenticate.setIntroSeen();
                    }}>Skip</IonButton>
                </IonToolbar>
            </IonFooter>
        </IonContent>
    )
}

export default Welcome;