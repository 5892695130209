import React, {useCallback, useContext, useEffect} from 'react'
import {ContentContext} from "../Content";
import Faqs from "../assets/js/Faqs";
import Blocks from "../assets/js/Blocks";
import Helper from "../assets/js/Helper";
import Blogs from "../assets/js/Blogs";
import Offers from "../assets/js/Offers";
import {useIonViewWillEnter} from "@ionic/react";
import Auth from "../assets/js/Auth";
import {AppContext} from "../State";
import {Plugins} from "@capacitor/core";

const Init = (props: any) => {

    const {content, dispatch} = useContext(ContentContext);
    const {state, dispatchState} = useContext(AppContext);
    const {Device} = Plugins;

    const deviceInfo = async () => {
        const info = await Device.getInfo();
        dispatchState({type: 'setDevice', device: info});
    }

    const refreshFaqs = useCallback(async () => {


        Faqs.loadFaqs(1, 20, content.faqs.meta.lastUpdate).then(res => {
            dispatch({type: 'updateFAQ', faqs: res.data});
        }).catch(err => {

        });
    }, [dispatch]);

    const refreshBlocks = useCallback(async () => {
        Blocks.loadBlocks(content.blocks.meta.lastUpdate).then(res => {
            if (Helper.checkNested(res, 'data')) {
                dispatch({type: 'updateBlocks', blocks: res});
            }
        }).catch(err => {

        });
    }, [dispatch]);

    const refreshBlogs = useCallback(async () => {
        Blogs.loadBlogs(1, 10, content.blogs.meta.lastUpdate).then(res => {
            if (Helper.checkNested(res, 'data')) {
                dispatch({type: 'updateBlogs', blogs: res.data});
            }
        }).catch(err => {
            //console.log("refreshBlogs Error:", err);
        });
    }, [dispatch]);

    const refreshOffers = useCallback(async () => {
        Offers.getOffers(1, 10, content.offers.meta.lastUpdate).then(res => {
            if (Helper.checkNested(res, 'data')) {
                dispatch({type: 'updateOffers', offers: res.data});
            }
        }).catch(err => {
            //console.log("refreshOffers Error:", err);
        });
    }, [dispatch]);

    useIonViewWillEnter(() => {
        populateContent();
        verifyUser();
    });

    const populateContent = () => {

        if(state.lastContentUpdate !== 'undefined' && !isNaN(state.lastContentUpdate) && state.lastContentUpdate !== 0){
            //todo set as a parameter somewhere??
            let interval = 600;
            let now  = Date.now();
            if((state.lastContentUpdate+interval)<now){
                //only update content every 10 minutes
                return;
            }
        }

        dispatchState({type: 'setLastContentUpdate', time: Date.now()});
        switch (props.type) {
            case "all":
                //console.log("Init All");
                deviceInfo();
                refreshFaqs();
                refreshBlocks();
                refreshBlogs();
                refreshOffers();
                break;
            case 'faqs':
                refreshFaqs();
                break;
            case 'blocks':
                refreshBlocks()
                break;
            case 'blogs':
                refreshBlogs()
                break;
            case 'offers':
                refreshOffers()
                break;
        }
    }

    const verifyUser = () => {
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    let verified = resp.data.email_verified_at;
                    if (verified === null) {
                        dispatchState({type: 'setVerifiedUser', userVerified: false});
                    } else {
                        dispatchState({type: 'setVerifiedUser', userVerified: true});
                    }
                }).catch(err => {
                //console.log("error: ", err);
            });
        } else {

        }
    }

    useEffect(() => {
        populateContent();
        verifyUser();
    }, []);


    return null;
}

export default Init
