import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {
    IonAlert,
    IonButton, IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";

import Auth from '../../assets/js/Auth';
import Blocks from "../../assets/js/Blocks";
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers";
import {ContentContext} from "../../Content";
import {eye} from "ionicons/icons";

const ResetPassword = (props: any) => {

    const [resetToken] = useState(props.match.params.token)
    const {content, dispatch} = useContext(ContentContext);

    const [resetEmail, setResetEmail] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePassword = (type) => {
        if (type === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    }

    const validationSchema = yup.object().shape({
        password: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,30}$/, 'The password must be 7–30 characters. Please include at least 1 lower case letter, at least 1 upper case letter and at least 1 number'),
        passwordConfirmation: yup.string().required().test('match',
            'Passwords do not match',
            function (passwordConfirmation) {
                return passwordConfirmation === this.parent.password;
            })
    });

    const {handleSubmit, errors, register} = useForm({
        criteriaMode: "all",
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        Auth.resetPassword(resetEmail, data.password, data.passwordConfirmation, resetToken)
            .then(res => {
                if (res.status === 200) {
                    setAlertText('Your password has been reset!');
                    setShowAlert(true);
                    window.location.replace('/search')
                } else {
                    return false;
                }
            }).catch(e => {
            if (e.response.status === 422) {
                //alert(e.response.data.errors[0].detail);
                setAlertText(e.response.data.errors[0].detail);
                setShowAlert(true);
                return false;
            }
        });
    }

    useEffect(() => {
        if (props.location.search !== "") {
            //@sam, the array method looked a bit flakey so i've switched it for something more specific
            //If we need to reuse this for the client to reset inside the appp?
            const queryString = props.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.has('email')) {
                setResetEmail(urlParams.get('email'));
            } else {
                setAlertText('The password reset link is invalid.');
                setShowAlert(true);
            }
        }

    }, [props.location.search])

    return (
        <Serveometer page={'Reset Password'} showHeader={'true'} render={
            <div className={'container'}>
                <IonCard className={'auth-form'}>
                    <IonCardContent>
                        <form id="frmPasswordReset" onSubmit={handleSubmit(onSubmit)}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'password-requirements-text')}}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonItem lines={'full'} className={`${errors && errors['password'] ? "has-error" : ""}`}>
                                            {/*<IonLabel position="floating">Password</IonLabel>*/}
                                            <IonInput name="password"
                                                      type={showPassword ? "text" : "password"}
                                                      autocomplete="new-password"
                                                      ref={register}
                                                      aria-invalid={errors && errors['password'] ? "true" : "false"}
                                                      aria-describedby={`PasswordError`}
                                                      placeholder={'New Password'}/>
                                            <IonIcon icon={eye} slot={'end'} size={'small'} onClick={() => {
                                                togglePassword('password');
                                            }} className={'ion-align-self-end'}/>
                                        </IonItem>
                                        {errors && errors['password'] && (
                                            <IonText color="danger" className="ion-padding-start">
                                                <small>{errors['password'].message}</small>
                                            </IonText>
                                        )}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonItem lines={'full'} className={`${errors && errors['passwordConfirmation'] ? "has-error" : ""}`}>
                                            {/*<IonLabel position="floating">Confirm Password</IonLabel>*/}
                                            <IonInput name="passwordConfirmation"
                                                      type={showConfirmPassword ? "text" : "password"}
                                                      autocomplete="new-password"
                                                      ref={register}
                                                      aria-invalid={errors && errors['passwordConfirmation'] ? "true" : "false"}
                                                      aria-describedby={`passwordConfirmationError`}
                                                      placeholder={'Confirm Password'}/>
                                            <IonIcon icon={eye} slot={'end'} size={'small'} onClick={() => {
                                                togglePassword('confirm-password');
                                            }} className={'ion-align-self-end'}/>
                                        </IonItem>
                                        {errors && errors['passwordConfirmation'] && (
                                            <IonText color="danger" className="ion-padding-start">
                                                <small>{errors['passwordConfirmation'].message}</small>
                                            </IonText>
                                        )}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton type={'submit'} class={'action primary'} expand={"full"}>CHANGE PASSWORD</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </form>
                    </IonCardContent>
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Password Reset'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                        }
                    }]}
                />
            </div>
        }/>
    )
}

export default ResetPassword;