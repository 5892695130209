import React, {useContext, useEffect} from 'react';
import Serveometer from "../../components/Serveometer";
import axios from "axios";
import {SMR_ENDPOINT} from "../../constants";
import {AppContext} from "../../State";

const Logout: React.FC = () => {

    const {state, dispatchState} = useContext(AppContext);

    useEffect(() => {
        if (window.localStorage.getItem('SMR_ACCESS_TOKEN')) {
            axios.get(SMR_ENDPOINT + '/auth/logout', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                }
            })
                .then(resp => {
                    window.localStorage.removeItem('SMR_ACCESS_TOKEN');
                    window.localStorage.removeItem('SMR_AUTH_USER_ID');
                    dispatchState({type: 'setVerifiedUser', userVerified: false});
                    dispatchState({type: 'setUser', user: {}});
                    window.location.href = '/';
                }).catch(err => {
                window.localStorage.removeItem('SMR_ACCESS_TOKEN');
                window.localStorage.removeItem('SMR_AUTH_USER_ID');
                dispatchState({type: 'setVerifiedUser', userVerified: false});
                dispatchState({type: 'setUser', user: {}});
                window.location.href = '/';
            });
        } else {
            window.location.href = '/';
        }
    }, [])

    return (
        <Serveometer page={'Logout'} showHeader={'true'} render={
            <div>

            </div>
        }/>
    )
}

export default Logout;