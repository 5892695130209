import axios from "axios";
import {SMR_ENDPOINT} from '../../constants';
import Helper from "./Helper";

export default {

    loadBlogs: async function (page, count, last_update) {
        try {
            const response = await axios.get(SMR_ENDPOINT + '/posts?page%5Bnumber%5D=' + page + '&page%5Bsize%5D=' + count + 'filter%5Bupdated_at%5D=' + last_update + '&filter%5Bis_offer%5D=0&sort=-published_at')
                .then((result) => {
                    let obj = Helper.setLatestDate(result, last_update);
                    return obj;
                });
            return response;
        } catch (error) {

        }
    },
}