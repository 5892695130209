import React, {useEffect} from 'react';
import {Plugins} from '@capacitor/core';

const {App: CapApp} = Plugins;

const AppUrlListener: React.FC = () => {
    useEffect(() => {
        CapApp.addListener('appUrlOpen', (data: any) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = data.url.split("serveometer.com").pop();
            const firstSlug = slug.split('/');
            if (slug) {
                if (slug === '/tab3/find') {
                    window.location.href = "/tab3";
                } else if (firstSlug === 'reset-password') {
                    window.location.href = slug;
                }
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
