import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,} from "@ionic/react";
import {ContentContext} from "../../Content";
import _ from "underscore";

const FaqItem = (props: any) => {

    const {content, dispatch} = useContext(ContentContext);

    // Declare empty object
    let init: any = {};

    const [faqId] = useState(props.match.params.id);
    const [faqDetails, setFaqDetails] = useState(init);


    useEffect(() => {
        let item = _.find(content.faqs.data, function (faq) {
            return faq.id == faqId;
        });
        setFaqDetails(item.attributes);

    }, [faqId]);


    return (
        <Serveometer page={'FAQ Answer'} showHeader={true} showBackButton={true} render={
            <div>
                <IonCard>
                    <IonCardHeader>
                        <IonCardSubtitle>{faqDetails.question}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div dangerouslySetInnerHTML={{
                            __html: faqDetails.answer
                        }}/>
                    </IonCardContent>
                </IonCard>
            </div>
        }/>
    )
}

export default FaqItem;