import React, {useEffect, useState} from 'react';

import {Network} from '@ionic-native/network';
import {IonCard, IonCardSubtitle} from "@ionic/react";

const NoConnection = (props: any) => {

    const [connectText, setConnectionText] = useState("NO INTERNET CONNECTION");
    const [connectionLost, setConnectionLost] = useState(false);

    useEffect(() => {
        let checkForDisconnect = Network.onDisconnect().subscribe(() => {
            setConnectionText('NO INTERNET CONNECTION');
            setConnectionLost(true);
        });
        let connectSubscription = Network.onConnect().subscribe(() => {
            setConnectionText('INTERNET CONNECTION FOUND');

            setTimeout(() => {
                setConnectionLost(false);
            }, 3000);
        });

        return () => {
            checkForDisconnect.unsubscribe();
            connectSubscription.unsubscribe();
        }

    }, []);

    return (
        connectionLost ?
            <IonCard className={'no-connection'}>
                <IonCardSubtitle>
                    {connectText}
                </IonCardSubtitle>
            </IonCard>
            :
            <div></div>
    )

}

export default NoConnection;