import React, {useContext, useEffect} from 'react';
import Serveometer from "../components/Serveometer";
import {IonCard, IonCardContent, IonCol, IonGrid, IonRow} from "@ionic/react";
import Blocks from "../assets/js/Blocks";
import {ContentContext} from "../Content";

const FourOFour: React.FC = () => {

    const {content, dispatch} = useContext(ContentContext);

    useEffect(() => {
        if (window.location.pathname !== '/sorry') {
            window.location.href = '/sorry';
        }
    }, [])

    return (
        <Serveometer page="404" showHeader={'true'} render={
            <div>
                <IonGrid>
                    <IonRow class="">
                        <IonCol class="ion-align-self-center ion-align-items-center">
                            <IonCard className="ion-margin">
                                <IonCardContent>
                                    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, '404-body')}}/>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        }/>
    )
}

export default FourOFour;