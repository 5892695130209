import React from 'react';
import Serveometer from "../components/Serveometer";
import {IonCol, IonGrid, IonRow, IonTitle, isPlatform} from "@ionic/react";

const Tab2: React.FC = () => {

    //const [pageTitle, setPageTitle] = useState('About');

    return (
        <Serveometer page={'About'} render={
            <IonGrid>
                <IonRow>
                    <IonCol className={isPlatform("desktop") ? "page-title-lg" : "page-title-sm"}>
                        <IonTitle>Tab 2</IonTitle>
                    </IonCol>
                </IonRow>
            </IonGrid>
        }/>
    );
};

export default Tab2;
