import React from 'react';
import Serveometer from "../../components/Serveometer";

const UserProfile: React.FC = () => {
    return (
        <Serveometer page={'User Profile'} showHeader={'true'} render={
            <div>

            </div>
        }/>
    )
}

export default UserProfile;