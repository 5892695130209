import React, {useEffect, useState} from 'react'
import {IonButton, IonIcon, IonLabel, IonRow, isPlatform} from "@ionic/react";
import {time} from "ionicons/icons";
import Map from "./Map";
import Media from "react-media";
import Helper from "../../assets/js/Helper";

const MapContainer = (props: any) => {


    //console.log("MapContainer Location:", props.location);
    //console.log("MapContainer Location ID:", props.locationId);

    return (
        <Map location={props.location} locationId={props.locationId} render={
            <>
                <h1 className={'ion-no-margin sm-title mb-5'}>{props.location.name}</h1>
                <p className={'light-text m-0 address'}>{props.location.address}, {props.location.city}, {props.location.province}, {props.location.postalCode}</p>
                <hr/>
                <div className={'vertical-align justify-space-between'}>
                    {props.location.statistics && props.location.statistics.answers && props.location.statistics.answers.yesnos ?
                        <div className={'extra-icons'}>
                            {
                                props.location.statistics.answers.yesnos.map((pack, pack_index) => {
                                    return [
                                        <div className={'yesnos-button item' + (pack_index === 0 ? ' mr-10' : '')}
                                             key={pack_index}
                                             onClick={() => {
                                                 const element = document.getElementById('pack-' + pack.id);
                                                 element.scrollIntoView({behavior: 'smooth'});
                                             }}>
                                            {
                                                pack.name === "Covid" ?
                                                    <IonIcon slot="start" size={'large'}
                                                    icon={Helper.yesNoIcons(props.covidScore)}
                                                    color={Helper.yesNoColours(props.covidScore)}/>
                                                : pack.name === "Accessibility" ?
                                                    <IonIcon slot="start" size={'large'}
                                                             icon={Helper.yesNoIcons(props.accessibilityScore)}
                                                             color={Helper.yesNoColours(props.accessibilityScore)}/>
                                                    : ''
                                            }
                                            &nbsp;
                                            <IonLabel>
                                                <strong>{pack.name}</strong>
                                            </IonLabel>
                                        </div>
                                    ]
                                })
                            }
                            {
                                props.location.hours && !isPlatform('desktop') ?
                                    <div onClick={() => {
                                        const element = document.getElementById('opening-times');
                                        element.scrollIntoView({behavior: 'smooth'});
                                    }} className={'yesnos-button item ml-10'}>
                                        <IonIcon slot="start" size={'large'} color={'medium'}
                                                 icon={time}/>&nbsp;
                                        <IonLabel>
                                            <strong>Hours</strong>
                                        </IonLabel>
                                    </div>
                                    : ''
                            }
                        </div>
                        : ''
                    }
                    <Media query="(min-width: 768px)">
                        {matches =>
                            matches ? (
                                <IonRow>
                                    <IonButton data-cy="claimLocationButton" className={'action success mr-10'}
                                               routerLink={'/location/' + props.locationId + '/claim'}>Claim</IonButton>
                                    <IonButton data-cy="reportLocationButton" className={'action danger'}
                                               routerLink={'/location/' + props.locationId + '/flag'}>Report</IonButton>
                                </IonRow>
                            ) : ('')
                        }
                    </Media>
                </div>
            </>
        }/>
    )
};

export default MapContainer;