import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonCol, IonGrid, IonImg, IonItem, IonLabel, IonList, IonRow, IonText} from '@ionic/react';
import {ContentContext} from "../../Content";
import Init from "../../components/Init";
import Pages from "../../assets/js/Pages";

const Newsletter: React.FC = () => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'newsletter';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);


    return (
        <Serveometer page={'Newsletter'} showBackButton={true} showHeader={'true'} render={
            <div className={'container-2'}>
                {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image} style={{width: '100%'}}/>}
                <div className="ion-padding">
                    <div className="ion-padding" dangerouslySetInnerHTML={{
                        __html: pageDetails.content
                    }}/>
                </div>


            </div>
        }/>
    );
}

export default Newsletter;