import React, {useContext, useEffect, useState} from 'react';
import {Plugins} from '@capacitor/core';
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonLoading,
    IonModal,
    IonRange,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonSpinner,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import '../theme/searchfixes.css';
import Serveometer from "../components/Serveometer";
import {MAP_IMG_URL} from "../constants";
import {
    addCircleOutline,
    closeCircle,
    createOutline,
    filterOutline,
    locateOutline,
    location,
    navigateOutline,
    removeCircleOutline,
    ribbonOutline,
    trash
} from "ionicons/icons";
import {Geolocation} from '@ionic-native/geolocation';

import SearchNow from '../assets/js/Search';
import {AppContext} from "../State";
import {ContentContext} from "../Content";
import Helper from "../assets/js/Helper";
import Chevrons from "../components/Score/Chevrons";
import _ from "underscore";
import Locations from "../assets/js/Locations";
import SearchLocationInput from "../components/SearchLocationInput";
import LocationPermissions from "../components/LocationPermission";
import {RefresherEventDetail} from "@ionic/core";
import Media from "react-media";
import Sidebar from "../components/Sidebar";
import Auth from "../assets/js/Auth";
import Blocks from "../assets/js/Blocks";

const Search: React.FC = () => {
    const {Keyboard} = Plugins;

    const {state, dispatchState} = useContext(AppContext);
    const {content, dispatch} = useContext(ContentContext);

    const [searchNear, setSearchNear] = useState("me");
    const [showLoading, setShowLoading] = useState(false);

    const [searchRecent, setSearchRecent] = useState("");
    const [showDistanceRange, setShowDistanceRange] = useState(false);
    const [showStationPicker, setShowStationPicker] = useState(false);

    const [newSortBy, setNewSortBy] = useState(state.searchOrderBy);

    const [currentDistance, setCurrentDistance] = useState(state.searchRange);
    const [newDistance, setNewDistance] = useState(state.searchRange);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [newSearchTerm, setNewSearchTerm] = useState('');
    const [currentlySearching, setCurrentlySearching] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    //Diabled as it's buggy
    //const [showBoundsAlert, setShowBoundsAlert] = useState(false);

    const [loaderText, setLoaderText] = useState('Load more places');
/**
 * This should be in a helper but not needed now anyway
 *
 * You must put code in it's proper place, this is super hacky and a bit wank
 *
    function arePointsNear(checkPoint, centerPoint, km) {
        var ky = 40000 / 360;
        var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
        var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
        var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
        return Math.sqrt(dx * dx + dy * dy) <= km;
    }
**/

    const runSearch = async (q: any, options: any) => {
        setCurrentlySearching(true);
        //setShowLoading(true);
        let page = options.hasOwnProperty('page') ? options.page : 0;
        let orderBy = options.hasOwnProperty('orderBy') ? options.orderBy : state.searchOrderBy;
        let distance = options.hasOwnProperty('distance') ? options.distance : state.searchRange;
        let loader = options.hasOwnProperty('loader') ? options.loader : false;
        //Changing search term, replace current results - don't append
        if (state.searchTerm !== q) {
            dispatchState({type: 'setSearchTerm', searchTerm: q});
            dispatchState({type: 'setSearchAppendResults', searchAppendResults: false});
        }
/**
 * Probably this shouldn't be called every time a search is run.
 * Understand the user may move but it prolly should only be run once
        let centerPoint = {lat: 51.5014, lng: 0.1419};
        let checkPoint  = {lat: state.myLatLng.lat, lng: state.myLatLng.lng}

        if(arePointsNear(checkPoint, centerPoint, 160)){
            //IN BOUNDS DO CONTINUE
        } else {
            Geolocation.getCurrentPosition().then((resp) => {
                setShowBoundsAlert(true);
                setCurrentlySearching(false);
                return false;
            }).catch((error) => {
                setCurrentlySearching(false);
            });
        }
**/
        SearchNow.places(q, {
            lat: state.myLatLng.lat,
            lng: state.myLatLng.lng,
            orderBy: orderBy,
            range: distance,
            page: page,
            limit: state.searchLimit
        }).then(res => {
            setShowLoading(false);
            dispatchState({type: 'setSearchResults', searchResults: res.data.hits.hits});
            dispatchState({type: 'setSearchResultCount', searchResultCount: res.data.hits.total.value});
            dispatchState({type: 'setSearchAppendResults', searchAppendResults: false});
            if (res.data.hasOwnProperty('city')) {
                setSearchNear(res.data.city);
                dispatchState({type: 'setSearchNear', searchNear: res.data.city});
            }
            setCurrentlySearching(false);
            setLoaderText('Load more places');
        }).catch(err => {
            //setShowLoading(false);
            setCurrentlySearching(false);
        });

    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get('recent')){
            dispatchState({type: 'setSearchOrderBy', searchOrderBy: 'recent'});
            dispatchState({type: 'setSearchRange', searchRange: 50});
            setNewSortBy('recent');
            let data = {lat: state.myLatLng.lat, lng: state.myLatLng.lng, orderBy: 'recent', distance: 50};
            runSearch(state.searchTerm, data);
        }

        //Lat Long is correct here but not in the runSearch Function
        //Passing to it directly so we use the correct values
        if (_.isNumber(state.myLatLng.lat)
            && _.isNumber(state.myLatLng.lng
            )) {
            let data = {lat: state.myLatLng.lat, lng: state.myLatLng.lng};
            runSearch(state.searchTerm, data);
        }

        //Trigger after position change or location change
    }, [state.myLatLng.lat, state.myLatLng.lng]);


    //Chrome flagging an errror if this is triggered without user interaction
    //Think it may not be needed now??
    const runSearchNearMe = () => {
        Geolocation.getCurrentPosition().then((resp) => {
            setShowLoading(false);
            dispatchState({type: 'setLocation', myLatLng: {lat: resp.coords.latitude, lng: resp.coords.longitude}});
            dispatchState({type: 'setPermission', permissionSet: true});
            runSearch(false, {});

        }).catch((error) => {
            //Handle no location service here
            dispatchState({type: 'setPermission', permissionSet: false});
            dispatchState({type: 'setSearchOrderBy', searchOrderBy: 'relevance'});
            setSearchNear('Choose location');
            setShowStationPicker(true);
            setShowLoading(false);
        });
    };

    async function refreshLatLog() {
        Geolocation.getCurrentPosition().then((resp) => {
            dispatchState({type: 'setLocation', myLatLng: {lat: resp.coords.latitude, lng: resp.coords.longitude}});
        });
    }

    async function refreshSearch(event: CustomEvent<RefresherEventDetail>) {
        dispatchState({type: 'setSearchAppendResults', searchAppendResults: false});
        if (state.searchOrderBy !== 'relevance') {
            await refreshLatLog();
            await runSearch(state.searchTerm, {});
            event.detail.complete();
            return
        }
        await runSearch(state.searchTerm, {});
        event.detail.complete();


    }

    /*async function searchNext($event: CustomEvent<void>) {
        dispatchState({type: 'setSearchAppendResults', searchAppendResults: true});
        await runSearch(state.searchTerm, {page: state.searchResults.length});
        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }*/

    const searchNext = () => {
        setLoaderText('Loading places...');
        dispatchState({type: 'setSearchAppendResults', searchAppendResults: true});
        runSearch(state.searchTerm, {page: state.searchResults.length, loader: true});
    }

    async function markDeleted(id) {
        let res = await Locations.delete(id);
        if (res) {
            let items = _.reject(state.searchResults, function (place) {
                return place['_id'] === id;
            });
            dispatchState({type: 'setSearchResults', searchResults: items});
        }

    }

    const hideLocationPicker = () => {
        setShowStationPicker(false);
    };

    const setLocationPicked = (location: any) => {
        setSearchNear(location);
    };

    const sortByUpdated = (value: string) => {
        dispatchState({type: 'setSearchOrderBy', searchOrderBy: value});
        runSearch(state.searchTerm, {orderBy: value});
    };

    const isShowableScore = (packs) => {
          return true;
    }

    return (
        <Serveometer page={'Home'} showHeader={isPlatform('desktop') || isPlatform('tablet') ? true : false} hideBreadcrumbs={1} render={
            <>
                <IonRefresher slot="fixed" onIonRefresh={refreshSearch}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div>
                    <IonHeader className={"header search"} style={{
                        background: "url('assets/imgs/home-header-bg.png') center center no-repeat"
                    }}>
                        <IonToolbar color={'none'} className={'container'}>
                            {
                                <Media query="(max-width: 768px)">
                                    {matches =>
                                        matches ? (
                                                <IonImg class="score-arrow"
                                                        src={'assets/imgs/ServeometerLight.svg'}
                                                        alt={'Logo'}/>
                                            )
                                            : ('')
                                    }
                                </Media>
                            }
                            <IonGrid>
                                <IonRow>
                                    <IonCol size={'12'} size-md={'9'}>
                                        <form action={''} >
                                            <IonSearchbar color={'light'}
                                                          className={'ion-no-padding search-roundness place-search'}
                                                          placeholder={state.searchTerm === "" ? "Search for a place e.g. Starbucks" : state.searchTerm}
                                                          onIonChange={(e) => {
                                                              runSearch(e.detail.value!, {});
                                                          }}
                                                          debounce={600}
                                                          onKeyUp={(e) => {
                                                              if (e.key === 'Enter') {
                                                                  Keyboard.hide();
                                                              }
                                                          }}
                                                          onFocus={(e) => {
                                                              if (state.searchTerm !== "") {
                                                                  e.currentTarget.value = state.searchTerm;
                                                              }
                                                          }}>
                                                <div className={'search-loader ion-float-right'}>
                                                    {currentlySearching ?
                                                        <IonSpinner name="lines-small"/> : <></>
                                                    }
                                                </div>
                                            </IonSearchbar>
                                        </form>
                                    </IonCol>
                                    <IonCol size={'12'} size-md={'3'}>
                                        <IonGrid className={'ion-no-padding filter-options-breakdown'}>
                                            <IonChip className={'full-chip'} onClick={() => {
                                                setShowSettingsModal(true);
                                            }}>
                                                <IonLabel className={'light-text'}>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Searching</td>
                                                            <td><span className={'dashed_underline'}>{currentDistance} miles</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Near</td>
                                                            <td><span className={'dashed_underline'}>{state.searchNear}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sorted by</td>
                                                            <td><span className={'dashed_underline'}>{state.searchOrderBy}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </IonLabel>
                                                <IonIcon className={'left-edit-button'} icon={createOutline}/>
                                            </IonChip>
                                        </IonGrid>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonToolbar>
                    </IonHeader>
                    <div className={'container'}>
                        {
                            Auth.introSeen() ?
                                ''
                                :
                                <Media query="(max-width: 768px)">
                                    {matches =>
                                        matches ? (
                                            <IonGrid id={'welcome-banner'}  style={{
                                                paddingBottom: '13px'
                                            }}>
                                                <IonRow>
                                                    <IonCol id={'intro-banner'} size={'6'} style={{
                                                        paddingLeft: '5px',
                                                        paddingRight: '5px',
                                                        paddingTop: '10px',
                                                        paddingBottom: '0px'
                                                    }}>
                                                        <IonImg src={'assets/imgs/intro-button.png'} onClick={() => {
                                                            window.location.href = '/extras/how-serveometer-works';
                                                        }}/>
                                                        <IonIcon icon={closeCircle} size={'small'} style={{
                                                            position: 'absolute',
                                                            top: '12px',
                                                            right: '6px',
                                                            color: '#999'
                                                        }} onClick={() => {
                                                            setAlertText(Blocks.getBlock(content, 'close-welcome-banner'));
                                                            setShowAlert(true);
                                                        }}/>
                                                    </IonCol>
                                                    <IonCol size={'6'} style={{
                                                        paddingLeft: '5px',
                                                        paddingRight: '5px',
                                                        paddingTop: '10px',
                                                        paddingBottom: '0px'
                                                    }}>
                                                        <IonImg src={'assets/imgs/disability-button.png'} onClick={() => {
                                                            window.location.href = '/extras/disability-rights-uk';
                                                        }}/>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        ) : ''
                                    }
                                </Media>
                        }
                        <IonGrid style={{
                            paddingTop: '0'
                        }}>
                            <IonRow>
                                <IonCol size-sm={'12'} size-md={'9'}>
                                    {
                                        state.searchResults.length > 0 ?
                                            state.searchResults.map((place: any, index) => {
                                                if (place['no_results'] !== null && place['no_results'] === 0) {
                                                    return (
                                                        <IonCol size-sm={'12'} size-md={'4'}>
                                                            <IonCard key={'0'}>
                                                                <IonCardContent>No locations found.</IonCardContent>
                                                            </IonCard>
                                                        </IonCol>
                                                    )
                                                } else {
                                                    return (
                                                        <IonCard key={place['_id']} className={'location-item mb-10'}  style={{
                                                            margin: '0',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <IonCardContent>
                                                                <IonItemSliding disabled={!state.user.is_admin}>
                                                                    <IonItem routerLink={'/location/' + place['_source'].id} lines={'none'}
                                                                             className={'ion-no-padding'}>
                                                                        <div className={'inner'}>
                                                                            {
                                                                                place['_source'].latitude !== undefined || 'undefined' ?
                                                                                    <div className={'map bg-img'} style={{
                                                                                        background: 'url("' + MAP_IMG_URL(place['_source'].latitude, place['_source'].longitude) + '") center center no-repeat'
                                                                                    }}>
                                                                                        {
                                                                                            place['_source'].hasOwnProperty('distance') && typeof place['_source'].distance == 'number' ?
                                                                                                <IonChip className={'distance'}
                                                                                                         onClick={(e) => {
                                                                                                             e.preventDefault();
                                                                                                             if (isPlatform('desktop') || isPlatform('mobileweb')) {
                                                                                                                 window.open('https://www.google.com/maps/search/?api=1&query=' + place['_source'].latitude + ',' + place['_source'].longitude, '_blank');
                                                                                                             } else {
                                                                                                                 window.location.href = 'https://www.google.com/maps/search/?api=1&query=' + place['_source'].latitude + ',' + place['_source'].longitude
                                                                                                             }
                                                                                                         }}>
                                                                                                    <IonIcon icon={location}
                                                                                                             color="primary"/>
                                                                                                    <IonLabel>{place['_source'].distance.toFixed(2)} mi</IonLabel>
                                                                                                </IonChip>
                                                                                                :
                                                                                                ''
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            <div className={'content'}>
                                                                                <IonCardTitle>{place['_source'].name}</IonCardTitle>
                                                                                {place['_source'].address}, {place['_source'].city}, {place['_source'].country}, {place['_source'].postalCode}
                                                                                <div className={'actions'}>
                                                                                    <Media query="(max-width: 768px)">
                                                                                        {matches =>
                                                                                            matches ? (
                                                                                                    Helper.calculateScore(place['_source']) ?
                                                                                                        <Chevrons
                                                                                                            score={Helper.calculateScore(place['_source'])}
                                                                                                            size={'small'}/> :
                                                                                                        <IonButton size={'small'}
                                                                                                                   className={'action primary'}>
                                                                                                            Submit The First Score
                                                                                                        </IonButton>
                                                                                                )
                                                                                                : (
                                                                                                    Helper.calculateScore(place['_source']) ?
                                                                                                        <Chevrons
                                                                                                            score={Helper.calculateScore(place['_source'])}/> :
                                                                                                        <IonButton size={'default'} className={'action primary'}>
                                                                                                            Submit The First Score
                                                                                                        </IonButton>
                                                                                                )
                                                                                        }
                                                                                    </Media>
                                                                                    <div className={'extra-icons'}>
                                                                                        {
                                                                                            place['_source'].covid_score !== null && place['_source'].covid_score !== 0 ?
                                                                                                <div className={'yesnos-button item mr-5'}>
                                                                                                    <IonIcon slot="start"
                                                                                                             icon={Helper.yesNoIcons(place['_source'].covid_score)}
                                                                                                             color={Helper.yesNoColours(place['_source'].covid_score)}/>&nbsp;
                                                                                                    <IonLabel>
                                                                                                        <strong>COVID</strong>
                                                                                                    </IonLabel>
                                                                                                </div>
                                                                                                : ''
                                                                                        }
                                                                                        {
                                                                                            place['_source'].accessibility_score !== null && place['_source'].accessibility_score !== 0 ?
                                                                                                <div className={'yesnos-button item'}>
                                                                                                    <IonIcon slot="start"
                                                                                                             icon={Helper.yesNoIcons(place['_source'].accessibility_score)}
                                                                                                             color={Helper.yesNoColours(place['_source'].accessibility_score)}/>&nbsp;
                                                                                                    <IonLabel>
                                                                                                        <strong>Accessibility</strong>
                                                                                                    </IonLabel>
                                                                                                </div>
                                                                                                : ''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </IonItem>
                                                                    <IonItemOptions side="end">
                                                                        <IonItemOption onClick={() => markDeleted(place['_source'].id)}
                                                                                       color="danger" expandable>
                                                                            <IonIcon slot="icon-only" icon={trash}/>
                                                                        </IonItemOption>
                                                                    </IonItemOptions>
                                                                </IonItemSliding>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    );
                                                }
                                            })
                                            :
                                            state.searchOrderBy === 'recent' ?
                                                <IonCard>
                                                    <IonCardContent>
                                                        No recently scored places matching your search
                                                    </IonCardContent>
                                                </IonCard>
                                                :
                                                <IonCard>
                                                    <IonCardContent>
                                                        No places matching your search
                                                    </IonCardContent>
                                                </IonCard>

                                    }
                                </IonCol>
                                <Media query="(min-width: 768px)">
                                    {matches =>
                                        matches ? (
                                            <IonCol size-sm={'12'} size-md={'3'}>
                                                <Sidebar/>
                                            </IonCol>
                                        ) : ('')
                                    }
                                </Media>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={() => {searchNext()}} className={'action primary'} expand={"full"} >{ loaderText }</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    {/*<IonInfiniteScroll threshold="350px" disabled={disableInfiniteScroll}
                                       onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                        <IonInfiniteScrollContent
                            loadingText="Loading more places">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>*/}
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Finding locations...'}
                        duration={20000}
                    />
                    <IonAlert
                        isOpen={showAlert}
                        onDidDismiss={() => setShowAlert(false)}
                        cssClass='my-custom-class'
                        header={''}
                        subHeader={''}
                        message={alertText}
                        buttons={[{
                            text: 'OK',
                            handler: () => {
                                document.getElementById('intro-banner').style.display = "none";
                                Auth.setIntroSeen();
                            }
                        }]}
                    />
                    {/*  Disabled due to buggy code
                    <IonAlert
                        isOpen={showBoundsAlert}
                        onDidDismiss={() => setShowBoundsAlert(false)}
                        cssClass='my-custom-class'
                        header={''}
                        subHeader={''}
                        message={"Sorry, ServeOmeter does not operate in your area. But don\'t worry, it\'ll be coming to your high street very soon!"}
                        buttons={[{
                            text: 'OK',
                            handler: () => {

                            }
                        }]}
                    />
*/}
                    <IonModal
                        isOpen={showSettingsModal}
                        onDidDismiss={() => setShowSettingsModal(false)}>
                        <IonContent>
                            {/*<IonHeader className={"header search p-10 fixed-position-header"} style={{*/}
                            {/*    backgroundColor: '#ffa900'*/}
                            {/*}}>*/}
                            {/*    <IonToolbar color={'none'}>*/}
                            {/*        <IonGrid className={'ion-no-padding filter-options-breakdown'}>*/}
                            {/*            <IonChip className={'full-chip'}>*/}
                            {/*                <IonLabel className={'light-text'}>Searching <span className={'dashed_underline'}>{newDistance} miles</span><br/> near <span*/}
                            {/*                    className={'dashed_underline'}>{state.searchNear}</span>. <br/> Sorted by <span className={'dashed_underline'}>{newSortBy}</span>.</IonLabel>*/}
                            {/*            </IonChip>*/}
                            {/*        </IonGrid>*/}
                            {/*    </IonToolbar>*/}
                            {/*</IonHeader>*/}
                            {/*<IonCard className={'search-box box-margin-top'}>
                        <IonCardContent className={'p-5 filter-card ion-text-center'}>
                            <p className={'light-text ion-text-center m-0 filter-type'}><strong>Searching for...</strong></p>
                            <hr />
                            <IonSearchbar color={'light'}
                                          className={'ion-no-padding search-roundness place-search'}
                                          placeholder={"Search for a place e.g. Starbucks"}
                                          onIonChange={(e) => {
                                              setNewSearchTerm(e.detail.value!);
                                          }}
                                          value={newSearchTerm !== '' ? newSearchTerm : state.searchTerm}
                                          debounce={600}/>
                        </IonCardContent>
                    </IonCard>*/}
                            <IonCard className={'search-box box-margin-top'}>
                                <IonCardContent className={'p-5 filter-card ion-text-center'}>
                                    <p className={'light-text ion-text-center m-0 filter-type'}><strong>Searching <span className={'miles-distance'}>{newDistance}</span> miles around...</strong></p>
                                    <hr/>
                                    <SearchLocationInput locationPicked={setLocationPicked}
                                                         locationPicker={hideLocationPicker}
                                                         setRecent={searchRecent}
                                                         searching={state.searchNear}/>
                                    <IonButton className={'action secondary small mt-10'} size={'small'} onClick={
                                        async () => {
                                            setShowLoading(true);
                                            const hasPermission = await LocationPermissions.checkGPSPermission();
                                            if (hasPermission) {
                                                Geolocation.getCurrentPosition().then((resp) => {
                                                    dispatchState({type: 'setLocation', myLatLng: {lat: resp.coords.latitude, lng: resp.coords.longitude}});
                                                    dispatchState({type: 'setSearchNear', searchNear: "me"});
                                                    setSearchNear('me');
                                                    setShowLoading(false);
                                                }).catch((error) => {
                                                    dispatchState({type: 'setSearchNear', searchNear: "Choose location"});
                                                    setSearchNear('Choose location');
                                                    setShowLoading(false);
                                                });
                                            } else {
                                                const permission = await LocationPermissions.requestGPSPermission();
                                                if (permission === 'CAN_REQUEST' || permission === 'GOT_PERMISSION') {
                                                    Geolocation.getCurrentPosition().then((resp) => {
                                                        dispatchState({type: 'setLocation', myLatLng: {lat: resp.coords.latitude, lng: resp.coords.longitude}});
                                                        dispatchState({type: 'setSearchNear', searchNear: "me"});
                                                        setSearchNear('me');
                                                        setShowLoading(false);
                                                    }).catch((error) => {
                                                        dispatchState({type: 'setSearchNear', searchNear: "Choose location"});
                                                        setSearchNear('Choose location');
                                                        setShowLoading(false);
                                                    });
                                                } else {
                                                    dispatchState({type: 'setSearchNear', searchNear: "Choose location"});
                                                    setSearchNear('Choose location');
                                                    setShowLoading(false);
                                                }
                                            }

                                        }
                                    }>
                                        <IonIcon icon={locateOutline} slot={'end'} className={'ion-float-right'}/>
                                        Use My Location
                                    </IonButton>
                                </IonCardContent>
                            </IonCard>
                            <IonCard>
                                <IonCardContent className={'p-5 filter-card'}>
                                    <p className={'light-text ion-text-center m-0 filter-type'}><strong>Within <span className={'miles-distance'}>{newDistance}</span> miles</strong></p>
                                    <hr/>
                                    <IonRange value={newDistance}
                                              pin={true}
                                              min={1}
                                              max={100}
                                              color="primary"
                                              onIonChange={e => {
                                                  setNewDistance(e.detail.value as unknown as number);
                                              }}>
                                        <IonIcon slot="start" size={'large'} onClick={() => {
                                            setNewDistance(newDistance - 1);
                                        }} icon={removeCircleOutline}/>
                                        <IonIcon slot="end" size={'large'} onClick={() => {
                                            setNewDistance(newDistance + 1);
                                        }} icon={addCircleOutline}/>
                                    </IonRange>
                                </IonCardContent>
                            </IonCard>
                            <IonCard>
                                <IonCardContent className={'p-5 filter-card'}>
                                    <p className={'light-text ion-text-center m-0 filter-type'}><strong>Sort my results by</strong></p>
                                    <hr/>
                                    <div className={'sort-toggles'}>
                                        <IonSegment value={newSortBy} onIonChange={(e) => {
                                            setNewSortBy(e.detail.value);
                                        }}>
                                            <IonSegmentButton value={'relevance'}>
                                                <IonIcon icon={filterOutline}/>
                                                <IonLabel>Relevance</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value={'distance'}>
                                                <IonIcon icon={navigateOutline}/>
                                                <IonLabel>Distance</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value={'recent'}>
                                                <IonIcon icon={ribbonOutline}/>
                                                <IonLabel>Last Scored</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                    </div>
                                </IonCardContent>
                            </IonCard>
                            <IonRow className={'p-5'}>
                                <IonCol size={'6'}>
                                    <IonButton className={'action danger'} expand={'full'} onClick={() => {
                                        setShowSettingsModal(false);
                                    }}>
                                        Cancel
                                    </IonButton>
                                </IonCol>
                                <IonCol size={'6'}>
                                    <IonButton className={'action primary'} expand={'full'} onClick={() => {
                                        setCurrentDistance(newDistance)
                                        dispatchState({type: 'setSearchRange', searchRange: newDistance});
                                        dispatchState({type: 'setSearchOrderBy', searchOrderBy: newSortBy});
                                        setShowSettingsModal(false);
                                        setTimeout(function () {
                                            runSearch(state.searchTerm, {orderBy: newSortBy, distance: newDistance});
                                        }, 1000);
                                    }}>
                                        Save Settings
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                    </IonModal>
                </div>
            </>
        }/>
    );
};

export default Search;
