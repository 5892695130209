import React, {useContext, useEffect, useRef, useState} from 'react';
import {IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonLoading, IonProgressBar, IonRefresher, IonRefresherContent, IonRow,} from '@ionic/react';

import MetaTags from 'react-meta-tags';

import {RefresherEventDetail} from '@ionic/core';
import Search from '../../assets/js/Search';
import Serveometer from "../../components/Serveometer";
import Helper from "../../assets/js/Helper";
import LocationScores from "../../components/Location/Scores";
import {checkmarkCircleOutline, helpCircle, informationCircleOutline, shieldCheckmark, warning,} from "ionicons/icons";
import _ from "underscore";

import QRCode from 'qrcode.react';

import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Chevrons from "../../components/Score/Chevrons";
import Blocks from "../../assets/js/Blocks";
import {ContentContext} from "../../Content";
import Locations from "../../assets/js/Locations";
import Actions from "../../components/Location/Actions";
import IconDefinitions from "../../components/Location/IconDefinitions";
import OpeningTimes from "../../components/Location/OpeningTimes";
import Amplify, { Analytics } from 'aws-amplify';
import Media from "react-media";
import MapContainer from "../../components/Location/MapContainer";
import Sidebar from "../../components/Sidebar";


const View = (props: any) => {


    const {content, dispatch} = useContext(ContentContext);

    // Declare empty object
    let init: any = {};

    const [locationId] = useState(props.match.params.id);
    const [location, setLocation] = useState(init);
    const [recentSubs, setRecentSubs] = useState(init);
    const [showModal, setShowModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [locationScore, setLocationScore] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const timesRef = useRef<HTMLIonContentElement | null>(null);

    const scrollToTimes = () => {
        contentRef.current.scrollByPoint(0, timesRef.current.scrollHeight, 2);
    };

    useEffect(() => {
        if (typeof locationId === "string") {

            Locations.findById(content, locationId)
                .then(data => {
                    let location = {};
                    if (_.isNumber(data)) {
                        //We have it locally
                        //data is the index number of the locally stored location
                        //done like this so we don't have to update local storage location with itself each time we load it.
                        location = content.locations[data].attributes;
                        //Todo Redirect unknown ids to 404
                        Locations.loadLocation(locationId)
                            .then(data => {
                                location = data.attributes;
                                setLocation(location);
                                setLocationScore(Helper.calculateScore(location));
                                dispatch({type: 'updateLocations', locations: data});
                            });
                    } else {
                        //Location has been remotely loaded, save to local storage
                        location = data.attributes;
                        dispatch({type: 'updateLocations', locations: data});
                        setLocation(location);
                        setLocationScore(Helper.calculateScore(location));
                    }

                });
            getScorableSubmissions()
            Analytics.record({name:'place.view',attributes:{location:locationId}});
        }
    }, [locationId]);


    function getScorableSubmissions() {
        Search.getScorableSubmissionsByLocationId(locationId, 5, 1)
            .then(data => {
                setRecentSubs(data);
                setShowLoading(false);
                setLoaded(true);
            });
    }

    function refreshLocation(event: CustomEvent<RefresherEventDetail>) {
        Locations.loadLocation(locationId)
            .then(data => {
                let location = data.attributes;
                dispatch({type: 'updateLocations', locations: location});
                getScorableSubmissions();
                setLocationScore(Helper.calculateScore(location));
                event.detail.complete();
            });
    }

    const evaluateYesNoPackSummary = (pack) => {

        let result = {icon: helpCircle, colour: 'medium'};
        if (pack.questions.length) {
            let outcome = 0;
            let lastScore = 0;
            pack.questions.map((q, index) => {
                if (index === 0) {
                    lastScore = q.score;
                    outcome = q.score;
                } else if (lastScore !== q.score) {
                    outcome = 0;
                }
            });

            if (!outcome) {
                result.icon = warning;
                result.colour = 'warning';
            } else {
                result.icon = outcome <= 0 ? warning : shieldCheckmark;
                result.colour = outcome === 100 ? 'success' : (outcome > 0 && outcome < 100 ? 'warning' : 'danger');
            }

        }

        return result;
    };

    // @ts-ignore
    return (
        <Serveometer showHeader={true} showBackButton={true} scrollRef={contentRef} page={location.name} breadcrumbTrail={
            [
                {pagetitle: 'Search', 'pathname': '/search'}
            ]
        } render={
            <>
                <MetaTags id={locationId}>
                    <title itemProp={'title'} id={'serveometerTitle'}>ServeOmeter score for {location.name}, {location.address}</title>
                    <meta id={'serveometerContent'} itemProp={'content'} name="description"
                          content={"Should you visit " + location.name + ", " + location.city + "? Check their COVID, Service and Disability Scores. Add your own ServeOmeter score to " + location.name + ", " + location.address}/>
                    <meta id={'serveometerKeywords'} itemProp={'keywords'} name="keywords"
                          content={"ServeOmeter, " + location.name + ", " + location.name + " Ratings, " + location.name + " Disability Score, " + location.name + " COVID Score, Customer Service Review"}/>
                </MetaTags>
                <IonRefresher slot="fixed" onIonRefresh={refreshLocation}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className={'single-location container'}>
                    <Media query="(max-width: 768px)">
                        {matches =>
                            matches ? (
                                <MapContainer location={location} locationId={locationId} covidScore={location.covid_score} accessibilityScore={location.accessibility_score}/>
                            ) : ''
                        }
                    </Media>
                    <IonGrid>
                        <IonRow>
                            <IonCol size-sm={'12'} size-md={'6'} size-lg={'9'}>
                                <Media query="(min-width: 768px)">
                                    {matches =>
                                        matches ? (
                                            <IonCard>
                                                <MapContainer location={location} locationId={locationId} covidScore={location.covid_score} accessibilityScore={location.accessibility_score}/>
                                            </IonCard>
                                        ) : ('')
                                    }
                                </Media>
                                <IonRow>
                                    <IonCol size={'12'} size-md={location.statistics && location.statistics.answers && location.statistics.answers.yesnos ? '6' : '12'}>
                                        <IonCard id={'details'} style={{
                                            margin: '0'
                                        }}>
                                            <h1>ServeOmeter Score</h1>
                                            {locationScore > 0 ?
                                                <IonCardContent className={'p-5'}>
                                                    <Chevrons score={locationScore}/>
                                                    <div className={'mt-5'}>
                                                        <p className={'ion-text-center'}>
                                                            <strong className={'sub-count'}>
                                                                {location.submission_count == 1 ? '1 person has' : location.submission_count + ' people have'} scored this location so far
                                                            </strong>
                                                        </p>
                                                    </div>
                                                    <IonButton data-cy="addYourScoreButton" className={'action primary mt-10 ml-5 mr-5'}
                                                               routerLink={'/location/' + locationId + '/score'}
                                                               expand={'full'}>
                                                        <IonIcon slot="start" icon={checkmarkCircleOutline}/>
                                                        Add your score
                                                    </IonButton>
                                                    {
                                                        location.statistics && location.statistics.answers && location.statistics.answers.scoreable ?
                                                            location.statistics.answers.scoreable.map((pack, pack_index) => {
                                                                return (
                                                                    <IonCardContent className={'p-5'} key={pack_index}>
                                                                        <Accordion className={'answer-accordion'}
                                                                                   allowMultipleExpanded
                                                                                   allowZeroExpanded>
                                                                            {
                                                                                pack.questions ?
                                                                                    pack.questions.map((q, index) => {
                                                                                        let score = Helper.calculateScore(q);
                                                                                        return (
                                                                                            <AccordionItem key={index} className={'answer-item'}>
                                                                                                <AccordionItemHeading>
                                                                                                    <AccordionItemButton
                                                                                                        className={'vertical-align p-5 pl-0'}>
                                                                                                        <IonIcon
                                                                                                            src={'/assets/imgs/smiley' + score + '.svg'}
                                                                                                            className={'questionIcon mr-15'}/>
                                                                                                        <IonLabel>
                                                                                                            <strong>{q.label}</strong>
                                                                                                            <Chevrons score={score} size={'small'}/>
                                                                                                        </IonLabel>
                                                                                                        <AccordionItemButton
                                                                                                            className={'accordion__button accordion__button_z'}/>
                                                                                                    </AccordionItemButton>
                                                                                                </AccordionItemHeading>
                                                                                                <AccordionItemPanel className={'ion-no-padding'}>
                                                                                                    {
                                                                                                        q.question_options.map((o, option_index) => {
                                                                                                            return (
                                                                                                                <IonItem key={option_index} lines={'full'}
                                                                                                                         className={'ion-no-padding'}>
                                                                                                                    <div className={'inner option-breakdown ion-no-padding'}>
                                                                                                                        <p>{o.text}</p>
                                                                                                                        <IonProgressBar value={o.count / q.count}/>
                                                                                                                    </div>
                                                                                                                </IonItem>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </AccordionItemPanel>
                                                                                            </AccordionItem>
                                                                                        )
                                                                                    })
                                                                                    : ''
                                                                            }
                                                                        </Accordion>
                                                                    </IonCardContent>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </IonCardContent>
                                                :
                                                <IonCardContent className={'no-score'}>
                                                    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'location-no-score-message')}}/>
                                                    <IonButton data-cy="addYourScoreButton" className={'action primary mt-10'}
                                                               routerLink={'/location/' + locationId + '/score'}
                                                               expand={'full'}>
                                                        <IonIcon slot="start" icon={checkmarkCircleOutline}/>
                                                        Add your score
                                                    </IonButton>
                                                </IonCardContent>

                                            }
                                        </IonCard>
                                    </IonCol>
                                    {

                                        location.statistics && location.statistics.answers && location.statistics.answers.yesnos ?
                                            <IonCol size={'12'} size-md={'6'}>
                                                {location.statistics.answers.yesnos.map((pack, pack_index) => {
                                                    return (
                                                        <IonCard key={pack_index} id={'pack-' + pack.id} className={'extra-indicators'} style={{
                                                            margin: '0',
                                                            marginBottom: '10px'
                                                        }}>
                                                            <IonItem color="light" lines={'full'} className={'ion-no-padding'}>
                                                                <IonLabel className={'vertical-align m-0 ml-15 card-title small-title'}>
                                                                    {pack.name}
                                                                </IonLabel>
                                                                <IonButton slot="end" className={'action small info mt-5 mb-5'} onClick={() => setShowModal(true)}>
                                                                    <IonIcon slot="start" icon={informationCircleOutline}/>
                                                                    Info
                                                                </IonButton>
                                                            </IonItem>
                                                            {
                                                                pack.questions ?
                                                                    <IonCardContent>
                                                                        {
                                                                            pack.questions.map((q, index) => {
                                                                                return (
                                                                                    <IonItem lines={'full'} key={index} className={'ion-no-padding'}>
                                                                                        <IonIcon slot="start" icon={Helper.yesNoIcons(q.score)} color={Helper.yesNoSummaryColours(q.percent)}/>
                                                                                        <IonLabel>
                                                                                            {q.label}
                                                                                        </IonLabel>
                                                                                    </IonItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </IonCardContent>
                                                                    :
                                                                    <IonCardContent>
                                                                        <h3>
                                                                            Nobody has submitting any {pack.name} information yet. Why not become the first and help others?
                                                                        </h3>
                                                                        <IonButton data-cy="addYourScoreButton" className={'action primary mt-10'}
                                                                                   routerLink={'/location/' + locationId + '/score'}
                                                                                   expand={'full'}>
                                                                            Add your score
                                                                        </IonButton>
                                                                    </IonCardContent>
                                                            }
                                                        </IonCard>
                                                    )
                                                })}
                                            </IonCol>
                                            : ''
                                    }
                                </IonRow>
                            </IonCol>
                            <IonCol size-sm={'12'} size-md={'6'} size-lg={'3'}>
                                {
                                    recentSubs.length ?
                                        <LocationScores scores={recentSubs}
                                                        title={'Recent Scores'}
                                                        locationId={locationId}
                                                        locationName={location.name}/>
                                        : ''
                                }


                                {
                                    location.hasOwnProperty('hours') && Array.isArray(location.hours) ?
                                        <OpeningTimes timesRef={timesRef}
                                                      className={'no-score'}
                                                      hours={location.hours}
                                                      locationId={locationId}/>
                                        : ''
                                }

                                <Actions locationId={locationId}/>

                                <IonCard style={{
                                    margin: '0',
                                    marginBottom: '10px'
                                }}>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol size={'6'} className={'qr-code'}>
                                                <QRCode value={"https://serveometer.com/location/" + locationId}/>
                                            </IonCol>
                                            <IonCol size={'6'}>
                                                <strong>Categories</strong><br/>
                                                {
                                                    location.categories ?
                                                        location.categories.map(cat => {
                                                            return cat + ', ';
                                                        })
                                                        : 'No categories set'
                                                }
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>

                                <Sidebar/>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IconDefinitions showModal={showModal} setShowModal={setShowModal}/>

                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Getting location...'}
                        duration={20000}
                    />
                </div>
            </>

        }/>
    );
};

export default View;
