import React from 'react'

const Chevrons = (props: any) => {
    return (
        <div className={"chevron-container" + (props.size ? ' ' + props.size : '')}>
            <div className={'chevron chevron-' + (props.score === 1 ? 'red zoom' : 'grey')}><span>1</span></div>
            <div className={'chevron chevron-' + (props.score === 2 ? 'orange zoom' : 'grey')}><span>2</span></div>
            <div className={'chevron chevron-' + (props.score === 3 ? 'yellow zoom' : 'grey')}><span>3</span></div>
            <div className={'chevron chevron-' + (props.score === 4 ? 'lime zoom' : 'grey')}><span>4</span></div>
            <div className={'chevron chevron-' + (props.score === 5 ? 'green zoom' : 'grey')}><span>5</span></div>
        </div>
    )
};

export default Chevrons;