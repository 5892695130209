import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonButton, IonCard, IonCardContent, IonChip, IonCol, IonGrid, IonIcon, IonRow, isPlatform} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";

import Confetti from 'react-confetti'
import {ContentContext} from "../../Content";
import {logoFacebook, logoLinkedin, logoTwitter, logoWhatsapp, mail} from "ionicons/icons";
import {SocialSharing} from "@ionic-native/social-sharing";
import Sidebar from "../../components/Sidebar";

const ScoreSuccessPending = (props: any) => {

    const {content} = useContext(ContentContext);

    const [token, setToken] = useState('');
    const [scoreId] = useState(props.match.params.scoreId);

    useEffect(() => {
        if (props.match.params.authToken !== undefined && props.match.params.authToken !== 'undefined') {
            let searchParam = props.location.search.split("=");
            let idParam = decodeURIComponent(searchParam[1]);
            window.localStorage.setItem("SMR_ACCESS_TOKEN", props.match.params.authToken);
            window.localStorage.setItem("SMR_AUTH_USER_ID", idParam);
            window.localStorage.removeItem('SMR_ACTIVE_SUBMISSION_ID')
            window.localStorage.removeItem('SMR_SCORE_PENDING')
            setToken(props.match.params.authToken);
        }
    }, []);

    return (
        <Serveometer page={'Score Success'} showHeader={'true'} render={
            <div className={'score-success container'}>
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={false}
                    numberOfPieces={2000}
                    tweenDuration={30000}
                />
                <IonRow>
                    <IonCol size={'12'} size-md={'9'}>
                        <IonCard>
                            <IonCardContent>
                                <div className={'success-message'} dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'score-submitted-success-pending-message')}}/>
                                <div className={'vertical-align justify-space-between mt-20'}>
                                    <IonGrid>
                                        <div className={'actions'}>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton routerLink={'/search'} expand={'block'} className={'action primary'}>Score another location</IonButton>
                                                </IonCol>
                                            </IonRow>

                                        </div>
                                    </IonGrid>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol size={'12'} size-md={'3'}>
                        <Sidebar/>
                    </IonCol>
                </IonRow>
            </div>
        }/>
    )
}

export default ScoreSuccessPending;