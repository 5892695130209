import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {RECAPTCHA_V3_SITE_KEY} from "./constants";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

ReactDOM.render(
    <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={RECAPTCHA_V3_SITE_KEY}
    >
        <App/>
    </GoogleReCaptchaProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
