import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Pages from "../../assets/js/Pages";
import {IonButton, IonCol, IonGrid, IonImg, IonRow} from "@ionic/react";

const Business: React.FC = (props: any) => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'business-page';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={'Business'} showHeader={'true'} showBackButton={true} render={
            <div>
                {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                <div className={'ion-padding container'} style={{backgroundColor: '#ffa900'}} >
                    <div className={'ion-padding'} style={{textAlign: 'center'}} >
                        <h1 style={{color: 'white', fontWeight: 'bold'}}>{pageDetails.title}</h1>
                        <br />
                        <div className="video">
                            <iframe width="100%" height="200"
                                    src="https://www.youtube.com/embed/_sdKovgCae0?autoplay=1&mute=1&loop=1&rel=0"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="ion-padding container">
                    <div className="ion-padding" style={{
                        fontWeight: 'bold'
                    }}>
                        <p style={{
                            fontSize: '22px',
                            fontWeight: 'bold'
                        }} >Hello and welcome to ServeOmeter!<br /></p>
                        <p>
                            ServeOmeter is a platform and an app (available on Android and iOS) dedicated to helping consumers shop more securely and confidently. Throughout our services, it gives them the reassurance that:
                            <br />
                        </p>
                        <IonGrid>
                            <IonRow>
                                <IonCol size={'3'}>
                                    <IonImg src={'assets/imgs/tick.png'} style={{
                                        width: '50px',
                                        height: 'auto',
                                        float: 'right'
                                    }} />
                                </IonCol>
                                <IonCol style={{
                                    verticalAlign: 'middle',
                                    paddingTop: '20px'
                                }}>
                                    You provide the highest level of service
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={'3'}>
                                    <IonImg src={'assets/imgs/tick.png'} style={{
                                        width: '50px',
                                        height: 'auto',
                                        float: 'right'
                                    }} />
                                </IonCol>
                                <IonCol style={{
                                    verticalAlign: 'middle',
                                    paddingTop: '20px'
                                }}>
                                    You make sure the COVID guidelines are scrupulously followed
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={'3'}>
                                    <IonImg src={'assets/imgs/tick.png'} style={{
                                        width: '50px',
                                        height: 'auto',
                                        float: 'right'
                                    }} />
                                </IonCol>
                                <IonCol style={{
                                    verticalAlign: 'middle',
                                    paddingTop: '20px'
                                }}>
                                    You offer disability access to the highest standard (see our partnership with Disability Rights UK)
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <p>
                            This in turn will make them mor loyal to your business.
                        </p>
                        <IonGrid>
                            <IonRow>
                                <IonCol size={'3'} sizeLg={'1'}>
                                    <IonImg src={'assets/imgs/1.png'} style={{
                                        verticalAlign: 'middle',
                                        width: '70px',
                                        height: 'auto',
                                        float: 'left'
                                    }} />
                                </IonCol>
                                <IonCol style={{
                                    paddingTop: '30px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: '#ffa900'
                                }}>
                                    How does ServeOmeter work for my business?
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <p>
                            ServeOmeter will help you to keep an eye on your customers' experience in real-time via a friction-free and straight-forward dashboard. Through this, you will not only be able to review what your customers said about your business, but you will have the possibility to take
                            action as well as notify customers what you did so to keep them in the loop.<br /><br />It is also a good way to understand what you're doing well and what needs improving.
                        </p>
                        <IonGrid>
                            <IonRow>
                                <IonCol size={'3'} sizeLg={'1'}>
                                    <IonImg src={'assets/imgs/2.png'} style={{
                                        verticalAlign: 'middle',
                                        width: '70px',
                                        height: 'auto',
                                        float: 'left'
                                    }} />
                                </IonCol>
                                <IonCol style={{
                                    paddingTop: '30px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: '#ffa900'
                                }}>
                                    How do I set up ServeOmeter for my business?
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <p>
                            It's really simple: once you've contacted us, we will send you a code to prove your business is genuine and that you are, indeed, its owner. Once this is done, all you have to do is download our ServeOmeter app (or use its website version) as well as the dashboard. You will then be
                            asked to register yourself and your business on the dashboard and... you're good to go.
                        </p>
                        <IonGrid>
                            <IonRow>
                                <IonCol size={'3'} sizeLg={'1'}>
                                    <IonImg src={'assets/imgs/3.png'} style={{
                                        verticalAlign: 'middle',
                                        width: '70px',
                                        height: 'auto',
                                        float: 'left'
                                    }} />
                                </IonCol>
                                <IonCol style={{
                                    paddingTop: '30px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: '#ffa900'
                                }}>
                                    You convinced me! How do I start using ServeOmeter for my business?
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <p>
                            If you'd like to use our services or know more about ServeOmeter, please do contact us by clicking this button:
                        </p>
                        <IonButton className={'action primary'} routerLink={'/extras/contact'} expand={'full'}>Contact us about ServeOmeter</IonButton>
                    </div>
                </div>
            </div>
        }/>
    )
}

export default Business;