import React, {useContext, useEffect, useState} from 'react';
import {IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonItem, IonLoading, IonRow} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";
import Serveometer from "../../components/Serveometer";
import {ContentContext} from "../../Content";
import {AppContext} from "../../State";
import { Analytics } from 'aws-amplify';
import Auth from "../../assets/js/Auth";
import {Link} from "react-router-dom";
import SubmitScore from "../../assets/js/SubmitScore";
import Locations from "../../assets/js/Locations";

const Login: React.FC = () => {


    const {content, dispatch} = useContext(ContentContext);
    const {state, dispatchState} = useContext(AppContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showSubmissionAlert, setShowSubmissionAlert] = useState(false);
    const [alertSubmissionText, setAlertSubmissionText] = useState('');

    const [showLoading, setShowLoading] = useState(false);

    let answers = JSON.parse(window.localStorage.getItem('SMR_QUESTION'));
    let location = window.localStorage.getItem('SMR_ACTIVE_SUBMISSION_LOCATION');

    const validateLoginDetails = () => {
        if (email === '') {
            return false;
        }
        if (password === '') {
            return false;
        }
        return true;
    };

    const attemptLogin = () => {
        setShowLoading(true);
        if (!validateLoginDetails) {
            setShowLoading(false);
            return false;
        }

        Auth.login(email, password).then((response) => {
            if (response !== undefined && 'undefined' && response.status === 200) {
                setShowLoading(false);
                window.localStorage.setItem("SMR_ACCESS_TOKEN", response.data.access_token);
                window.localStorage.setItem("SMR_AUTH_USER_ID", response.data.user_id);
                dispatchState({type: 'setUser', user: response.data.user});

                Analytics.updateEndpoint({
                    address: response.data.user.email, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
                    location: {
                        latitude: state.myLatLng.lat, // The latitude of the endpoint location, rounded to one decimal place.
                        longitude: state.myLatLng.lng, // The longitude of the endpoint location, rounded to one decimal place.
                    },
                }).then((resp) => {

                    Analytics.record({
                                name: '_userauth.sign_in',
                                attributes: {userId:state.user.id}
                            },'AWSPinpoint');
                    if (localStorage.getItem('SMR_QUESTION') && localStorage.getItem('SMR_ACTIVE_SUBMISSION_LOCATION')) {
                        SubmitScore.submit(location, state.submission.happened_at, answers).then(data => {
                            if (data) {
                                Analytics.record({name:'place.score',attributes:{user_id:data.submission.user_id,location:location}});
                                Locations.loadLocation(location)
                                    .then(res => {
                                        dispatch({type: 'updateLocations', locations: res.attributes});
                                        dispatchState('setSubmission', {})
                                        window.location.href = '/score/' + data.submission.id + '/success';
                                        setShowLoading(false);
                                    });

                            } else {
                                setShowLoading(false);
                                setAlertSubmissionText('Your login was successful but there was an error with your score submission. Please try again.');
                                setShowSubmissionAlert(true);
                            }
                        });
                    } else {
                        if (localStorage.getItem('SMR_PROPOSING')) {
                            localStorage.removeItem('SMR_PROPOSING');
                            window.location.href = '/location/propose';
                        } else {
                            window.location.href = '/search';
                        }
                    }

                });

            } else {
                setAlertText("Please check your login details and try again.");
                setShowAlert(true);
                setShowLoading(false);
                Analytics.record('_userauth.authfail');
            }
        }, (error) => {
            if (error.response.status === 422) {
                if (error.response.data.errors.email !== undefined && 'undefined') {
                    setAlertText(error.response.data.errors.email[0]);
                    setShowAlert(true);
                    setShowLoading(false);
                    Analytics.record({name:'_userauth.authfail',attributes:{error:error.response.data.errors.email[0]}});
                } else if (error.response.data.errors.password !== undefined && 'undefined') {
                    setAlertText(error.response.data.errors.password[0]);
                    setShowAlert(true);
                    setShowLoading(false);
                    Analytics.record({name:'_userauth.authfail',attributes:{error:error.response.data.errors.password[0]}});
                } else {
                    setAlertText("Please check your login details and try again.");
                    setShowAlert(true);
                    setShowLoading(false);
                    Analytics.record({name:'_userauth.authfail',attributes:{error:"Other error"}});
                }
            } else if (error.response.status === 401) {
                setAlertText(error.response.data.message);
                setShowAlert(true);
                setShowLoading(false);
                Analytics.record('_userauth.authfail');
            } else if (error.response.status === 500) {
                setAlertText("Something has gone wrong.");
                setShowAlert(true);
                setShowLoading(false);
                Analytics.record('_userauth.authfail');
                Analytics.record('API Error', {request: '/auth/login/', user: email},);
            } else {
                setAlertText("Something has gone wrong. Please check your internet connection and try again.");
                setShowAlert(true);
                setShowLoading(false);
            }
        })
    };


    useEffect(() => {
        if (window.localStorage.getItem('SMR_ACCESS_TOKEN')) {
            window.location.href = '/extras/my-profile';
        }
    }, []);

    return (
        <Serveometer page={'Login'} showBackButton={true} showHeader={'true'} render={
            <div className={'container'}>
                <IonCard className={'auth-form'}>
                    <IonCardContent>
                        <div className={'ion-text-center'} dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'login-direct-intro')}}/>
                        <p className={'ion-text-center'}>Not signed up yet? <Link to={'/register/form'} title={'Register'}>Create an account</Link>.</p>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'}>
                                        <IonInput data-cy="email" value={email} onIonChange={e => setEmail(e.detail.value!)} placeholder="Email" type={"email"}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'}>
                                        <IonInput data-cy="password" value={password} onIonChange={e => setPassword(e.detail.value!)} placeholder="Password" type={"password"}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton data-cy="btnLogin" className={'action primary'} expand={"full"} onClick={attemptLogin}>Login</IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <a data-cy="btnForgotPassword" onClick={() => {
                                        setShowLoading(true);
                                        setTimeout(function(){
                                            window.location.href = '/request-password-reset';
                                        }, 1000)
                                    }}>Forgotten your password?</a>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Login'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
                <IonAlert
                    isOpen={showSubmissionAlert}
                    onDidDismiss={() => setShowSubmissionAlert(false)}
                    cssClass='my-custom-class'
                    header={'Register - Submission failed'}
                    subHeader={''}
                    message={alertSubmissionText}
                    buttons={[{
                        text: 'Retry',
                        handler: () => {
                            setShowLoading(true);
                            SubmitScore.submit(location, state.submission.happened_at, answers).then(data => {
                                if (data) {
                                    Analytics.record({name:'place.score',attributes:{user_id:data.submission.user_id,location:location}});
                                    Locations.loadLocation(location)
                                        .then(res => {
                                            dispatch({type: 'updateLocations', locations: res.attributes});
                                            dispatchState('setSubmission', {})
                                            window.location.href = '/score/' + data.submission.id + '/success';
                                            setShowLoading(false);
                                        });

                                } else {
                                    setShowLoading(false);
                                    setAlertSubmissionText('Your login was successful but there was an error with your score submission. Please try again.');
                                    setShowSubmissionAlert(true);
                                }
                            }).catch(error => {
                                //registerData = error;
                                setShowLoading(false);
                                setAlertSubmissionText('Your login was successful but there was an error with your score submission. Please try again or send the error details to us below.');
                                setShowSubmissionAlert(true);
                            });
                        }
                    }, {
                        text: 'Email error',
                        handler: () => {
                            window.open("mailto:?subject=&body=Error alert. Details: {}", '_blank');
                        }
                    }]}
                />
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
            </div>
        }/>
    )
};

export default Login;
