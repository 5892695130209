import React, {useContext, useEffect, useState} from 'react';
import {
    getPlatforms,
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonRow,
    IonText,
    IonToolbar
} from "@ionic/react";
import axios from "axios";
import {eye} from "ionicons/icons";
import {APP_VER, SMR_ENDPOINT} from '../constants';
import SubmitScore from "../assets/js/SubmitScore";

import Blocks from '../assets/js/Blocks';

import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers";
import {Link} from "react-router-dom";
import {ContentContext} from "../Content";
import Locations from "../assets/js/Locations";
import Pages from "../assets/js/Pages";
import {AppContext} from "../State";
import Auth from "../assets/js/Auth";
import { Analytics } from 'aws-amplify';
import Serveometer from "../components/Serveometer";

const RegisterForm = (props: any) => {
    const {content, dispatch} = useContext(ContentContext);
    const {state, dispatchState} = useContext(AppContext);


    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showSubmissionAlert, setShowSubmissionAlert] = useState(false);
    const [alertSubmissionText, setAlertSubmissionText] = useState('');

    const [showLoading, setShowLoading] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [showTerms, setShowTerms] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePassword = (type) => {
        if (type === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    }

    const validationSchema = yup.object().shape({
        firstName: yup.string().required().min(1).max(50),
        lastName: yup.string().required().min(1).max(50),
        email: yup.string().required().email(),
        //password: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,30}$/, 'The password must be 7–30 characters. Please include at least 1 lower case letter, at least 1 upper case letter and at least 1 number'),
        //password: yup.string().required().matches(/^(?=.*?[A-Z])$/, 'You must have one uppercase letter.').required().matches(/^(?=.*?[a-z])$/, 'You must have one lowercase letter.').required().matches(/^(?=.*?[0-9])$/, 'You must have one number.').required().matches(/^(?=.{7,30})$/, 'The password must be 7–30 characters.'),
        password: yup.string().required("No password was entered.").min(8, 'Password must be at least 8 characters')
            .max(30, 'Password must be under 30 characters'),
        // .matches(/[a-z]/, 'at least one lowercase char.')
        // .matches(/[A-Z]/, 'at least one uppercase char.')
        // .matches(/[0-9]/, 'at least one number.'),
        passwordConfirmation: yup.string().required().test('match',
            'Passwords do not match',
            function (passwordConfirmation) {
                return passwordConfirmation === this.parent.password;
            }),
        terms: yup.string().required().matches(/on/, "You must accept the terms and conditions")
    });

    const {handleSubmit, errors, register} = useForm({
        criteriaMode: "all",
        resolver: yupResolver(validationSchema)

    });

    const updateTerms = (val) => {
        var els = document.getElementsByName("terms");
        for (var i = 0; i < els.length; i++) {
            let checkbox = (els[i] as HTMLInputElement)
            checkbox.value = (val ? "on" : "0");
        }
    }

    let url = props.registerFrom;
    let location = props.location;
    // @ts-ignore
    let answers = JSON.parse(window.localStorage.getItem('SMR_QUESTION'));
    let platforms = getPlatforms();

    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'terms-and-conditions';

    let registerData: any = {};

    const onSubmit = (data: any) => {
        setSubmitting(true);
        setShowLoading(true);

        axios.post(SMR_ENDPOINT + '/auth/register', {
            email: data.email,
            password: data.password,
            password_confirmation: data.passwordConfirmation,
            firstname: data.firstName,
            lastname: data.lastName,
            receive_marketing: data.marketing === 'on' ? "1" : "0",
            platforms: JSON.stringify(platforms)

        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                setSubmitting(false);
                window.localStorage.removeItem('SMR_IS_NEW_USER');
                if (response.status === 201) {
                    window.localStorage.setItem('SMR_ACCESS_TOKEN', response.data.access_token);
                    window.localStorage.setItem('SMR_AUTH_USER_ID', response.data.user.id);
                    dispatchState({type: 'setUser', user: response.data.user});
                    Analytics.updateEndpoint({
                        address: response.data.user.email, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
                        location: {
                            latitude: state.myLatLng.lat, // The latitude of the endpoint location, rounded to one decimal place.
                            longitude: state.myLatLng.lng, // The longitude of the endpoint location, rounded to one decimal place.
                        }
                    }).then((resp) => {
                        Analytics.record({name:'_userauth.sign_up',attributes:{user_id:state.user.id,location:location}});
                        if (url === '/register/score/' + location) {
                            SubmitScore.submit(location, state.submission.happened_at, answers).then(data => {
                                if (data) {
                                    Analytics.record({name:'place.score',attributes:{user_id:state.user.id,location:location}});
                                    Locations.loadLocation(location)
                                        .then(res => {
                                            dispatch({type: 'updateLocations', locations: res.attributes});
                                            window.localStorage.setItem('SMR_ACTIVE_SUBMISSION_ID', data.submission.id);
                                            dispatchState('setSubmission', {})
                                            setShowLoading(false);
                                            window.location.href = '/verify';

                                        });

                                } else {
                                    registerData = data;
                                    setShowLoading(false);
                                    setAlertSubmissionText('Your registration was successful but there was an error with your score submission. Please try again or send the error details to us below.');
                                    setShowSubmissionAlert(true);
                                }
                            });
                        } else {
                            window.location.href = '/verify';
                            setShowLoading(false);
                        }
                    });

                } else {
                    setShowLoading(false);
                    setAlertText('Something went wrong. Please check the fields and try again.');
                    setShowAlert(true);
                }
            }, (error) => {
                if (error.response.status === 422) {
                    if (error.response.data.errors.password !== undefined && 'undefined') {
                        setAlertText(error.response.data.errors.password[0]);
                        setShowAlert(true);
                    } else if (error.response.data.errors.email !== undefined && 'undefined') {
                        setAlertText(error.response.data.errors.email[0]);
                        setShowAlert(true);
                    } else {
                        setAlertText('Something went wrong. Please check the fields and try again.');
                        setShowAlert(true);
                    }
                }
                if (error.response.status === 500) {
                    setAlertText("Something has gone wrong. Please try again or contact us to let us know.");
                    setShowAlert(true);
                }
                setShowLoading(false);
                setSubmitting(false);
            });
    };

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {
        })

        if (url === '/register/form') {
            setShowRegistration(true);
        }
        if (window.localStorage.getItem('SMR_ACCESS_TOKEN')) {
            window.location.href = '/extras/my-profile';
        }
    }, []);

    return (
        <>

            {
                props.loginCheck ?
                    location ?
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'register-account-intro-during-score')}}/>
                        :
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'register-regular-intro')}}/>
                    :
                    ''
            }
            <IonGrid>
                {
                    !showRegistration && !location ?
                        <>
                            <IonRow>
                                <IonCol className={'ion-text-center'}>
                                    Already have an account?
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton className={'action secondary'} expand={"full"} routerLink={'/login'}>Login</IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <a href={'/request-password-reset'}>Forgotten your password?</a>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton data-cy="btnCreateAccount" className={'action primary'} expand={"full"} onClick={() => {
                                        setShowRegistration(true);
                                    }}>Create your free account</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                        :
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                props.loginCheck ?
                                    <IonRow>
                                        <IonCol className={'ion-text-center mt-10'}>
                                            <Link to={'/login'}>Already have an account?</Link>
                                        </IonCol>
                                    </IonRow>
                                    :
                                    ''
                            }
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['firstName'] ? "has-error" : ""}`}>
                                        <IonInput data-cy="firstName" autocapitalize={'on'} name={'firstName'} placeholder={'First Name'} ref={register} autocomplete="given-name"/>
                                    </IonItem>
                                    {errors && errors['firstName'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['firstName'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['lastName'] ? "has-error" : ""}`}>
                                        <IonInput data-cy="lastName" autocapitalize={'on'} name={'lastName'} placeholder={'Last Name'} ref={register} autocomplete="family-name"/>
                                    </IonItem>
                                    {errors && errors['lastName'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['lastName'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['email'] ? "has-error" : ""}`}>
                                        <IonInput data-cy="email" name={'email'} type={'email'} placeholder={'Email'} ref={register} autocomplete="email"/>
                                    </IonItem>
                                    {errors && errors['email'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['email'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['password'] ? "has-error" : ""}`}>
                                        <IonInput data-cy="password" name={'password'} type={showPassword ? "text" : "password"} placeholder={'Password'} ref={register}
                                                  autocomplete="new-password"/>
                                        <IonIcon icon={eye} onClick={() => {
                                            togglePassword('password');
                                        }}/>
                                    </IonItem>
                                    {/*{errors && errors['password'] && (
                                    <IonText color="danger" className="ion-padding-start">
                                        <small>{errors['password'].message}</small>
                                    </IonText>
                                )}*/}
                                    <IonText color="danger" style={{
                                        paddingLeft: '4px',
                                        fontWeight: 'bold',
                                        color: '#F16F6F',
                                        fontSize: '87%'
                                    }}>
                                        <small>The password must be at least 8 characters</small>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['passwordConfirmation'] ? "has-error" : ""}`}>
                                        <IonInput data-cy="passwordConfirmation" name={'passwordConfirmation'} type={showConfirmPassword ? "text" : "password"}
                                                  placeholder={'Confirm Password'} ref={register} autocomplete="new-password"/>
                                        <IonIcon icon={eye} onClick={() => {
                                            togglePassword('confirm-password');
                                        }}/>
                                    </IonItem>
                                    {errors && errors['passwordConfirmation'] && (
                                        <IonText color="danger" style={{
                                            paddingLeft: '4px',
                                            fontWeight: 'bold',
                                            color: '#F16F6F',
                                            fontSize: '87%'
                                        }} >
                                            <small>{errors['passwordConfirmation'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'} className={`${errors && errors['terms'] ? "has-error" : ""}`}>
                                        <IonLabel className="ion-text-wrap">Please read and accept our <a onClick={() => {
                                            setShowTerms(true)
                                        }}>Terms & Conditions</a>, <a href={'https://serveometer.com/extras/privacy-policy'} target={'_blank'}>Privacy Policy</a> & <a href={'https://serveometer.com/extras/accessibility-policy'} target={'_blank'}>Accessibility Policy</a></IonLabel>
                                        <IonCheckbox data-cy="terms" name={'terms'} ref={register} slot="start" onIonChange={e => updateTerms(e.detail.checked)}/>
                                    </IonItem>
                                    {errors && errors['terms'] && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{errors['terms'].message}</small>
                                        </IonText>
                                    )}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines={'full'}>
                                        <IonLabel className="ion-text-wrap">Would you like to receive updates and news from us?</IonLabel>
                                        <IonCheckbox data-cy="marketing" name={'marketing'} ref={register} slot="start"/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton data-cy="btnRegister" type={'submit'} className={'action primary'} expand={"full"}>Register</IonButton>
                                </IonCol>
                            </IonRow>
                        </form>
                }
            </IonGrid>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={20000}
            />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={'Register'}
                subHeader={''}
                message={alertText}
                buttons={[{
                    text: 'OK',
                    handler: () => {

                    }
                }]}
            />
            <IonAlert
                isOpen={showSubmissionAlert}
                onDidDismiss={() => setShowSubmissionAlert(false)}
                cssClass='my-custom-class'
                header={'Register - Submission failed'}
                subHeader={''}
                message={alertSubmissionText}
                buttons={[{
                    text: 'Retry',
                    handler: () => {
                        setShowLoading(true);
                        SubmitScore.submit(location, state.submission.happened_at, answers).then(data => {
                            if (data) {
                                Analytics.record({name:'place.score',attributes:{user_id:data.submission.user_id,location:location}});
                                Locations.loadLocation(location)
                                    .then(res => {
                                        dispatch({type: 'updateLocations', locations: res.attributes});
                                    });
                                window.localStorage.setItem('SMR_ACTIVE_SUBMISSION_ID', data.submission.id);
                                dispatchState('setSubmission', {})
                                window.location.href = '/verify';
                                setShowLoading(false);
                            } else {
                                registerData = data;
                                setShowLoading(false);
                                setAlertSubmissionText('Your registration was successful but there was an error with your score submission. Please try again or send the error details to us below.');
                                setShowSubmissionAlert(true);
                            }
                        }).catch(error => {
                            registerData = error;
                            setShowLoading(false);
                            setAlertSubmissionText('Your registration was successful but there was an error with your score submission. Please try again or send the error details to us below.');
                            setShowSubmissionAlert(true);
                        });
                    }
                }, {
                    text: 'Email error',
                    handler: () => {
                        window.open("mailto:?subject=&body=Error alert. Details: " + JSON.stringify(registerData), '_blank');
                    }
                }]}
            />
            <IonModal
                isOpen={showTerms}
                cssClass='my-custom-class'
                onDidDismiss={() => setShowTerms(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot={'end'}>
                            <IonButton slot={'end'} onClick={() => setShowTerms(false)}>&times;</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div>
                        {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                        <div className="ion-padding">
                            <div className="ion-padding" dangerouslySetInnerHTML={{
                                __html: pageDetails.content
                            }}/>
                        </div>


                    </div>
                </IonContent>
            </IonModal>
        </>
    )
};

export default RegisterForm;
