import React from 'react';
import Serveometer from "../components/Serveometer";

const Tab3: React.FC = () => {

    //const [pageTitle, setPageTitle] = useState('Contact');

    return (
        <Serveometer page={'Contact'} showHeader={true} render={
            <div>
                <a href={'https://deeplink.serveometer.com/tab3/find'}>DEEP LINK TEST</a>
            </div>
        }/>
    );
};

export default Tab3;
