import React, {useContext, useEffect, useState} from 'react';

import {Plugins} from '@capacitor/core';

import Autosuggest from 'react-autosuggest';
import {IonItem, IonLabel} from "@ionic/react";
import '../theme/searchfixes.css';
import '../theme/styles.scss';
import UKPlaces from "../assets/js/UKPlaces";

import {AppContext} from "../State";

const SearchLocationInput = (props: any) => {
    const {Keyboard} = Plugins;
    const {state, dispatchState} = useContext(AppContext);

    const [suggestions, setSuggestions] = useState([]);
    const [value, setNewValue] = useState(props.searching);

    const [filter, setFilter] = useState('');


    const getSuggestions = async (value: any) => {
        return UKPlaces.search(value);
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion: any) => {
        props.locationPicked(suggestion.name);
        dispatchState({type: 'setLocation', myLatLng: {lat: suggestion.lat, lng: suggestion.lng}});
        dispatchState({type: 'setSearchNear', searchNear: suggestion.name});
        props.locationPicker(false);
        return suggestion.name;
    };

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion: any) => (
        <a className={'suggestion'}>
            <IonItem lines="full">
                <IonLabel className={'suggestion-title'}>{suggestion.name}</IonLabel>
            </IonItem>
        </a>
    );

    // @ts-ignore
    function onChange(event: any, {newValue, method}) {
        setNewValue(newValue);
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    function onSuggestionsFetchRequested(value: any) {
        getSuggestions(value.value).then(res => {
            let suggestions = res.slice(0, 10);
            setSuggestions(suggestions);
        })
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    function onSuggestionsClearRequested() {
        setSuggestions([]);
    }

    function onSuggestionSelected(event, {}) {
        Keyboard.hide();
    }

    function onKeyDown(event: any) {
        if (event.key === 'Enter') {
            Keyboard.hide();
        }
    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'e.g. Wimbledon',
        value,
        onChange: onChange,
        onKeyDown: onKeyDown,
        type: "search"
    };

    const searchTheme = {
        container: 'search-container',
        containerOpen: 'search-container',
        input: 'field',
        inputOpen: 'field',
        inputFocused: 'field',
        suggestionsContainer: 'suggestions',
        suggestionsContainerOpen: '',
        suggestionsList: 'suggestion-list',
        suggestion: 'suggestion-item',
        suggestionFirst: '',
        suggestionHighlighted: '',
        sectionContainer: '',
        sectionContainerFirst: '',
        sectionTitle: ''
    };

    useEffect(() => {
        if (state.searchNear !== '') {
            setNewValue(state.searchNear);
        }
    }, [state.searchNear])

    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                focusInputOnSuggestionClick={false}
                inputProps={inputProps}
                theme={searchTheme}
            />
        </>
    )
}

export default SearchLocationInput;