import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Pages from "../../assets/js/Pages";
import {IonImg} from "@ionic/react";

const Privacy: React.FC = (props: any) => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'privacy_policy';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={'Privacy Policy'} showHeader={'true'} showBackButton={true} render={
            <div className={'container'}>
                {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                <div className="ion-padding">
                    <h1>{pageDetails.title}</h1>
                    <p>Meta Title: {pageDetails.meta_title}</p>
                    <p>Meta Description: {pageDetails.meta_description}</p>
                    <p>@todo set these in the head somehow??</p>
                    <div className="ion-padding" dangerouslySetInnerHTML={{
                        __html: pageDetails.content
                    }}/>
                </div>


            </div>
        }/>
    )
}

export default Privacy;