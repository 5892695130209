import React, {useEffect, useState} from 'react';
import {IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import _ from "underscore";
import {warning} from "ionicons/icons";

const OpeningTimes = (props: any) => {

    const [openingTimes, setOpeningTimes] = useState([]);

    let days = [
        {day: "sun", order: 0},
        {day: "mon", order: 1},
        {day: "tue", order: 2},
        {day: "wed", order: 3},
        {day: "thu", order: 4},
        {day: "fri", order: 5},
        {day: "sat", order: 6},
        {day: "wee", order: 7}
    ];

    useEffect(() => {
        if (props.hasOwnProperty('hours')) {
            let items = [];
            props.hours.map(e => {
                let match = _.findWhere(days, {day: e.day.substring(0, 3).toLowerCase()});
                if (match) {
                    e.order = match.order;
                    days = _.reject(days, function (d) {
                        return d.order == match.order;
                    });
                    items.push(e);
                }
            });
            items = _.sortBy(items, function (i) {
                return i.order;
            });
            setOpeningTimes(items);
        }
    }, [])

    return (
        <IonCard ref={props.timesRef ?? props.timesRef} id={'opening-times'}  style={{
            margin: '0',
            marginBottom: '10px'
        }}>
            <IonItem color="light" lines={'full'} className={'ion-no-padding'}>
                <IonLabel className={'vertical-align m-0 ml-15 card-title small-title'}>
                    Opening Times
                </IonLabel>
                <IonButton slot="end"
                           className={'action small info mt-5 mb-5'}
                           routerLink={'/location/' + props.locationId + '/flag'}>
                    <IonIcon slot="start" icon={warning}/>
                    Incorrect?
                </IonButton>
            </IonItem>
            <IonCardContent className={'p-5'}>
                <IonGrid>
                    {
                        openingTimes.map(hours => {
                            return (
                                <IonRow key={hours.day}>
                                    <IonCol className={'day'}>
                                        {hours.day}
                                    </IonCol>
                                    <IonCol className={'light-text'}>
                                        {hours.hour.split(' - ')[0]}
                                    </IonCol>
                                    <IonCol className={'light-text'}>
                                        {hours.hour.split(' - ')[1]}
                                    </IonCol>
                                </IonRow>
                            )
                        })
                    }
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

export default OpeningTimes;