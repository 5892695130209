import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonButton, IonCard, IonCardContent} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";

import Confetti from 'react-confetti'
import DisabilityQuestion from "../../components/DisabilityQuestion";
import {ContentContext} from "../../Content";

const RegistrationSuccess = (props: any) => {

    const {content} = useContext(ContentContext);
    const [token, setToken] = useState('');
    const [showDisability, setShowDisability] = useState(true);

    const hideDisability = () => {
        setShowDisability(false);
    }

    useEffect(() => {
        if (props.match.params.authToken !== undefined && props.match.params.authToken !== 'undefined') {
            let searchParam = props.location.search.split("=");
            let idParam = decodeURIComponent(searchParam[1]);
            window.localStorage.setItem("SMR_ACCESS_TOKEN", props.match.params.authToken);
            window.localStorage.setItem("SMR_AUTH_USER_ID", idParam);
            setToken(props.match.params.authToken);
        }
    }, []);


    return (
        <Serveometer page={'Registration Success'} showHeader={'true'} render={
            <div>
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={false}
                    numberOfPieces={2000}
                    tweenDuration={5000}
                />
                <IonCard>
                    <IonCardContent>
                        <h1>Success</h1>
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'register-regular-success')}}/>
                    </IonCardContent>
                </IonCard>

                {
                    showDisability ? <DisabilityQuestion hideDisability={hideDisability}></DisabilityQuestion> : ''
                }

                <IonCard>
                    <IonCardContent>
                        <IonButton routerLink={'/search'}
                                   expand={'full'}
                                   className={'action primary mb-10'}>Search For a Location</IonButton>
                        <IonButton routerLink={'/extras/my-profile'}
                                   expand={'full'}
                                   className={'action secondary'}>Go to Your Profile</IonButton>
                    </IonCardContent>
                </IonCard>
            </div>
        }/>
    )
}

export default RegistrationSuccess;