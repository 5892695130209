import React, {useContext, useEffect} from 'react';
import Serveometer from "../components/Serveometer";
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonGrid, IonImg, IonRow} from "@ionic/react";
import Blocks from "../assets/js/Blocks";
import * as moment from "moment-timezone";
import {ContentContext} from "../Content";
import Init from "../components/Init";

const Offers: React.FC = () => {

    const {content, dispatch} = useContext(ContentContext);

    let momentjs: any;
    momentjs = moment;
    useEffect(() => {

    }, []);

    return (
        <Serveometer page={'Offers'} showHeader={'true'} render={
            <><Init type="offers"></Init>
                <div>
                    <IonGrid className={'container'}>
                        <IonRow>
                            <IonCol>
                                <IonCard style={{
                                    marginLeft: '0',
                                    marginRight: '0'
                                }}>
                                    <IonCardContent>
                                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'offers-page-intro')}}/>
                                    </IonCardContent>
                                </IonCard>
                                {
                                    content.offers.data.map(post => {
                                        return (
                                            <IonCard routerLink={'/offers/blog/' + post.id} key={post.id} style={{
                                                marginLeft: '0',
                                                marginRight: '0'
                                            }}>
                                                <IonImg src={post.attributes.featured_image}/>
                                                <IonCardHeader>
                                                    <p>{post.attributes.title}</p>
                                                    <IonCardSubtitle>{momentjs(post.attributes.published_at).tz('Europe/London').format('dddd, MMMM Do YYYY')}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                    {post.attributes.meta_description}
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    })
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </>
        }/>
    )
}

export default Offers;