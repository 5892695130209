import axios from "axios";
import {SMR_ENDPOINT} from "../../constants";
import * as _ from "underscore";

import Helper from "./Helper";

export default {

    getBlock: function (content, blockName) {

        let block = _.find(content.blocks.data, function (block) {
            return block.attributes.key === blockName;
        })

        //Check we have found a block
        if (Helper.checkNested(block, 'attributes', 'content')) {
            return block.attributes.content;
        }
        return "";
    },

    loadBlocks: async function (last_update) {
        try {
            let url = (SMR_ENDPOINT + '/blocks?filter%5Bupdated_at%5D=' + last_update);
            const response = await axios.get(url)
                .then((result) => {
                    if (Helper.checkNested(result, 'data', 'data', [0])) {
                        result = Helper.setLatestDate(result, last_update);
                        result.data.data = _.indexBy(result.data.data, function (block) {
                            return block.attributes.key;
                        })
                        return result.data;
                    }
                    return false;
                });
            return response;
        } catch (error) {

        }
    },
}