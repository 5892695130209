import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {SMR_ENDPOINT} from "../../constants";
import axios from "axios";
import Search from "../../assets/js/Search";
import Auth from "../../assets/js/Auth";
import {IonAlert, IonButton, IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonLoading, IonTextarea} from "@ionic/react";


const FlagLocationScore = (props: any) => {

    const [scoreId] = useState(props.match.params.scoreId);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [flagText, setFlagText] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [showDoneAlert, setShowDoneAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const submitFlag = () => {
        setShowLoading(true);

        let authToken = '';
        if (window.localStorage.getItem('SMR_ACCESS_TOKEN')) {
            authToken = window.localStorage.getItem('SMR_ACCESS_TOKEN');
        } else {
            setShowLoading(false);
            setAlertText('Please log in before flagging a score.');
            setShowAlert(true);
            return false;
        }


        if (flagText === '' || myEmail === '') {
            setShowLoading(false);
            setAlertText('Please make sure you have completed all fields.');
            setShowAlert(true);
            return false;
        } else {
            axios.post(
                SMR_ENDPOINT + '/submissions/flag',
                {
                    submission_id: scoreId,
                    comment: flagText
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authToken
                    }
                }
            ).then(
                (response) => {
                    if (response.status === 201) {
                        setShowDoneAlert(true);
                    } else {
                        setAlertText('Something has gone wrong. Please try again.');
                        setShowAlert(true);
                    }
                    setShowLoading(false);
                }, (error) => {
                    setAlertText('Something has gone wrong. Please try again.');
                    setShowAlert(true);
                    setShowLoading(false);
                }
            );
        }
    }

    useEffect(() => {
        Search.getSubmissionById(scoreId).then(resp => {

        });
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    if (resp.data.email !== undefined && 'undefined') {
                        setMyEmail(resp.data.email);
                        setFirstName(resp.data.firstname);
                        setLastName(resp.data.lastname);
                    }
                }).catch(err => {
                setAlertText('Something has gone wrong. Please try again.');
                setShowAlert(true);
            });
        }
    }, []);

    return (
        <Serveometer page={'Flag Location Score'} showHeader={'true'} render={
            <div>
                <IonCard>
                    <IonCardContent>
                        Have you noticed something that needs attention on a score submitted by someone else?
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardContent>
                        <IonItem lines={'full'}>
                            <IonLabel position="floating">First name</IonLabel>
                            <IonInput autocapitalize={'on'} value={firstName} onIonChange={e => setFirstName(e.detail.value!)}></IonInput>
                        </IonItem>
                        <IonItem lines={'full'}>
                            <IonLabel position="floating">Last name</IonLabel>
                            <IonInput autocapitalize={'on'} value={lastName} onIonChange={e => setLastName(e.detail.value!)}></IonInput>
                        </IonItem>
                        <IonItem lines={'full'}>
                            <IonLabel position="floating">Your email</IonLabel>
                            <IonInput value={myEmail} onIonChange={e => setMyEmail(e.detail.value!)}></IonInput>
                        </IonItem>
                        <IonItem lines={'full'} className={'ion-padding-bottom'}>
                            <IonLabel position="floating">Describe the issue</IonLabel>
                            <IonTextarea autocapitalize={'on'} rows={6} cols={20} value={flagText} onIonChange={e => setFlagText(e.detail.value!)}></IonTextarea>
                        </IonItem>

                        <IonButton expand={'full'} onClick={() => {
                            submitFlag()
                        }}>SUBMIT</IonButton>
                    </IonCardContent>
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Flag location'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
                <IonAlert
                    isOpen={showDoneAlert}
                    onDidDismiss={() => setShowDoneAlert(false)}
                    cssClass='my-custom-class'
                    header={'Success!'}
                    subHeader={''}
                    message={'Thank you for letting us know about this. One of our team will investigate further.'}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            window.history.go(-1);
                        }
                    }]}
                />
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
            </div>
        }/>
    )
}

export default FlagLocationScore;