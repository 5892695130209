import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {checkmarkCircleOutline} from "ionicons/icons";
import {IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon} from "@ionic/react";
import Search from "../../assets/js/Search";
import {MAP_IMG_URL} from "../../constants";
import {AppContext} from "../../State";

const Begin: React.FC = (props: any) => {

    const {state} = useContext(AppContext);

    // Declare empty object
    let init: any = {};

    const [location, setLocation] = useState(init);

    useEffect(() => {
        if (!state.userVerified && state.user.hasOwnProperty('user_id')) {
            window.location.href = '/verify';
        }

        Search.searchById(props.match.params.id).then(response => {
            setLocation(response.attributes);
        });
    }, [props.match.params.id]);

    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        allowTouchMove: false
    };

    return (
        <Serveometer page={'Score here?'} showHeader={true} showBackButton={true} render={
            <div className={'main-container begin ion-text-center'} id={'begin-slider'}>
                <IonCard>
                    <IonCardContent>
                        <div className={'map bg-img'} style={{
                            background: 'url("' + MAP_IMG_URL(location.latitude, location.longitude) + '") center center no-repeat'
                        }}/>
                        <div className={'details'}>
                            <IonCardTitle className={'mt-10 sm-title'}>{location.name}</IonCardTitle>
                            <p>{location.address}, {location.city}</p>
                        </div>
                        <IonButton routerLink={'/location/' + props.match.params.id + '/score'}
                                   expand={'full'}
                                   onClick={() => {
                                       window.localStorage.setItem('SMR_ACTIVE_SUBMISSION_LOCATION', props.match.params.id);
                                       window.localStorage.removeItem('SMR_QUESTION');
                                   }} className={'action primary mt-10 mb-10'}>
                            <IonIcon slot="start" icon={checkmarkCircleOutline}/>
                            Yes, Let's Begin
                        </IonButton>
                        <IonButton className={'action danger small'} size={'small'}
                                   routerLink={'/search'}>No, Find Somewhere Else</IonButton>
                    </IonCardContent>
                </IonCard>
            </div>
        }/>
    )
};
export default Begin;
