import React, {useContext, useEffect} from 'react';
import Serveometer from "../components/Serveometer";
import * as moment from 'moment-timezone';

import {IonButton, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow} from "@ionic/react";
import {ContentContext} from "../Content";
import Init from "../components/Init";
import Sidebar from "../components/Sidebar";

const Latest: React.FC = () => {
    let momentjs: any;
    momentjs = moment;

    const {content, dispatch} = useContext(ContentContext);

    useEffect(() => {

    }, []);

    return (
        <Serveometer page={'Latest News'} showHeader={true} render={
            <><Init type={"blogs"}/>
                <IonGrid className={'container posts'}>
                    <IonRow>
                        <IonCol size={'12'} size-md={'9'}>
                            {
                                content.blogs.data.map(blog => {
                                    return (
                                        <IonCard routerLink={'/latest/blog/' + blog.id} key={blog.id} style={{
                                            marginLeft: '0',
                                            marginRight: '0'
                                        }}>
                                            <IonGrid className={'p-0'}>
                                                <IonRow>
                                                    <IonCol size={'12'} size-md={'4'} className={'p-0 featured-img'} style={{
                                                        background: "url('" + blog.attributes.featured_image + "') center center no-repeat"
                                                    }}>
                                                    </IonCol>
                                                    <IonCol size={'12'} size-md={'8'} className={'vertical-align'}>
                                                        <IonCardContent>
                                                            <IonCardTitle>{blog.attributes.title}</IonCardTitle>
                                                            <IonCardSubtitle
                                                                className={'mt-10 mb-10'}>{momentjs(blog.attributes.published_at).tz('Europe/London').format('dddd, MMMM Do YYYY')}</IonCardSubtitle>
                                                            <p>{blog.attributes.meta_description}</p>
                                                            <IonButton className={'action primary mt-10 ion-float-right'}>Read more</IonButton>
                                                        </IonCardContent>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCard>
                                    )
                                })
                            }
                        </IonCol>
                        <IonCol size={'12'} size-md={'3'}>
                            <Sidebar/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </>
        }/>
    )
}

export default Latest;