import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonAlert, IonButton, IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonLoading, IonTextarea} from "@ionic/react";

import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

import Search from "../../assets/js/Search";
import Auth from "../../assets/js/Auth";
import Map from "../../components/Location/Map";
import axios from "axios";
import {FORM_MAX_CHARS, SMR_ENDPOINT} from "../../constants";
import MapContainer from "../../components/Location/MapContainer";

const FlagLocation = (props: any) => {

    // Declare empty object
    let init: any = {};

    const [locationId] = useState(props.match.params.id);
    const [location, setLocation] = useState(init);
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [flagText, setFlagText] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [showDoneAlert, setShowDoneAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [charsLeft, setCharsLeft] = useState(FORM_MAX_CHARS);
    const [showLoading, setShowLoading] = useState(false);


    const getToken = async () => {
        if (!executeRecaptcha) {
            return;
        }
        return await executeRecaptcha("place_request_edit");
    }

    const submitFlag = () => {
        setShowLoading(true);

        getToken().then(token => {
            if (flagText === '' || myEmail === '') {
                setShowLoading(false);
                setAlertText('Please make sure you have completed all fields.');
                setShowAlert(true);
                return false;
            } else {
                axios.post(
                    SMR_ENDPOINT + '/places/edit-request',
                    {
                        place_id: locationId,
                        email: myEmail,
                        data: flagText,
                        image: '',
                        recaptcha_token: token
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(
                    (response) => {
                        if (response.status === 201) {
                            setShowDoneAlert(true);
                        } else {
                            setAlertText('Something has gone wrong. Please try again.');
                            setShowAlert(true);
                        }
                        setShowLoading(false);
                    }, (error) => {
                        setAlertText('Something has gone wrong. Please try again.');
                        setShowAlert(true);
                        setShowLoading(false);
                    }
                );
            }
        });
    }

    const handleChange = (event) => {
        let input = event.target.value;
        if (charsLeft < 0) {
            input.substring(0, FORM_MAX_CHARS);
        }
        setFlagText(input);
        setCharsLeft(FORM_MAX_CHARS - input.length);
    }

    useEffect(() => {
        Search.searchById(locationId)
            .then(data => {
                setLocation(data.attributes);
            });
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    if (resp.data.email !== undefined && 'undefined') {
                        setMyEmail(resp.data.email);
                    }
                }).catch(err => {
                setAlertText('Something has gone wrong. Please try again.');
                setShowAlert(true);
            });
        }
    }, []);

    return (
        <Serveometer showBackButton={true} page={'Flag Location   '} breadcrumbTrail={
            [
                {pagetitle: 'Search', 'pathname': '/search'},
                {pagetitle: location.name, 'pathname': '/location/' + location.id}
            ]
        } showHeader={'true'} render={
            <div className={'container'}>
                <MapContainer location={location} noSelfBtn={true}/>
                <IonCard className={'mt-10'}>
                    <IonCardContent>
                        See a problem with a location? Use the form below to tell us.
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="floating">Your email</IonLabel>
                            <IonInput value={myEmail} onIonChange={e => setMyEmail(e.detail.value!)}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Describe the issue</IonLabel>
                            <IonTextarea autocapitalize={'on'} maxlength={2000} rows={6} cols={20} value={flagText} onIonChange={e => handleChange(e!)}></IonTextarea>
                            <p>Characters Left: {charsLeft}</p>
                        </IonItem>

                        <IonButton expand={'full'} onClick={() => {
                            submitFlag()
                        }}>SUBMIT</IonButton>
                    </IonCardContent>
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Flag location'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
                <IonAlert
                    isOpen={showDoneAlert}
                    onDidDismiss={() => setShowDoneAlert(false)}
                    cssClass='my-custom-class'
                    header={'Success!'}
                    subHeader={''}
                    message={'Thank you for letting us know about this. One of our team will investigate further.'}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            window.history.go(-1);
                        }
                    }]}
                />
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
            </div>
        }/>
    )
}

export default FlagLocation;