import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonButton, IonCard, IonCardContent, IonChip, IonCol, IonGrid, IonIcon, IonRow, isPlatform} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";

import Confetti from 'react-confetti'
import {ContentContext} from "../../Content";
import {logoFacebook, logoLinkedin, logoTwitter, logoWhatsapp, mail} from "ionicons/icons";
import {SocialSharing} from "@ionic-native/social-sharing";
import Sidebar from "../../components/Sidebar";

const ScoreSuccess = (props: any) => {

    const {content} = useContext(ContentContext);

    const [token, setToken] = useState('');
    const [scoreId] = useState(props.match.params.scoreId);

    useEffect(() => {
        if (props.match.params.authToken !== undefined && props.match.params.authToken !== 'undefined') {
            let searchParam = props.location.search.split("=");
            let idParam = decodeURIComponent(searchParam[1]);
            window.localStorage.setItem("SMR_ACCESS_TOKEN", props.match.params.authToken);
            window.localStorage.setItem("SMR_AUTH_USER_ID", idParam);
            window.localStorage.removeItem('SMR_ACTIVE_SUBMISSION_ID')
            setToken(props.match.params.authToken);
        }
    }, []);

    const sharingOptions = {
        //message: 'share this', // not supported on some apps (Facebook, Instagram)
        //subject: 'the subject', // fi. for email
        //files: ['', ''], // an array of filenames either locally or remotely
        url: 'https://serveometer.com/score/' + scoreId,
        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        iPadCoordinates: '0,0,0,0'
    };


    const AppShareNow = () => {
        //TODO: set sharing options
        SocialSharing.shareWithOptions(sharingOptions).then(res => {

        }).catch(err => {

        })
    };

    return (
        <Serveometer page={'Score Success'} showHeader={'true'} render={
            <div className={'score-success container'}>
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={false}
                    numberOfPieces={2000}
                    tweenDuration={30000}
                />
                <IonRow>
                    <IonCol size={'12'} size-md={'9'}>
                        <IonCard>
                            <IonCardContent>
                                <div className={'success-message'} dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'score-submitted-success-message')}}/>
                                <div className={'vertical-align justify-space-between mt-20'}>
                                    <IonGrid>
                                        {
                                            isPlatform('desktop') || isPlatform('mobileweb') ?
                                                <div className={'social-share-icons'}>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonChip onClick={() => {
                                                                window.open("mailto:?subject=&body=" + 'https://serveometer.com/score/' + scoreId, '_blank');
                                                            }} className={'ion-text-center mail'}>
                                                                <IonIcon icon={mail} className={'m-0'}/>
                                                            </IonChip>
                                                            <IonChip onClick={() => {
                                                                window.open("https://www.facebook.com/sharer/sharer.php?u=" + 'https://serveometer.com/score/' + scoreId, '_blank');
                                                            }} className={'ion-text-center facebook'}>
                                                                <IonIcon icon={logoFacebook} className={'m-0'}/>
                                                            </IonChip>
                                                            <IonChip onClick={() => {
                                                                window.open("https://twitter.com/intent/tweet?text=" + 'https://serveometer.com/score/' + scoreId, '_blank');
                                                            }} className={'ion-text-center twitter'}>
                                                                <IonIcon icon={logoTwitter} className={'m-0'}/>
                                                            </IonChip>
                                                            <IonChip onClick={() => {
                                                                window.open("https://api.whatsapp.com/send?text=" + 'https://serveometer.com/score/' + scoreId, '_blank');
                                                            }} className={'ion-text-center whatsapp'}>
                                                                <IonIcon icon={logoWhatsapp} className={'m-0'}/>
                                                            </IonChip>
                                                            <IonChip onClick={() => {
                                                                window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + 'https://serveometer.com/score/' + scoreId + "&title=&summary=&source=", '_blank');
                                                            }} className={'ion-text-center linkedin'}>
                                                                <IonIcon icon={logoLinkedin} className={'m-0'}/>
                                                            </IonChip>
                                                        </IonCol>
                                                    </IonRow>
                                                </div>
                                                :
                                                <IonRow>
                                                    <IonCol>
                                                        <div className={'socials mt-10'}>
                                                            <p><strong>Share this location with others...</strong></p>
                                                            <IonButton className={'action primary'} size="small" expand={'block'} onClick={() => {
                                                                AppShareNow();
                                                            }} color={'primary'}>Share...</IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                        }
                                    <div className={'actions'}>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton routerLink={'/score/' + scoreId} expand={'block'} className={'action primary'}>View your score</IonButton>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton routerLink={'/search'} expand={'block'} className={'action primary'}>Score another location</IonButton>
                                                </IonCol>
                                            </IonRow>

                                    </div>
                                    </IonGrid>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol size={'12'} size-md={'3'}>
                        <Sidebar/>
                    </IonCol>
                </IonRow>
            </div>
        }/>
    )
}

export default ScoreSuccess;