import React from 'react'
import {IonButton, IonCard, IonCardContent, IonChip, IonCol, IonIcon, IonItem, IonLabel, IonRow, isPlatform} from "@ionic/react";
import {logoFacebook, logoLinkedin, logoTwitter, logoWhatsapp, mail} from "ionicons/icons";
import {SocialSharing} from "@ionic-native/social-sharing";

const SocialShareIcons = (props: any) => {

    const sharingOptions = {
        //message: 'share this', // not supported on some apps (Facebook, Instagram)
        //subject: 'the subject', // fi. for email
        //files: ['', ''], // an array of filenames either locally or remotely
        url: window.location.href,
        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        iPadCoordinates: '0,0,0,0'
    };

    const AppShareNow = () => {
        //TODO: set sharing options
        SocialSharing.shareWithOptions(sharingOptions).then(res => {

        }).catch(err => {

        })
    };

    return (
        <IonCard className={'actions'}>
            <IonItem color="light" lines={"full"}>
                <IonLabel className={'vertical-align m-0 card-title small-title'}>
                    Share
                </IonLabel>
            </IonItem>
            <IonCardContent className={'p-5'}>
                {
                    isPlatform('desktop') || isPlatform('mobileweb') ?
                        <div className={'social-share-icons ion-text-center'}>
                            <IonChip onClick={() => {
                                window.open("mailto:?subject=&body=" + window.location.href, '_blank');
                            }} className={'ion-text-center mail'}>
                                <IonIcon icon={mail} className={'m-0'}/>
                            </IonChip>
                            <IonChip onClick={() => {
                                window.open("https://www.facebook.com/sharer/sharer.php?u=" + window.location.href, '_blank');
                            }} className={'ion-text-center facebook'}>
                                <IonIcon icon={logoFacebook} className={'m-0'}/>
                            </IonChip>
                            <IonChip onClick={() => {
                                window.open("https://twitter.com/intent/tweet?text=" + window.location.href, '_blank');
                            }} className={'ion-text-center twitter'}>
                                <IonIcon icon={logoTwitter} className={'m-0'}/>
                            </IonChip><br/>
                            <IonChip onClick={() => {
                                window.open("https://api.whatsapp.com/send?text=" + window.location.href, '_blank');
                            }} className={'ion-text-center whatsapp'}>
                                <IonIcon icon={logoWhatsapp} className={'m-0'}/>
                            </IonChip>
                            <IonChip onClick={() => {
                                window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href + "&title=&summary=&source=", '_blank');
                            }} className={'ion-text-center linkedin'}>
                                <IonIcon icon={logoLinkedin} className={'m-0'}/>
                            </IonChip>
                        </div>
                        :
                        <IonRow>
                            <IonCol>
                                <div className={'socials mt-10'}>
                                    <p><strong>Share this location with others...</strong></p>
                                    <IonButton className={'mt-20'} size="small" expand={'full'} onClick={() => {
                                        AppShareNow();
                                    }} color={'primary'}>Share...</IonButton>
                                </div>
                            </IonCol>
                        </IonRow>
                }
            </IonCardContent>
        </IonCard>
    )
};

export default SocialShareIcons;