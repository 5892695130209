import * as _ from "underscore";
import * as moment from "moment-timezone";
import merge from 'deepmerge';
import {shieldCheckmark, warning, } from "ionicons/icons";

export default {

    calculateScore: function (location) {
        if (location === undefined) return 0;
        if (location.score && location.max_score) {
            return Number((location.score / location.max_score * 5).toFixed(0));
        }
        return 0;
    },

    calculatePercentage: function (options, value) {
        if (options && Array.isArray(options)) {
            var sum = _.reduce(options, function (memo, option) {
                return memo + option.question_options_aggregate.count;
            }, 0);
            return Number((value / sum * 100).toFixed(0)) + "%";
        }
        return 0;
    },

    yesNoIcons: function (score) {
        return score < 100 ? warning : shieldCheckmark;
    },

    yesNoColours: function (score) {
        return score === 100 ? 'success' : (score < 100 && score > 0 ? 'warning' : 'danger');
    },

    yesNoSummaryColours: function (percent) {
        return percent > 50 ? 'success' : (percent < 50 ? 'danger' : 'warning');
    },

    getScoreClass: function (score) {
        switch (score) {
            case 1:
                return 'red';
            case 2:
                return 'orange';
            case 3:
                return 'yellow';
            case 4:
                return 'lime';
            case 5:
                return 'green';
            default:
                return 'grey';
        }
    },

    // Check if a nested property exists
    checkNested: function (obj, level, ...rest) {
        if (obj === undefined) return false;
        if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
        return this.checkNested(obj[level], ...rest)
    },

    //Update a data meta with lastUpdate date
    setLatestDate: function (obj, last_update) {
        last_update = this.getLatestDate(obj, last_update);
        obj = merge.all([obj, {data: {meta: {lastUpdate: last_update}}}]);
        return obj;
    },

    //let last updated timestamp from api object's data attributes
    getLatestDate: function (obj, last_update) {
        if (this.checkNested(obj, 'data', 'data', [0])) {
            let mostRecentItem = _.chain(obj['data']['data']).sortBy(function (item) {
                return item.attributes.updated_at;
            }).reverse().first().value();
            return moment(mostRecentItem.attributes.updated_at).unix();
        }
        return last_update;
    },

    //Deep merge of a nested object
    mergeObjects: function (old, updated, inner) {
        var o = {};

        if (inner !== undefined && 'undefined') {
            let oldMerge = old[0]['data'];
            let updatedMerge = updated[0]['data'];

            var r = [{
                data: {...oldMerge, ...updatedMerge},
                meta: updated[0]['meta']
            }];

            if (updated[0]['included'] !== undefined && 'undefined') {
                r['included'] = updated[0]['included'];
            }
            if (updated[0]['links'] !== undefined && 'undefined') {
                r['links'] = updated[0]['links'];
            }

            return r;
        } else {
            old.forEach(function (v) {
                o[v.id] = v;
            });

            updated.forEach(function (v) {
                o[v.id] = v;
            });

            var r = [];

            for (var p in o) {
                if (o.hasOwnProperty(p))
                    r.push(o[p]);
            }

            return r;
        }
    },

    getDistanceFromLatLon: function (lat1, lon1, lat2, lon2, unit = 'M') {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        } else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == "K") {
                dist = dist * 1.609344
            }
            if (unit == "N") {
                dist = dist * 0.8684
            }
            return dist;
        }
    },

    deg2rad: function (deg) {
        return deg * (Math.PI / 180)
    },

    setDistanceFromMe: function (obj, myLatLong, units = 'M') {
        for (let i = 0; i < obj.length; i++) {
            if (typeof parseFloat(obj[i]._source.latitude) === 'number' && typeof parseFloat(obj[i]._source.longitude) === 'number') {
                let distance = this.getDistanceFromLatLon(parseFloat(myLatLong.latitude),
                    parseFloat(myLatLong.longitude), parseFloat(obj[i]._source.latitude), parseFloat(obj[i]._source.longitude), units);
                obj[i]._source.distance = distance;
            } else {
                obj[i]._source.distance = 0;
            }
        }
        return obj;
    }
}