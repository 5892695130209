import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import Search from "../../assets/js/Search";
import Auth from "../../assets/js/Auth";
import {IonAlert, IonButton, IonCard, IonCardContent, IonInput, IonItem, IonLabel, IonLoading, IonTextarea} from "@ionic/react";
import Map from "../../components/Location/Map";
import Blocks from "../../assets/js/Blocks";
import {ContentContext} from "../../Content";
import MapContainer from "../../components/Location/MapContainer";
import {SMR_ENDPOINT} from "../../constants";
import axios from "axios";
import {useForm} from "react-hook-form";

const ClaimLocation = (props: any) => {


    const {content, dispatch} = useContext(ContentContext);
    // Declare empty object
    let init: any = {};

    const [locationId] = useState(props.match.params.id);
    const [location, setLocation] = useState(init);

    const {executeRecaptcha} = useGoogleReCaptcha();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [flagText, setFlagText] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [showDoneAlert, setShowDoneAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const {handleSubmit, register} = useForm();


    const getToken = async () => {
        if (!executeRecaptcha) {
            return;
        }
        return await executeRecaptcha("place_request_edit");
    }

    const submitFlag = (data) => {
        setShowLoading(true);
        setShowLoading(false);
        setShowDoneAlert(true);


        /*getToken().then(token => {
            if(myEmail === ''){
                setShowLoading(false);
                setAlertText('Please make sure you have completed all fields.');
                setShowAlert(true);
                return false;
            } else {
                axios.post(
                    SMR_ENDPOINT + '/places/claim',
                    {
                        data: {
                            type: "places",
                            attributes: {
                                place_id: locationId,
                                name: companyName,
                                address: location.address,
                                city: location.city
                            }
                        }
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                        }
                    }
                ).then(
                    (response) => {
                        if(response.status === 201) {
                            setShowDoneAlert(true);
                        } else {
                            console.log("success",response);
                            setAlertText('Something has gone wrong. Please try again.');
                            setShowAlert(true);
                        }
                        setShowLoading(false);
                    }, (error) => {
                        console.log("Error happended",error);
                        setAlertText('Something has gone wrong. Please try again.');
                        setShowAlert(true);
                        setShowLoading(false);
                    }
                );
            }
        });
*/
    }

    useEffect(() => {
        Search.searchById(locationId)
            .then(data => {
                setLocation(data.attributes);
            });
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    if (resp.data.email !== undefined && 'undefined') {
                        setMyEmail(resp.data.email);
                        setFirstName(resp.data.firstname);
                        setLastName(resp.data.lastname);
                    }
                }).catch(err => {
                setAlertText('Something has gone wrong. Please try again.');
                setShowAlert(true);
            });
        }
    }, []);

    return (
        <Serveometer page={'Claim Location'} showBackButton={true} breadcrumbTrail={
            [
                {pagetitle: 'Search', 'pathname': '/search'},
                {pagetitle: location.name, 'pathname': '/location/' + location.id}
            ]
        } showHeader={'true'} render={
            <div className={'container'}>
                <MapContainer location={location} noSelfBtn={true}/>
                <IonCard className={'mt-10'}>
                    <IonCardContent>
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'location-claim-form-intro')}}/>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardContent>
                        <form onSubmit={handleSubmit(submitFlag)}>
                            <IonItem lines={'full'}>
                                <IonLabel position="floating">First name</IonLabel>
                                <IonInput autocapitalize={'on'} value={firstName} onIonChange={e => setFirstName(e.detail.value!)}></IonInput>
                            </IonItem>
                            <IonItem lines={'full'}>
                                <IonLabel position="floating">Last name</IonLabel>
                                <IonInput autocapitalize={'on'} value={lastName} onIonChange={e => setLastName(e.detail.value!)}></IonInput>
                            </IonItem>
                            <IonItem lines={'full'}>
                                <IonLabel position="floating">Company Name</IonLabel>
                                <IonInput autocapitalize={'on'} value={companyName} onIonChange={e => setCompanyName(e.detail.value!)}></IonInput>
                            </IonItem>
                            <IonItem lines={'full'}>
                                <IonLabel position="floating">Telephone Number</IonLabel>
                                <IonInput value={telephoneNumber} onIonChange={e => setTelephoneNumber(e.detail.value!)}></IonInput>
                            </IonItem>
                            <IonItem lines={'full'}>
                                <IonLabel position="floating">Your email</IonLabel>
                                <IonInput value={myEmail} onIonChange={e => setMyEmail(e.detail.value!)}></IonInput>
                            </IonItem>
                            <GoogleReCaptchaProvider reCaptchaKey="6LeW4skZAAAAAAlyYx_73JZs4Z_fRuNlLG2CEXug">
                                <GoogleReCaptcha onVerify={token => console.log("GR-Token")}/>
                            </GoogleReCaptchaProvider>

                            <IonButton expand={'full'} type={'submit'} >Submit</IonButton>
                        </form>
                    </IonCardContent>
                </IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Claim location'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
                <IonAlert
                    isOpen={showDoneAlert}
                    onDidDismiss={() => setShowDoneAlert(false)}
                    cssClass='my-custom-class'
                    header={'Claim location'}
                    subHeader={'Success!'}
                    message={Blocks.getBlock(content, 'location-claim-form-done')}
                    buttons={[{
                        text: 'Continue',
                        handler: () => {
                            window.history.go(-1);
                        }
                    }]}
                />
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={20000}
                />
            </div>
        }/>
    )
}

export default ClaimLocation;