/* ENV SETUP */

export const ENV = window['ENV'] || 'development';

const API_HOSTS: any = {
    leelocal: 'https://serveometer.api/api/v1',
    development: 'https://devapi.serveometer.com/api/v1',
    production: 'https://api.serveometer.com/api/v1',
};


export const SMR_ENDPOINT = API_HOSTS[ENV];
export const SMR_ENV = ENV;
export const APP_VER = window['APP_VERSION'];

export const FORM_MAX_CHARS = 2000;
export const RECAPTCHA_V3_SITE_KEY = "6LeW4skZAAAAAAlyYx_73JZs4Z_fRuNlLG2CEXug";
export const TEST="Tests1";
const MAP_ACCESS_TOKEN = "pk.eyJ1Ijoic2FtZWtvIiwiYSI6ImNrZTN4ZzRzYTA0djEycm9ndHJ2d3BjamgifQ.sydb5OcRohsHvhfuk0Kt6Q";
export const MAP_IMG_URL = (lat: string, long: string) => (
    "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-l+000("
    + long + "," + lat + ")/" + long + "," + lat + ",16/1000x800?access_token=" + MAP_ACCESS_TOKEN
);
export const MAP_IMG_URL_MOB = (lat: string, long: string) => (
    "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-l+000("
    + long + "," + lat + ")/" + long + "," + lat + ",16/600x400?access_token=" + MAP_ACCESS_TOKEN
);