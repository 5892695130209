import axios from 'axios';
import {SMR_ENDPOINT} from '../../constants';

export default {
    submit: function (location, happend_at, answers) {

        //Remove null values
        //But why is it there?  Are we missing something?
        //@sam can you check. This should allow it to work though.
        var filtered = answers.filter(function (el) {
            return el != null;
        });

        return axios.post(
            SMR_ENDPOINT + '/submissions',
            {
                data: {
                    type: 'submissions',
                    attributes: {
                        place_id: location,
                        happened_at: happend_at,
                        answers: Object.values(filtered)
                    }
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                }
            }
        ).then(
            (response) => {
                if (response.status === 201) {
                    window.localStorage.removeItem('SMR_ACTIVE_SUBMISSION_LOCATION');
                    window.localStorage.removeItem('SMR_QUESTION');
                    return response.data;
                } else {
                    return response;
                }
            }, (error) => {
                return false;
            }
        );
    }
}