import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Pages from "../../assets/js/Pages";
import {IonCol, IonGrid, IonImg, IonRow} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";
import MetaTags from "../../components/MetaTags";

const DisabilityRightsUK: React.FC = () => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'disability-rights-uk';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={pageDetails.title} showHeader={'true'} showBackButton={true} render={
            <div className={'container-2'}>
                <MetaTags title={'ServeOmeter'} description={pageDetails.meta_description} />
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonImg src={'assets/imgs/DR UK logo.png'} style={{
                                width: '90%'
                            }} />
                        </IonCol>
                        <IonCol style={{
                            textAlign: 'right',
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <a href="https://widgets.justgiving.com/Button/Redirect?p=eyJUZXh0IjoiRGlzYWJpbGl0eSBSaWdodHMgVUsiLCJJZCI6ImJhYjg0MzkwLWU2NWItNDg4NS1hY2VjLTM0ZmJkYTY3NDNlOCIsIkNoYXJpdHlJZCI6MjQzMzk2LCJTaXplIjoicyIsIlJlZmVyZW5jZSI6InNlcnZlb21ldGVyIiwiVHlwZSI6IkNoYXJpdHlEb25hdGUifQ==">
                                <img src="https://widgets.justgiving.com/Button?p=eyJUZXh0IjoiRGlzYWJpbGl0eSBSaWdodHMgVUsiLCJJZCI6ImJhYjg0MzkwLWU2NWItNDg4NS1hY2VjLTM0ZmJkYTY3NDNlOCIsIkNoYXJpdHlJZCI6MjQzMzk2LCJTaXplIjoicyIsIlJlZmVyZW5jZSI6InNlcnZlb21ldGVyIiwiVHlwZSI6IkNoYXJpdHlEb25hdGUifQ==" style={{
                                    marginRight: '20px'
                                }} />
                            </a>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div>
                    {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                    <div className="ion-padding">
                        <div className="ion-padding" dangerouslySetInnerHTML={{
                            __html: pageDetails.content
                        }}/>
                    </div>
                </div>
                <div className={'ion-padding container'} style={{backgroundColor: '#250858'}} >
                    <div className={'ion-padding'} style={{textAlign: 'center'}} >
                        <h2 style={{color: 'white', fontWeight: 'bold'}}>
                            Click the video link below to see what Sophie Morgan has to say about our partnership:
                        </h2>
                        <br />
                        <div className="video">
                            <iframe width="100%" height="400"
                                    src="https://www.youtube.com/embed/OhvojIVJT-4"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        }/>
    )
}

export default DisabilityRightsUK;