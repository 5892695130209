import axios from "axios";
import {SMR_ENDPOINT} from '../../constants';
import Helper from "./Helper";

export default {

    loadFaqs: async function (page, count, last_update) {
        try {
            const response = await axios.get(SMR_ENDPOINT + '/faqs?page%5Bnumber%5D=' + page + '&page%5Bsize%5D=' + count + 'filter%5Bupdated_at%5D=' + last_update)
                .then((result) => {
                    return Helper.setLatestDate(result, last_update);
                });
            return response;
        } catch (error) {

        }
    },
}