import React, {useContext} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow, IonText} from '@ionic/react';
import {ContentContext} from "../../Content";
import Init from "../../components/Init";

const FAQ: React.FC = () => {

    const {content, dispatch} = useContext(ContentContext);

    return (
        <Serveometer page={'Frequently Asked Questions'} showBackButton={true} showHeader={'true'} render={
            <><Init type="faqs"></Init>
                {

                }
                <IonGrid className={'container'}>
                    <IonRow>
                        <IonCol>
                            <IonList>
                                {
                                    content.faqs.data.map((faq, index) => {
                                        return (
                                            <IonItem routerLink={'/extras/faq/' + faq.id} key={faq.id}>
                                                <IonLabel className="ion-text-wrap">
                                                    <IonText color="primary">
                                                        <h1>{faq.attributes.question}</h1>
                                                    </IonText>
                                                </IonLabel>
                                            </IonItem>
                                        )
                                    })
                                }
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </>
        }/>
    );
}

export default FAQ;