import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Pages from "../../assets/js/Pages";
import {IonButton, IonImg} from "@ionic/react";

const HowTo: React.FC = (props: any) => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'how-does-serveometer-work';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={'How Serveometer Works'} showHeader={'true'} showBackButton={true} render={
            <div>
                {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                <div className={'ion-padding container'} style={{backgroundColor: '#ffa900'}} >
                    <div className={'ion-padding'} style={{textAlign: 'center'}} >
                        <h1 style={{color: 'white', fontWeight: 'bold'}}>{pageDetails.title}</h1>
                        <br />
                        <div className="video">
                            <iframe width="100%" height="200"
                                    src="https://www.youtube.com/embed/vAvS-MfmBXk?autoplay=1&mute=1&loop=1&rel=0"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="ion-padding ion-no-padding-top container">
                    <div className="ion-padding ion-no-padding-top" dangerouslySetInnerHTML={{
                        __html: pageDetails.content
                    }}/>
                    <IonButton className={'action primary'} routerLink={'/search'} expand={'full'}>Start submitting your score</IonButton>
                </div>


            </div>
        }/>
    )
}

export default HowTo;