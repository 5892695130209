import React, {useContext, useState} from 'react';
import Serveometer from "../components/Serveometer";

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonItemDivider,
    IonRow
} from "@ionic/react";

import Auth from '../assets/js/Auth';
import {SMR_ENV} from '../constants';

import Blocks from '../assets/js/Blocks';
import {
    arrowDown,
    arrowUp,
    chevronDown,
    chevronUp,
    informationCircle,
    logIn,
    logOut,
    mail,
    people,
    person
} from "ionicons/icons";
import {ContentContext} from "../Content";
import Media from "react-media";

const Extras = (props: any) => {

    const {content} = useContext(ContentContext);

    const [showDevTools, setShowDevTools] = useState(false);

    return (
        <Serveometer page={'Extras'} showHeader={true} render={
            <div className={'extra-tiles'}>
                <IonGrid className={'container'}>
                    <IonRow>
                        <IonCol>
                            <IonCard style={{
                                marginLeft: '0',
                                marginRight: '0'
                            }}>
                                <IonCardContent>
                                    <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'extras-page-summary')}}/>
                                </IonCardContent>
                            </IonCard>
                            <IonGrid style={{
                                paddingLeft: '0',
                                paddingRight: '0'
                            }}>
                                {
                                    Auth.isAuthenticated() ?
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className={'action primary'} routerLink={'/extras/my-profile'} expand={'full'}>
                                                    <div className={'inner'}>
                                                        <IonIcon icon={person}/><br/>
                                                        My Profile
                                                    </div>
                                                </IonButton>
                                            </IonCol>
                                            <IonCol>
                                                <IonButton className={'action primary'} routerLink={'/logout'} expand={'full'}>
                                                    <div className={'inner'}>
                                                        <IonIcon icon={logOut}/><br/>
                                                        Logout
                                                    </div>
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                        :
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className={'action primary'} routerLink={'/login'} expand={'full'}>
                                                    <div className={'inner'}>
                                                        <IonIcon icon={logIn}/><br/>
                                                        Login
                                                    </div>
                                                </IonButton>
                                            </IonCol>
                                            <IonCol>
                                                <IonButton className={'action primary'} routerLink={'/register/form'} expand={'full'}>
                                                    <div className={'inner'}>
                                                        <IonIcon icon={people}/><br/>
                                                        Register
                                                    </div>
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                }
                                <IonRow>
                                    <IonCol>
                                        <IonButton className={'action primary'} routerLink={'/extras/faq'} expand={'full'}>
                                            <div className={'inner'}>
                                                <IonIcon icon={informationCircle}/><br/>
                                                FAQs
                                            </div>
                                        </IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton className={'action primary'} routerLink={'/extras/contact'} expand={'full'}>
                                            <div className={'inner'}>
                                                <IonIcon icon={mail}/><br/>
                                                Contact
                                            </div>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton className={'action primary'} routerLink={'/extras/terms'} expand={'full'}>Terms & Conditions</IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton className={'action primary'} routerLink={'/extras/privacy'} expand={'full'}>Privacy Policy</IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    {/*<IonCol>
                                        <IonButton className={'action primary'} routerLink={'/extras/newsletter'} expand={'full'}>Newsletter</IonButton>
                                    </IonCol>*/}
                                    <IonCol size={'12'} size-md={'12'}>
                                        <IonButton className={'action primary'} routerLink={'/extras/disability-rights-uk'} expand={'full'}>Disability Rights UK</IonButton>
                                    </IonCol>
                                    <Media query="(max-width: 768px)">
                                        {matches =>
                                            matches ? (
                                                <IonCol size={'12'} size-md={'12'}>
                                                    <IonImg src={'assets/imgs/welcome-banner.png'} onClick={() => {

                                                        window.location.href = '/extras/how-serveometer-works';
                                                    }}/>
                                                </IonCol>
                                            ) : ''
                                        }
                                    </Media>
                                </IonRow>
                                {
                                    /*SMR_ENV === 'development' ?
                                        <IonRow>
                                            <IonCol>

                                                    <IonButton className={'action primary'} style={{
                                                        height: '50px'
                                                    }} onClick={() => {
                                                        !showDevTools ? setShowDevTools(true) : setShowDevTools(false);
                                                    }} expand={'full'} >Development tools {
                                                        !showDevTools ? <IonIcon style={{marginLeft: '10px'}} size={'small'} icon={chevronDown}/> : <IonIcon style={{marginLeft: '10px'}} size={'small'} icon={chevronUp}/>
                                                    } </IonButton>

                                                {
                                                    showDevTools ?
                                                        <>
                                                            <IonGrid style={{
                                                                paddingLeft: '0',
                                                                paddingRight: '0'
                                                            }}>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <IonButton className={'action primary'} expand={'full'} onClick={() => {
                                                                            window.localStorage.clear();
                                                                            window.location.href = '/';
                                                                        }}>Clear All Storage & Logout</IonButton>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                            <IonGrid style={{
                                                                paddingLeft: '0',
                                                                paddingRight: '0'
                                                            }}>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <IonButton className={'action primary'} expand={'full'} onClick={() => {
                                                                            window.localStorage.removeItem('content');
                                                                            window.localStorage.removeItem('data');
                                                                            window.localStorage.removeItem('SMR_INTRO_SEEN');
                                                                            window.location.href = '/';
                                                                        }}>Clear All Storage & Remain logged in</IonButton>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                            <IonGrid style={{
                                                                paddingLeft: '0',
                                                                paddingRight: '0'
                                                            }}>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <IonButton className={'action primary'} expand={'full'} onClick={() => {
                                                                            window.localStorage.removeItem('SMR_INTRO_SEEN');
                                                                            window.location.href = '/';
                                                                        }}>Clear welcome screen flag</IonButton>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </>
                                                        :
                                                        ''
                                                }

                                            </IonCol>
                                        </IonRow>
                                        :
                                        ''*/
                                }

                            </IonGrid>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        }/>
    );
};

export default Extras;
