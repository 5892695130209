import React from 'react';
import {IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/react';

import {chevronBackSharp, logIn, logoFacebook, logoLinkedin, logoTwitter, logOut, personCircleOutline} from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import Media from "react-media";

import Auth from '../assets/js/Auth';
import Breadcrumbs from "./Breadcrumbs";
import {useLocation} from "react-router";

const Serveometer = (props: any) => {

    const location = useLocation();
    const thisyear = new Date().getFullYear();

    let backButton: any;
    if (props.showBackButton) {
        backButton = (<IonButtons slot="start">
            <IonIcon className={'serveometer-back-button'} size={'large'} icon={chevronBackSharp} onClick={() => {
                if (props.backButtonURL) {
                    window.location.href = props.backButtonURL;
                } else {
                    window.history.go(-1);
                }
            }}/>
        </IonButtons>);
    } else {
        backButton = "";
    }

    return (
        <IonPage>
            {props.showHeader ?
                <IonHeader>
                    <IonToolbar className={'header'}>
                        <Media query="(min-width: 768px)">
                            {
                                matches =>
                                    matches ? (
                                        <div className={'desktop container'}>
                                            <IonGrid>
                                                <IonRow class="ion-align-items-center">
                                                    <IonCol sizeLg={'12'}>
                                                        <IonImg onClick={() => {
                                                            window.location.href = '/';
                                                        }} class="logo" src={'assets/imgs/ServeometerDark.svg'}/>
                                                        <IonButtons>
                                                            <div className={'nav-items'}>
                                                                <IonButton href={'/search'}>
                                                                    <IonLabel>Search</IonLabel>
                                                                </IonButton>
                                                                <IonButton href={'/search?recent=1'}>
                                                                    <IonLabel>Recent Scores</IonLabel>
                                                                </IonButton>
                                                                <IonButton href={'/extras/disability-rights-uk'}>
                                                                    <IonLabel>Disability Rights UK</IonLabel>
                                                                </IonButton>
                                                                <IonButton href={'/offers'}>
                                                                    <IonLabel>Offers</IonLabel>
                                                                </IonButton>
                                                                <IonButton href={'/extras'}>
                                                                    <IonLabel>Extras</IonLabel>
                                                                </IonButton>
                                                            </div>
                                                            {
                                                                Auth.isAuthenticated() ?
                                                                    <div className={'auth-links'}>
                                                                        <IonButton size={'small'}
                                                                                   fill={'solid'}
                                                                                   className={'action primary small'}
                                                                                   routerLink={'/extras/my-profile'}>
                                                                            <IonIcon icon={personCircleOutline}/>
                                                                            My Profile</IonButton>
                                                                        <IonButton data-cy={'logout'}
                                                                                   size={'small'}
                                                                                   fill={'solid'}
                                                                                   className={'action primary small'}
                                                                                   routerLink={'/logout'}>
                                                                            <IonIcon icon={logOut}/>
                                                                            Logout</IonButton>
                                                                    </div>
                                                                    :
                                                                    <div className={'auth-links'}>
                                                                        <IonButton size={'default'}
                                                                                   fill={'solid'}
                                                                                   className={'action primary'}
                                                                                   routerLink={'/register'}>
                                                                            <IonIcon slot="start" icon={personCircleOutline}/>
                                                                            Register</IonButton>
                                                                        <IonButton size={'default'}
                                                                                   fill={'solid'}
                                                                                   className={'action primary'}
                                                                                   routerLink={'/login'}>
                                                                            <IonIcon slot="start" icon={logIn}/>
                                                                            Login</IonButton>
                                                                    </div>
                                                            }
                                                        </IonButtons>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </div>
                                    ) : (
                                        <div>
                                            <IonTitle>{props.page}</IonTitle>
                                            {backButton}
                                        </div>
                                    )
                            }
                        </Media>
                    </IonToolbar>
                </IonHeader> : ""}
            <IonContent ref={props.scrollRef ?? props.scrollRef} scrollEvents={true}>
                {
                    location.pathname.split('/')[1] === 'location' && location.pathname.split('/')[3] === 'score' ?
                        <>
                            <Media query="(min-width: 768px)">
                                {matches =>
                                    matches && !props.hideBreadcrumbs ? (
                                        <Breadcrumbs pagetitle={props.page} trail={props.breadcrumbTrail ? props.breadcrumbTrail : 0}/>
                                    ) : ''
                                }
                            </Media>
                            {props.render}
                        </>
                        :
                        <div style={{
                            minHeight: '100%',
                            marginBottom: '-290px'
                        }}>
                            <Media query="(min-width: 768px)">
                                {matches =>
                                    matches && !props.hideBreadcrumbs ? (
                                        <Breadcrumbs pagetitle={props.page} trail={props.breadcrumbTrail ? props.breadcrumbTrail : 0}/>
                                    ) : ''
                                }
                            </Media>
                            {props.render}
                            <div style={{
                                height: '290px'
                            }}></div>
                        </div>
                }

                <Media query="(min-width: 768px)">
                    {matches =>
                        matches ? (
                            props.hideFooter ? ''
                                : <IonFooter id={'web-lg-footer'}>
                                    <IonGrid className={'container'}>
                                        <IonRow>
                                            <IonCol size={'6'}>
                                                <IonImg class="footer-logo"
                                                        src={'assets/imgs/ServeometerPop.png'}
                                                        alt={'Serveometer Logo'}/>
                                            </IonCol>
                                            <IonCol size={'3'}>
                                                <ul className={'footer-menu'}>
                                                    <li>
                                                        <a href={'/search'} title={'Search places'}>Search</a>
                                                    </li>
                                                    <li>
                                                        <a href={'/latest'} title={'See latest updates'}>News</a>
                                                    </li>
                                                    <li>
                                                        <a href={'/search?recent=1'} title={'Recent Scores'}>Recent Scores</a>
                                                    </li>
                                                    <li>
                                                        <a href={'/offers'} title={'Offers'}>Offers</a>
                                                    </li>
                                                    <li>
                                                        <a href={'/extras/faq'} title={'FAQs'}>FAQs</a>
                                                    </li>
                                                </ul>
                                            </IonCol>
                                            <IonCol size={'3'}>
                                                <ul className={'footer-menu'}>
                                                    <li>
                                                        <a href={'/extras/disability-rights-uk'} title={'Disability Rights UK'}>Disability Rights UK</a>
                                                    </li>
                                                    {/*<li>
                                                        <a href={'/extras/newsletter'} title={'Newsletter'}>Newsletter</a>
                                                    </li>*/}
                                                    <li>
                                                        <a href={'/extras'} title={'Extras'}>Extras</a>
                                                    </li>
                                                    <li>
                                                        <a href={'/terms'} title={'Terms & Conditions'}>Terms & Conditions</a>
                                                    </li>
                                                    <li>
                                                        <a href={'/privacy'} title={'Privacy Policy'}>Privacy Policy</a>
                                                    </li>
                                                </ul>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className={'mt-20'}>
                                            <IonCol size={'6'}>
                                                <div className={'socials'}>
                                                    <IonIcon icon={logoFacebook} onClick={() => {
                                                        window.open("https://www.facebook.com/sharer/sharer.php?u=" + window.location.href, '_blank');
                                                    }}/>
                                                    <IonIcon icon={logoTwitter} onClick={() => {
                                                        window.open("https://twitter.com/intent/tweet?text=" + window.location.href, '_blank');
                                                    }}/>
                                                    <IonIcon icon={logoLinkedin} onClick={() => {
                                                        window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href + "&title=&summary=&source=", '_blank');
                                                    }}/>
                                                </div>
                                            </IonCol>
                                            <IonCol size={'6'}>
                                                <strong>&#169;&nbsp;ServeOmeter 2020-{thisyear}. All rights reserved.</strong>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonFooter>
                        ) : (
                            ''
                        )
                    }
                </Media>
            </IonContent>
        </IonPage>
    )
};

export default Serveometer;
