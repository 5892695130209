import React from 'react'
import {IonButton, IonCol, IonRow} from "@ionic/react";
import Map from "../../components/Location/Map";
import Helper from "../../assets/js/Helper";
import Chevrons from "./Chevrons";
import {Link} from "react-router-dom";
import * as moment from "moment-timezone";
import Media from "react-media";

const MapContainer = (props: any) => {

    let momentjs: any;
    momentjs = moment;

    return (
        <Map location={props.location} render={
            <>
                <h1 className={'ion-no-margin sm-title mb-5'}>A ServeOmeter Score for <Link to={'/location/' + props.scoreDetails.place_id}>{props.location.name}</Link></h1>
                <p className={'light-text m-0 address'}>{props.location.address}, {props.location.city}, {props.location.province}, {props.location.postalCode}</p>
                <hr/>
                <p className={'m-0 light-text'}>By <strong>{props.scoreDetails.submitted_by}</strong> on {momentjs(props.scoreDetails.created_at).tz('Europe/London').format('Do MMM YYYY')}</p>
                <hr/>
                <Media query="(max-width: 768px)">
                    {matches =>
                        matches ? (
                            <>
                                {
                                    props.scoreDetails.scores ?
                                        <Chevrons score={Helper.calculateScore(props.scoreDetails.scores[0])}/>
                                        : ''
                                }
                                <hr/>
                                <IonRow>
                                    <IonCol size={'6'}>
                                        <IonButton className={'action primary'} expand={'full'}
                                                   routerLink={'/location/' + props.scoreDetails.place_id + '/score'}>
                                            Add Yours
                                        </IonButton>
                                    </IonCol>
                                    <IonCol size={'6'}>
                                        <IonButton className={'action secondary'} expand={'full'}
                                                   routerLink={'/location/' + props.scoreDetails.place_id}>
                                            View Location
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        ) : (
                            <div className={'vertical-align justify-space-between'}>
                                {
                                    props.scoreDetails.scores ?
                                        <Chevrons score={Helper.calculateScore(props.scoreDetails.scores[0])}/>
                                        : ''
                                }
                                <IonRow>
                                    <IonButton className={'action primary mr-10'}
                                               routerLink={'/location/' + props.scoreDetails.place_id + '/score'}>
                                        Add Yours
                                    </IonButton>
                                    <IonButton className={'action secondary'}
                                               routerLink={'/location/' + props.scoreDetails.place_id}>
                                        View Location
                                    </IonButton>
                                </IonRow>
                            </div>
                        )
                    }
                </Media>
            </>
        }/>
    )
};

export default MapContainer;