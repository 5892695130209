import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import {IonAlert, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow} from "@ionic/react";
import Blocks from "../../assets/js/Blocks";

// Demo styles, see 'Styles' section below for some notes on us
import 'react-accessible-accordion/dist/fancy-example.css';
import {key, logOut, pencil} from "ionicons/icons";
import Auth from "../../assets/js/Auth";
import {ContentContext} from "../../Content";

const MyProfile: React.FC = () => {
    const {content,dispatch} = useContext(ContentContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [myEmail, setMyEmail] = useState('');
    const [disability, setDisability] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    useEffect(() => {
        if (Auth.isAuthenticated()) {
            Auth.getUser()
                .then(resp => {
                    if (resp.data.email !== undefined && 'undefined') {
                        setMyEmail(resp.data.email);
                        setFirstName(resp.data.firstname);
                        setLastName(resp.data.lastname);
                        setDisability(resp.data.disability);
                    }
                }).catch(err => {
                setAlertText('Something has gone wrong. Please try again.');
                setShowAlert(true);
            });
        }
    }, []);

    return (
        <Serveometer page={'My Profile'} showBackButton={true} showHeader={'true'} render={
            <div className={'extra-tiles'}>
                <IonCard>
                    <IonCardContent>
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'my-profile-intro')}}/>
                    </IonCardContent>
                </IonCard>
                <IonItem lines={'full'}>
                    {firstName + ' ' + lastName}
                </IonItem>
                <IonItem lines={'full'}>
                    {myEmail}
                </IonItem>
                {/*<IonItem lines={'full'}>
                    <IonLabel position={'floating'}>Disability</IonLabel>
                    <IonInput readonly value={disability}/>
                </IonItem>*/}
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton className={'action primary'} routerLink={'/extras/my-profile/edit'} expand={'full'}>
                                <div className={'inner'}>
                                    <IonIcon icon={pencil}/><br/>
                                    Edit Profile
                                </div>
                            </IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton className={'action primary'} routerLink={'/extras/my-profile/change-password'} expand={'full'}>
                                <div className={'inner'}>
                                    <IonIcon icon={key}/><br/>
                                    Change Password
                                </div>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton className={'action primary'} routerLink={'/logout'} expand={'full'}>
                                <div className={'inner'}>
                                    <IonIcon icon={logOut}/><br/>
                                    Logout
                                </div>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Flag location'}
                    subHeader={''}
                    message={alertText}
                    buttons={[{
                        text: 'OK',
                        handler: () => {

                        }
                    }]}
                />
            </div>
        }/>
    )
}

export default MyProfile;