import React, {createContext, useEffect, useReducer} from "react";
import {useLocalStorage} from "./useLocalStorage";
import Helper from "./assets/js/Helper";

const initialState = {

    blocks: {
        data: [],
        meta: {
            lastUpdate: 0,
        },
    },

    blogs: {
        data: [],
        links: {},
        meta: {
            lastUpdate: 0,
            page: {
                'current-page': 1,
                'per-page': 20,
            },

        },
    },
    faqs: {
        data: [],
        links: [],
        meta: {
            lastUpdate: 0,
            page: {
                'current-page': 1,
                'per-page': 20,
            }
        }
    },
    offers: {
        data: [],
        links: {},
        meta: {
            lastUpdate: 0,
            page: {
                'current-page': 1,
                'per-page': 20,
                'last-page': 1
            }
        }
    },
    locations: []
};


let ContentContext = createContext(initialState as any);

let reducer = (state, action) => {
    switch (action.type) {
        case "updateFAQ": {
            let data = Helper.mergeObjects([state.faqs], [action.faqs]);
            return {...state, faqs: data[0]}
        }
        case "updateBlocks": {
            let data = Helper.mergeObjects([state.blocks], [action.blocks], true);
            return {...state, blocks: data[0]}
        }
        case "updateBlogs": {
            let data = Helper.mergeObjects([state.blogs], [action.blogs]);
            return {...state, blogs: data[0]}
        }
        case "updateOffers": {
            let data = Helper.mergeObjects([state.offers], [action.offers]);
            return {...state, offers: data[0]}
        }

        case "updateLocations": {
            let data = Helper.mergeObjects([state.locations], [action.locations]);
            return {...state, locations: data[0]}
        }
        case "setLastUpdate": {
            return {...state, lastUpdate: action.set}
        }

    }
    return state;
};


const logger = (reducer) => {
    const reducerWithLogger = (state, action) => {
        //@Sam Can we make this development only?
        // console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
        // console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
        // console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
        return reducer(state, action);
    };
    return reducerWithLogger;
};

const loggerReducer = logger(reducer);


function ContentContextProvider(props) {

    const [contentStore, setContentStore] = useLocalStorage('content', initialState);

    let [content, dispatch] = useReducer(loggerReducer, contentStore);

    let value = {content, dispatch};

    useEffect(() => {
        setContentStore(content);
    }, [content, setContentStore]);

    return (
        <ContentContext.Provider value={value}>{props.children}</ContentContext.Provider>
    );
}

let ContextConsumer = ContentContext.Consumer;

export {ContentContext, ContentContextProvider, ContextConsumer};