import React from 'react';
import Serveometer from "../../components/Serveometer";

const OfferDetails: React.FC = () => {
    return (
        <Serveometer page={'Offer Details'} showHeader={'true'} render={
            <div>

            </div>
        }/>
    )
}

export default OfferDetails;