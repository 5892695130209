import axios from "axios";
import {SMR_ENDPOINT} from "../../constants";
import * as _ from "underscore";
import Helper from "./Helper";

export default {

    findById: async function (content, id) {
        let locations = content.locations;
        if (Array.isArray(locations) && locations.some(location => location.id === id)) {
            //Return the ID. Then we know not to resubmit to local storage
            return content.locations.map(l => l.id).indexOf(id);
        }
        return this.loadLocation(id);
    },

    delete: async function (id) {
        try {
            let url = (SMR_ENDPOINT + '/places/trash/' + id);
            const response = await axios.delete(url,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                    }
                }).then((r) => {
                if (r.status === 204) {
                    return true;
                } else {
                    return false;
                }
            });
            return response;
        } catch (error) {
            return false;
        }
    },

    loadLocation: async function (id) {
        try {
            let url = (SMR_ENDPOINT + '/places/' + id);
            const response = await axios.get(url)
                .then((result) => {
                    if (Helper.checkNested(result, 'data', 'data', 'id')) {
                        let locationAttributes = result.data.data.attributes;

                        //Reindex to make the stats more accessible
                        if ('statistics' in locationAttributes && 'packs' in locationAttributes.statistics) {
                            locationAttributes.statistics.answers = {};
                            locationAttributes.statistics.answers.scoreable = _.filter(locationAttributes.statistics.packs, function (pack) {
                                return pack.is_scoreable
                            });
                            locationAttributes.statistics.answers.comments = _.filter(locationAttributes.statistics.packs, function (pack) {
                                return pack.is_comments
                            });
                            locationAttributes.statistics.answers.yesnos = _.chain(locationAttributes.statistics.packs)
                                .filter(function (pack) {
                                    return !pack.is_comments && !pack.is_scoreable
                                })
                                .each(function (pack) {
                                    pack.questions = _.sortBy(pack.questions, function (q) {
                                        return q.score;
                                    });
                                    pack.questions.reverse();
                                }).value();
                        }
                        result.data.data.attributes = locationAttributes;
                        return result.data.data;
                    }
                    return false;
                });
            return response;
        } catch (error) {
            return false;
        }
    },
    registerLocation: async function (data) {
        try {
            const response = await axios.post(
                SMR_ENDPOINT + '/places/register',
                {
                    data: {
                        type: "places",
                        attributes: {
                            name: data.name,
                            address: data.address,
                            address2: data.address2,
                            city: data.city,
                            province: data.province,
                            postalCode: data.postalCode,
                            country: data.country,
                            description: data.description
                        }
                    }
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                    }
                }
            ).then(
                (response) => {
                    if (response.status === 201) {
                        return response.data;
                    } else {
                        return response;
                    }
                }, (error) => {
                    return false;
                }
            ).catch((err) => {
                return false;
            });
            return response;
        } catch (error) {
            return false;
        }

    }
}