import React, {useContext, useEffect, useState} from 'react';

import Serveometer from "../../components/Serveometer";
import RegisterForm from "../../components/RegisterFrom";
import {IonCard, IonCardContent} from "@ionic/react";

const Register = (props: any) => {

    let url = props.match.url;
    let location = props.match.params.location;

    return (
        <Serveometer page={'Register'} showBackButton={true} showHeader={'true'} render={
            <div className={'container'}>
                <IonCard className={'auth-form'}>
                    <IonCardContent>
                        <RegisterForm registerFrom={url} loginCheck={true} location={location} />
                    </IonCardContent>
                </IonCard>
            </div>
        }/>
    )
};

export default Register;
