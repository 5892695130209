import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

import {Deeplinks} from '@ionic-native/deeplinks';

import awsconfig from './aws-exports';

import CookieConsent from 'react-cookie-consent';

/* AUTH */
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Logout from './pages/Auth/Logout';
import PasswordResetRequest from './pages/Auth/PasswordResetRequest';
import RegisterConfirmation from './pages/Auth/RegisterConfirmation';
import RegistrationSuccess from './pages/Auth/RegistrationSuccess';
import ResetPassword from './pages/Auth/ResetPassword';
import VerifyAccount from './pages/Auth/VerifyAccount';

/* BLOG */
import BlogPost from './pages/Blog/BlogPost';

/* EXTRAS */
import About from './pages/Extras/About';
import Contact from './pages/Extras/Contact';
import FAQ from './pages/Extras/FAQ';
import FaqItem from './pages/Extras/FaqItem';
import MyProfile from './pages/Extras/MyProfile';
import Privacy from './pages/Extras/Privacy';
import Terms from './pages/Extras/Terms';

/* EXTRAS/PROFILE */
import ChangePassword from './pages/Extras/Profile/ChangePassword';
import Edit from './pages/Extras/Profile/Edit';

/* LOCATION */
import LocationDetailImages from "./pages/Location/LocationDetailImages";
import ClaimLocation from "./pages/Location/ClaimLocation";
import FlagLocation from "./pages/Location/FlagLocation";
import FlagLocationScore from "./pages/Location/FlagLocationScore";
import LocationSubscribe from "./pages/Location/LocationSubscribe";
import LocationView from "./pages/Location/View";
import LocationScores from "./pages/Location/Scores";

/* Score */
import Begin from './pages/Score/Begin';
import Submit from "./pages/Score/Submit";
import View from "./pages/Score/View";

/* OFFERS */
import OfferDetails from './pages/Offers/OfferDetails';

/* USER */
import User from './pages/User/UserProfile';

/* TABS */
import Extras from './pages/Extras';
import Search from './pages/Search';
import Latest from './pages/Latest';
import Offers from './pages/Offers';

/* WELCOME */
import WelcomeScreen from './pages/Welcome';
import Home from './pages/Web/Home';

/* MISC/UNUSED */
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import FourOFour from "./pages/404";
import NoConnection from "./components/NoConnection";

/* MEDIA COMPONENT */
import Media from "react-media";

/* AUTHENTICATION MODULE */
import Authenticate from './assets/js/Auth';

/* URL LISTENER */
import AppUrlListener from "./components/AppUrlListener";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import {businessSharp, giftSharp, newspaperSharp, personCircle} from 'ionicons/icons';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/styles.scss';
import {APP_VER, SMR_ENDPOINT, SMR_ENV} from "./constants";
import ScoreSuccess from "./pages/Score/Success";
import {AppContext, AppContextProvider} from "./State";
import {ContentContextProvider} from "./Content";

import Init from "./components/Init";
import UnVerified from "./components/UnVerified";
import ProposeLocation from "./pages/Location/ProposeLocation";
import {Plugins} from "@capacitor/core";
import Amplify, { Analytics } from 'aws-amplify';
import HowTo from "./pages/Extras/HowTo";
import ScoreSuccessPending from "./pages/Score/ScoreSuccessPending";
import Business from "./pages/Extras/Business";
import {forceUpdate} from "ionicons/dist/types/stencil-public-runtime";
import DisabilityRightsUK from "./pages/Extras/DisabilityRightsUK";
import Newsletter from "./pages/Extras/Newsletter";
import {GoogleAnalytics} from "@ionic-native/google-analytics";

const App: React.FC = () => {
    const [showLoading, setShowLoading] = useState(false);
    const {Device} = Plugins;

    //Triggers on each view
    GoogleAnalytics.startTrackerWithId('G-5L19M9GB80').then(() => {
        //console.log('Google Analytics Ready');
    }).catch(e => console.log('Error starting Google Analytics', e));

    const deviceInfo = async () => {
        return await Device.getInfo();
    }

    const initAmplify = () => {
        /* Used for PinPoint logging */
        //Testing
        Amplify.configure(awsconfig);

        deviceInfo().then(function(info){


            Analytics.updateEndpoint({
                demographic: {
                    appVersion: APP_VER, // The version of the application associated with the endpoint.
                    locale: 'en_GB', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
                    make: info.manufacturer, // The manufacturer of the endpoint device, such as Apple or Samsung.
                    model: info.model, // The model name or number of the endpoint device, such as iPhone.
                    modelVersion: info.uuid, // The model version of the endpoint device.
                    platform: info.osVersion, // The platform of the endpoint device, such as iOS or Android.
                    platformVersion: info.operatingSystem // The platform version of the endpoint device.
                }
            });
        });
        Analytics.autoTrack('pageView', {
            // REQUIRED, turn on/off the auto tracking
            enable: true,
            // OPTIONAL, the event name, by default is 'pageView'
            eventName: 'pageView',
            // OPTIONAL, the attributes of the event, you can either pass an object or a function
            // which allows you to define dynamic attributes
            // attributes: {
            //     attr: 'attr'
            // },
            // when using function
            // attributes: () => {
            //    const attr = somewhere();
            //    return {
            //        myAttr: attr
            //    }
            // },
            // OPTIONAL, by default is 'multiPageApp'
            // you need to change it to 'SPA' if your app is a single-page app like React
            type: 'SPA',
            // OPTIONAL, the service provider, by default is the Amazon Pinpoint
            provider: 'AWSPinpoint',
            // OPTIONAL, to get the current page url
            getUrl: () => {
                // the default function
                return window.location.origin + window.location.pathname;
            }
        });
        Analytics.autoTrack('session', {
            // REQUIRED, turn on/off the auto tracking
            enable: true,
            // OPTIONAL, the attributes of the event, you can either pass an object or a function
            // which allows you to define dynamic attributes
            attributes: {
                app_version: APP_VER
            },
            // when using function
            // attributes: () => {
            //    const attr = somewhere();
            //    return {
            //        myAttr: attr
            //    }
            // },
            // OPTIONAL, the service provider, by default is the Amazon Pinpoint
            provider: 'AWSPinpoint'
        });

    }

    Deeplinks.route({
        '/reset-password/:token': ResetPassword
    }).subscribe(match => {
        //console.log('Successfully matched route', match);
    }, nomatch => {
        //console.error('Got a deeplink that didn\'t match', nomatch);
    });

    /*const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    function debounce(fn, ms) {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(_ => {
                timer = null
                fn.apply(this)
            }, ms)
        };
    }*/

    useEffect(() => {
        //Do on App load

        initAmplify();

        /*const debouncedHandleResize = debounce(function handleResize() {
            if(dimensions.width > 600 && window.innerWidth < 600){
                window.location.reload();
            }
            if(dimensions.width < 601 && window.innerWidth > 601){
                window.location.reload();
            }
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }*/
    });

    return (

        <IonApp>
            <AppContextProvider>
                <ContentContextProvider>
                    <Init type={'all'}></Init>
                    <NoConnection></NoConnection>
                    <UnVerified></UnVerified>
                    <IonReactRouter>
                        <Media query="(max-width: 599px)">
                            {matches =>
                                matches && ((isPlatform('mobile') || isPlatform('ios') || isPlatform('electron')|| isPlatform('mobileweb') || isPlatform('desktop')  || isPlatform('tablet'))) ? (
                                    <IonTabs>
                                        <AppUrlListener></AppUrlListener>
                                        <IonRouterOutlet>
                                            {/* AUTH */}
                                            <Route path="/login" component={Login}/>
                                            {
                                                Authenticate.isAuthenticated() ?
                                                    <Route path="/register" component={MyProfile} exact={true}/>
                                                    :
                                                    <Route path="/register" component={Register} exact={true}/>
                                            }

                                            <Route path="/register/form" component={Register} exact={true}/>
                                            <Route path="/logout" component={Logout}/>
                                            <Route path="/request-password-reset" component={PasswordResetRequest}/>
                                            <Route path="/register-confirmation" component={RegisterConfirmation}/>
                                            <Route path="/register-success" component={RegistrationSuccess}/>
                                            <Route path="/reset-password/:token" component={ResetPassword}/>
                                            <Route path="/verify" component={VerifyAccount}/>

                                            {/* EXTRAS */}
                                            <Route path="/extras/about" component={About} exact={true}/>
                                            <Route path="/extras/how-serveometer-works" component={HowTo} exact={true}/>
                                            <Route path="/extras/business" component={Business} exact={true}/>
                                            <Route path="/extras/contact" component={Contact} exact={true}/>
                                            <Route path="/extras/faq" component={FAQ} exact={true}/>
                                            <Route path="/extras/faq/:id" component={FaqItem} exact={true}/>
                                            <Route path="/extras/my-profile" component={MyProfile} exact={true}/>
                                            <Route path="/extras/privacy" component={Privacy} exact={true}/>
                                            <Route path="/extras/terms" component={Terms} exact={true}/>
                                            <Route path={'/extras/verify/:authToken'} component={RegistrationSuccess} excat={true}/>
                                            <Route path="/extras/disability-rights-uk" component={DisabilityRightsUK} exact={true}/>
                                            <Route path="/extras/newsletter" component={Newsletter} exact={true}/>

                                            {/* EXTRAS/PROFILE */}
                                            <Route path="/extras/my-profile/change-password" component={ChangePassword} exact={true}/>
                                            <Route path="/extras/my-profile/edit" component={Edit} exact={true}/>

                                            {/* LOCATION */}
                                            <Route path="/location/:id" component={LocationView} exact={true}/>
                                            <Route path="/location/:id/images" component={LocationDetailImages} exact={true}/>
                                            <Route path="/location/:id/claim" component={ClaimLocation} exact={true}/>
                                            <Route path="/location/:id/flag" component={FlagLocation} exact={true}/>
                                            <Route path="/location/:id/subscribe" component={LocationSubscribe} exact={true}/>
                                            <Route path="/location/propose" component={ProposeLocation} exact={true}/>
                                            <Route path="/location/propose" component={ProposeLocation} exact={true}/>

                                            {/* Score Submission */}
                                            <Route path="/location/:id/begin" component={Begin} exact={true}/>
                                            <Route path="/location/:id/score" component={Submit} exact={true}/>
                                            <Route path="/location/:id/scores" component={LocationScores} exact={true}/>
                                            <Route path="/register/score/:location" component={Register} exact={true}/>
                                            <Route path="/location/:id/scores/:scoreId" component={View} exact={true}/>
                                            <Route path="/score/:scoreId" component={View} exact={true}/>
                                            <Route path="/score/:scoreId/flag" component={FlagLocationScore} exact={true}/>
                                            <Route path="/score/:scoreId/success" component={ScoreSuccess} exact={true}/>

                                            {/* BLOG */}
                                            {/*<Route path="/blog" component={BlogPosts} exact={true} />*/}


                                            {/* OFFERS */}
                                            <Route path="/offers/:id" component={OfferDetails} exact={true}/>

                                            {/* USER */}
                                            <Route path="/user/:id" component={User} exact={true}/>

                                            {/* TABS */}
                                            <Route path="/search" component={Search}/>
                                            <Route path="/extras" component={Extras} exact={true}/>
                                            <Route path="/latest" component={Latest} exact={true}/>
                                            <Route path="/latest/blog/:id" component={BlogPost} exact={true}/>
                                            <Route path="/offers" component={Offers} exact={true}/>
                                            <Route path="/offers/blog/:id" component={BlogPost} exact={true}/>

                                            {/* MISC */}
                                            <Route path="/tab2" component={Tab2}/>
                                            <Route path="/tab3" component={Tab3}/>
                                            <Route path='/welcome-screen' component={WelcomeScreen}/>

                                            {/* NO MATCH - 404 */}
                                            <Route path='/sorry' component={FourOFour} exact={true}/>
                                            <Route component={FourOFour}/>

                                            {
                                                Authenticate.isAuthenticated() ?
                                                    (
                                                        <Route path="/" render={() => <Redirect to="/search"/>} exact={true}/>
                                                    ) : (
                                                        <Route path="/" render={() => <Redirect to="/search"/>} exact={true}/>
                                                    )
                                            }

                                        </IonRouterOutlet>
                                        <IonTabBar className={'tab-bar-height'} slot="bottom" id={'smr-tabs'}>
                                            <IonTabButton className={'tab-button'} tab="search" href={'/search'}>
                                                <IonIcon icon={businessSharp}/>
                                                <IonLabel>SEARCH</IonLabel>
                                            </IonTabButton>
                                            <IonTabButton tab="latest" href={'/latest'}>
                                                <IonIcon icon={newspaperSharp}/>
                                                <IonLabel>LATEST</IonLabel>
                                            </IonTabButton>
                                            <IonTabButton tab="offers" href={'/offers'}>
                                                <IonIcon icon={giftSharp}/>
                                                <IonLabel>OFFERS</IonLabel>
                                            </IonTabButton>
                                            <IonTabButton tab="extra" href={'/extras'}>
                                                <IonIcon icon={personCircle}/>
                                                <IonLabel>EXTRAS</IonLabel>
                                            </IonTabButton>
                                        </IonTabBar>
                                    </IonTabs>
                                ) : (
                                    <IonRouterOutlet>
                                        {/* AUTH */}
                                        <Route path="/login" component={Login}/>
                                        <Route path="/register" component={Register} exact={true}/>
                                        <Route path="/register/form" component={Register} exact={true}/>
                                        <Route path="/logout" component={Logout} exact={true}/>
                                        <Route path="/request-password-reset" component={PasswordResetRequest} exact={true}/>
                                        <Route path="/register-confirmation" component={RegisterConfirmation} exact={true}/>
                                        <Route path="/register-success" component={RegistrationSuccess} exact={true}/>
                                        <Route path="/reset-password/:token" component={ResetPassword} exact={true}/>
                                        <Route path="/verify" component={VerifyAccount} exact={true}/>

                                        {/* EXTRAS */}
                                        <Route path="/extras/about" component={About} exact={true}/>
                                        <Route path="/extras/how-serveometer-works" component={HowTo} exact={true}/>
                                        <Route path="/extras/business" component={Business} exact={true}/>
                                        <Route path="/extras/contact" component={Contact} exact={true}/>
                                        <Route path="/extras/faq" component={FAQ} exact={true}/>
                                        <Route path="/extras/faq/:id" component={FaqItem} exact={true}/>
                                        <Route path="/extras/my-profile" component={MyProfile} exact={true}/>
                                        <Route path="/extras/privacy" component={Privacy} exact={true}/>
                                        <Route path="/extras/terms" component={Terms} exact={true}/>
                                        <Route path={'/extras/verify/:authToken'} component={RegistrationSuccess} excat={true}/>
                                        <Route path="/extras/disability-rights-uk" component={DisabilityRightsUK} exact={true}/>
                                        <Route path="/extras/newsletter" component={Newsletter} exact={true}/>

                                        {/* EXTRAS/PROFILE */}
                                        <Route path="/extras/my-profile/change-password" component={ChangePassword} exact={true}/>
                                        <Route path="/extras/my-profile/edit" component={Edit} exact={true}/>

                                        {/* LOCATION */}
                                        <Route path="/location/:id" component={LocationView} exact={true}/>
                                        <Route path="/location/:id/images" component={LocationDetailImages} exact={true}/>
                                        <Route path="/location/:id/claim" component={ClaimLocation} exact={true}/>
                                        <Route path="/location/:id/flag" component={FlagLocation} exact={true}/>
                                        <Route path="/location/:id/subscribe" component={LocationSubscribe} exact={true}/>
                                        <Route path="/location/propose" component={ProposeLocation} exact={true}/>

                                        {/* Score Submission */}
                                        <Route path="/location/:id/begin" component={Begin} exact={true}/>
                                        <Route path="/location/:id/score" component={Submit} exact={true}/>
                                        <Route path="/location/:id/scores" component={LocationScores} exact={true}/>
                                        <Route path="/register/score/:location" component={Register} exact={true}/>
                                        <Route path="/location/:id/scores/:scoreId" component={View} exact={true}/>
                                        <Route path="/score/:scoreId" component={View} exact={true}/>
                                        <Route path="/score/:scoreId/flag" component={FlagLocationScore} exact={true}/>
                                        <Route path="/score/:scoreId/success" component={ScoreSuccess} exact={true}/>
                                        <Route path="/score/:scoreId/success-pending" component={ScoreSuccessPending} exact={true}/>

                                        {/* BLOG */}
                                        {/*<Route path="/blog" component={BlogPosts} exact={true} />*/}

                                        {/* OFFERS */}
                                        <Route path="/offers/:id" component={OfferDetails} exact={true}/>

                                        {/* USER */}
                                        <Route path="/user/:id" component={User} exact={true}/>

                                        {/* TABS */}
                                        <Route path="/search" component={Search}/>
                                        <Route path="/extras" component={Extras} exact={true}/>
                                        <Route path="/latest" component={Latest} exact={true}/>
                                        <Route path="/latest/blog/:id" component={BlogPost} exact={true}/>
                                        <Route path="/offers" component={Offers} exact={true}/>
                                        <Route path="/offers/blog/:id" component={BlogPost} exact={true}/>

                                        {/* MISC */}
                                        <Route path="/tab2" component={Tab2} exact={true}/>
                                        <Route path="/tab3" component={Tab3} exact={true}/>
                                        <Route path='/' component={Home} exact={true}/>

                                        {/* NO MATCH - 404 */}
                                        <Route path='/sorry' component={FourOFour} exact={true}/>
                                        <Route component={FourOFour}/>

                                        {/*{
                                            Authenticate.isAuthenticated() ?
                                                <Route path="/" render={() => <Redirect to="/"/>} exact={true}/> :
                                                <Route path="/" render={() => <Redirect to="/"/>} exact={true}/>
                                        }*/}

                                    </IonRouterOutlet>
                                )
                            }
                        </Media>
                    </IonReactRouter>
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                    />
                    {
                        isPlatform('desktop') || isPlatform('mobileweb') ?
                            <CookieConsent
                                location="bottom"
                                buttonText="Ok"
                                cookieName="SMR_COOKIECONSENT"
                                style={{background: "#2B373B"}}
                                buttonStyle={{background: "#ffa900", color: "#000", fontSize: "18px"}}
                                expires={150}
                            >
                                This website uses cookies to enhance the user experience.
                                <br/><br/>
                                <span style={{fontSize: "10px"}}>By clicking 'OK' or continuing to use this site you adhere to our <a href={'/extras/terms'}>terms and conditions.</a></span>
                            </CookieConsent>
                            :
                            ''
                    }
                </ContentContextProvider>
            </AppContextProvider>
        </IonApp>

    )
};

export default App;