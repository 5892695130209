import React, {useContext, useEffect, useState} from 'react';
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonProgressBar,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSlide,
    IonSlides,
    IonTextarea,
} from "@ionic/react";
import SubmitScore from "../../assets/js/SubmitScore";
import * as moment from 'moment-timezone';
import {calendar, closeCircle, eye, helpCircle, readerOutline, shieldCheckmark, warning} from "ionicons/icons";
import Locations from "../../assets/js/Locations";
import {ContentContext} from "../../Content";
import {AppContext} from "../../State";
import Media from "react-media";
import Blocks from "../../assets/js/Blocks";
import { Keyboard } from "@ionic-native/keyboard";
import { Analytics } from 'aws-amplify';

const Questions = (props: any) => {

    const {content, dispatch} = useContext(ContentContext);
    const {state, dispatchState} = useContext(AppContext);

    let momentjs: any;
    momentjs = moment;

    //Set the time of day to be as we are now
    function getCurrentDayTime(m) {
        var g = "10:00:00"; //return g

        if (!m || !m.isValid()) {
            return;
        } //if we can't find a valid or filled moment, we return.

        var split_afternoon = 12 //24hr time to split the afternoon
        var split_evening = 17 //24hr time to split the evening
        var currentHour = parseFloat(m.format("HH"));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            g = "15:00:00";
        } else if (currentHour >= split_evening) {
            g = "19:00:00";
        }
        return g;
    }

    const [globalAnswers, setGlobalAnswers] = useState([]);
    const [slideCount, setSlideCount] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [topSlide, setTopSlide] = useState(1);
    const [topQuestion, setTopQuestion] = useState(2);
    const [canProgress, setCanProgress] = useState(true);
    const [showCommenter, setShowCommenter] = useState(['']);
    const [scoreDate, setScoreDate] = useState(momentjs().format('YYYY-MM-DD'));
    const [timeOfDay, setTimeOfDay] = useState(getCurrentDayTime(momentjs()));
    const [activePacks, setActivePacks] = useState([props.questionPacks[0].id]);

    const [showDisabilityModal, setShowDisabilityModal] = useState(false);

    const [showConfirmCancel, setShowConfirmCancel] = useState(false);

    const questionColours: any = {
        0: 'terrible',
        1: 'bad',
        2: 'medium',
        3: 'good',
        4: 'great'
    };

    const yesNoIcons = (score) => {
        switch (score) {
            case -1:
                return warning;
            case 1:
                return shieldCheckmark;
            default:
                return helpCircle;
        }
    };

    const yesNoColours = (score) => {
        switch (score) {
            case 2:
                return 'danger';
            case 3:
                return 'success';
            case 1:
                return 'success';
            case -1:
                return 'danger';
            default:
                return 'medium';
        }
    };

    let questionSlider: any;

    let sliderOpts = {
        initialSlide: 0,
        speed: 400,
        allowTouchMove: false
    };

    if (questionSlider === null || questionSlider === undefined || 'undefined') {
        questionSlider = document.getElementById('question-slider');
    }

    useEffect(() => {
        // let newdate = momentjs().format('YYYY-MM-DD');
        //setScoreDate(newdate);
        setSlideCount(props.questionPacks[0].count + props.packCount);
        setActivePacks([props.questionPacks[0].id]);
        return () => {
        }
    }, [props.packCount, props.questionPacks]);

    const setQuestionAnswer = (answer: any, comment: any, question_id: any) => {
        setTopQuestion(topQuestion + 1);
        let answers = [];
        if (window.localStorage.getItem('SMR_QUESTION')) {
            answers = JSON.parse(window.localStorage.getItem('SMR_QUESTION'));
            if (answers[question_id]) {
                if (answer) {
                    answers[question_id]['question_option_id'] = answer;
                }
                if (comment) {
                    answers[question_id]['response_text'] = comment;
                }
            } else {
                answers[question_id] = {
                    question_id: question_id,
                    question_option_id: answer,
                    response_text: comment
                };
            }
            window.localStorage.setItem('SMR_QUESTION', JSON.stringify(answers));
        } else {
            answers[question_id] = {
                question_id: question_id,
                question_option_id: answer,
                response_text: comment
            };
            window.localStorage.setItem('SMR_QUESTION', JSON.stringify(answers));
        }
        setGlobalAnswers(answers);
    };

    const next = () => {
        let t = topSlide;
        questionSlider.slideNext(400, false).then(() => {
            if(currentSlide < t-1){
                setCurrentSlide(currentSlide + 1);
            } else if(currentSlide === t-1) {
                setCurrentSlide(currentSlide + 1);
                if(topQuestion > topSlide){
                    setCanProgress(true);
                } else {
                    setCanProgress(false);
                }
            } else {
                setCurrentSlide(currentSlide + 1);
                setTopSlide(topSlide + 1);
                setCanProgress(false);
            }
        });
    };

    const prev = () => {
        questionSlider.slidePrev(400, false).then(() => {
            setCurrentSlide(currentSlide - 1);
            setCanProgress(true);
        });
    };

    const submit = () => {
        let happened_at = momentjs(scoreDate + " " + timeOfDay).toISOString();

        if (window.localStorage.getItem('SMR_ACCESS_TOKEN')) {
            //We are logged in, submit the score
            // @ts-ignore
            SubmitScore.submit(props.locationId, happened_at, JSON.parse(window.localStorage.getItem('SMR_QUESTION'))).then(data => {
                if (data) {
                    //Refresh the local location stats, now so when returning to the place it will have correct data
                    Analytics.record({name:'place.score',attributes:{user_id:data.submission.user_id,location:props.locationId}});
                    Locations.loadLocation(props.locationId)
                        .then(res => {
                            dispatch({type: 'updateLocations', locations: res.attributes});
                        });
                    if(localStorage.getItem('SMR_SCORE_PENDING')){
                        window.location.href = '/score/' + data.submission.id + '/success-pending';
                    } else {
                        window.location.href = '/score/' + data.submission.id + '/success';
                    }
                } else {

                }
            });
        } else {
            window.localStorage.setItem('SMR_ACTIVE_SUBMISSION_LOCATION', props.locationId);
            dispatchState('setSubmission', {happened_at: happened_at});
            window.location.href = '/register/score/' + props.locationId;
        }
    };

    return (
        <div>
            <div className={'progress-text'} >
                Progress {/*C: {currentSlide} / T: {topSlide} / Q: {topQuestion}*/}
            </div>
            <IonIcon className={'close-icon'} icon={closeCircle} style={{
                color: '#999'
            }} onClick={() => {
                setShowConfirmCancel(true);
            }}/>
            <IonProgressBar value={currentSlide / slideCount}/><br/>
            <IonSlides className={'question-slider'} pager={false} id="question-slider" options={sliderOpts}>
                <IonSlide className={'block-slide'}>
                    <div className={'sm-title'} >When was this?</div>
                    <hr/>
                    <IonItem lines={'full'} className={'ion-padding-left'}>
                        <IonLabel position={'floating'}>
                            <b>Date</b>
                        </IonLabel>
                        <IonDatetime displayFormat="D MMMM YYYY" pickerFormat="D MMM YYYY" min={momentjs().subtract(1, 'months').format('YYYY-MM-DD')} max={momentjs().format('YYYY-MM-DD')}
                                     value={scoreDate} onIonChange={e => {
                            // @ts-ignore
                            setScoreDate(e.detail.value);
                        }}/>
                        <IonIcon size={'small'} icon={calendar} className={'ion-float-right'} style={{
                            position: 'absolute',
                            right: '20px',
                            bottom: '12px'
                        }} />
                    </IonItem>
                    <IonItem lines={'full'} className={'ion-padding-left ion-margin-top'}>
                        <IonLabel position={'floating'}>
                            <b>Time of day</b>
                        </IonLabel>
                        <IonSelect value={timeOfDay} placeholder="Time of day" onIonChange={
                            (e) => {
                                setTimeOfDay(e.detail.value)
                            }
                        }>
                            <IonSelectOption value="10:00:00">Morning</IonSelectOption>
                            <IonSelectOption value="15:00:00">Afternoon</IonSelectOption>
                            <IonSelectOption value="19:00:00">Evening</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <br/>
                    <IonButton data-cy={'whenWasThisNextButton'} fill={'clear'} className={'ion-float-right action primary ion-margin-bottom'} onClick={() => {
                        next();
                    }}>Next</IonButton>
                </IonSlide>
                {
                    props.questionPacks.map((pack: any, packIndex: any) => {
                        return [
                            pack.attributes.intro_text ?
                                <IonSlide className={'block-slide ion-no-padding'} key={'pack-' + packIndex}>
                                    <IonCard className={'m-0'}>
                                        <IonCardContent>
                                            <div className={'sm-title'} onClick={() => {
                                                if (pack.attributes.intro_text.includes('Disability Rights')) {
                                                    setShowDisabilityModal(true);
                                                }
                                            }} dangerouslySetInnerHTML={{__html: pack.attributes.intro_text}}/>
                                            <hr/>
                                            <IonButton data-cy={'pack_'+packIndex+'_continueButton'}
                                                expand="full" onClick={() => {
                                                if (slideCount < slideCount + pack.questions.length) {
                                                    setSlideCount(slideCount + pack.questions.length);
                                                    activePacks.push(pack.id);
                                                }
                                                setTopQuestion(topQuestion+1);
                                                next();
                                            }} className={'action mb-10 primary'}>
                                                Yes, I'll help
                                            </IonButton>
                                            <IonButton expand="full" className={'action secondary'} onClick={() => {
                                                if (props.questionPacks.length > (packIndex + 1)) {
                                                    questionSlider.getActiveIndex().then((index: any) => {
                                                        questionSlider.slideTo((index + props.questionPacks[packIndex].questions.length + 1), -1);
                                                        setCurrentSlide(currentSlide + 1);
                                                    });
                                                } else {
                                                    submit();
                                                }
                                            }}>
                                                {props.questionPacks.length > (packIndex + 1) ? 'Skip' : 'Finish'}
                                            </IonButton>
                                            {/*<Media query="(max-width: 768px)">
                                                {matches =>
                                                    matches ? (
                                                        <>
                                                            <hr/>
                                                            <IonButton className={'action danger small'}
                                                                       onClick={() => {
                                                                           setShowConfirmCancel(true);
                                                                       }}>Cancel</IonButton>
                                                        </>
                                                    ) : ''
                                                }
                                            </Media>*/}
                                        </IonCardContent>
                                    </IonCard>
                                </IonSlide>
                                : '',
                            pack.questions.map((q: any, questionIndex: any) => {
                                return [
                                    <IonSlide key={q.id} className={'block-slide question-slide'}>
                                        <IonGrid className={'ion-no-padding'}>
                                            <IonRow>
                                                <IonCol>
                                                    <div className={'sm-title'}>{q.attributes.text}</div>
                                                </IonCol>
                                            </IonRow>
                                            <hr/>
                                            {
                                                q.attributes.questionType === 'Radio' || q.attributes.questionType === 'Yes/No' ?
                                                    <IonRadioGroup onIonChange={e => {
                                                        setCanProgress(true);
                                                        setQuestionAnswer(e.detail.value, '', q.id);
                                                    }} className={'mb-10 answers'}>
                                                        {
                                                            q.attributes.options.map((a: any, index: any) => {
                                                                return (
                                                                    <IonRow key={a.id}>
                                                                        <IonCol>
                                                                            <IonItem
                                                                                className={questionColours[a.value] + ' question-answer' + (index + 1 === q.attributes.options.length ? ' last' : '')}>
                                                                                {
                                                                                    q.attributes.questionType === 'Radio' ?
                                                                                        <IonIcon slot="start"
                                                                                                 src={'/assets/imgs/smiley' + a.value + '.svg'}
                                                                                                 className={'questionIcon'}/>
                                                                                        : <IonIcon slot="start" icon={yesNoIcons(a.value)} color={yesNoColours(a.value)}/>
                                                                                }
                                                                                <IonLabel data-cy={'p_'+packIndex+'_q_'+questionIndex+'_o_'+index} className={'questionLabel'}>{a.text}</IonLabel>
                                                                                <IonRadio slot="end" value={a.id} color="success"/>
                                                                            </IonItem>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                )
                                                            })
                                                        }
                                                    </IonRadioGroup>
                                                    :
                                                    <>
                                                        <IonTextarea data-cy={'p_'+packIndex+'_q_'+questionIndex+'_text'}
                                                            className={'question-comment-input mb-20'}
                                                                     onIonChange={e => {
                                                                         setQuestionAnswer(q.attributes.options[0].id, e.detail.value, q.id);
                                                                         setCanProgress(true);
                                                                     }} placeholder={'Begin typing...'}/>
                                                    </>
                                            }
                                            {
                                                q.attributes.questionType !== 'Text' ?
                                                    <IonRow className={'mb-20'}>
                                                        <IonCol>
                                                            {
                                                                showCommenter.includes(q.id) ? (
                                                                        <>
                                                                            <IonTextarea className={'question-comment-input'}
                                                                                         onIonChange={e => setQuestionAnswer('', e.detail.value, q.id)}
                                                                                         placeholder={'Begin typing...'}
                                                                                         autocapitalize={'on'} />

                                                                        </>
                                                                    ) :
                                                                    <IonButton
                                                                        size={'small'}
                                                                        className={'action secondary small'}
                                                                        onClick={() => {
                                                                            setShowCommenter(showCommenter => showCommenter.concat(q.id))
                                                                        }}>
                                                                        <IonIcon slot={'start'} icon={readerOutline}/>
                                                                        Add a comment?</IonButton>
                                                            }
                                                        </IonCol>
                                                    </IonRow>
                                                    : ''
                                            }
                                        </IonGrid>
                                        <IonGrid className={'ion-no-padding nav-btns'}>
                                            <IonRow>
                                                <IonCol size={'6'} className={'pr-5'}>
                                                    <IonButton fill={'clear'}
                                                               className={'ion-float-left action secondary'}
                                                               onClick={() => {
                                                                   if (packIndex > 0 && questionIndex === 0) {
                                                                       if (slideCount > slideCount - pack.questions.length) {
                                                                           setSlideCount(slideCount - pack.questions.length);
                                                                           activePacks.splice(activePacks.indexOf(pack.id));
                                                                       }
                                                                   }
                                                                   prev()
                                                               }}>Previous</IonButton>
                                                </IonCol>
                                                <IonCol size={'6'} className={'pl-5'}>
                                                    <IonButton data-cy={'p_'+packIndex+'_q_'+(questionIndex)+'_next'} fill={'clear'}
                                                               className={'ion-float-right action primary'}
                                                               disabled={(globalAnswers[q.id] === undefined || globalAnswers[q.id] === 'undefined') || !canProgress}
                                                               onClick={() => {
                                                                   if (currentSlide === slideCount) {
                                                                       submit();
                                                                   } else {
                                                                       next();
                                                                   }
                                                               }}>
                                                        {currentSlide === slideCount ? 'Finish' : 'Next'}
                                                    </IonButton>
                                                </IonCol>
                                                {/*<Media query="(max-width: 768px)">
                                                    {matches =>
                                                        matches ? (
                                                            <>
                                                                <hr/>
                                                                <IonButton className={'action danger small'}
                                                                           onClick={() => {
                                                                               setShowConfirmCancel(true);
                                                                           }}>Cancel</IonButton>
                                                            </>
                                                        ) : ''
                                                    }
                                                </Media>*/}
                                            </IonRow>
                                        </IonGrid>
                                    </IonSlide>
                                ]
                            })
                        ];
                    })
                }
            </IonSlides>
            <IonAlert
                isOpen={showConfirmCancel}
                onDidDismiss={() => setShowConfirmCancel(false)}
                cssClass='my-custom-class'
                header={'Confirm'}
                message={'Are you sure you wish to cancel this score?'}
                buttons={[
                    {
                        text: 'No, continue',
                        handler: () => {
                            //Continue
                        }
                    },
                    {
                        text: 'Yes, cancel',
                        role: 'cancel',
                        handler: () => {
                            window.location.href = '/location/' + props.locationId;
                        }
                    }
                ]}
            />
            <IonModal isOpen={showDisabilityModal} cssClass='my-custom-class'>
                <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'disability-information')}} style={{
                    padding: '10px',
                    fontSize: '14px'
                }}/>
                <div style={{
                    padding: '10px',
                    fontSize: '14px'
                }}>
                    <IonButton expand={'full'} onClick={() => setShowDisabilityModal(false)}>Close</IonButton>
                </div>
            </IonModal>
        </div>
    )
};

export default Questions;
