import React from 'react';
import Meta from 'react-meta-tags';

const MetaTags = (props: any) => {

    return (
        <Meta>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            <meta property="og:title" content={props.title} />
            {
                props.image ?
                    <meta property="og:image" content={props.image} />
                    :
                    ''
            }

        </Meta>
    )

}

export default MetaTags;