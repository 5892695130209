import axios from 'axios';
import {SMR_ENDPOINT} from '../../constants';
import _ from "underscore";
import Helper from "./Helper";

export default {
    url: SMR_ENDPOINT + "/places/search?",
    urlNearMe: SMR_ENDPOINT + "/places/nearme?",

    places: async function (query, options) {
        let url = '';
        if (query === "" || query === false) {
            url = this.urlNearMe + "q=ignored";
        } else {
            url = this.url + "q=" + encodeURIComponent(query);
        }
        if (options.orderBy) {
            url += "&orderby=" + options.orderBy;
        }

        if (options.range) {
            url += "&distance=" + options.range;
        } else {
            url += "&distance=20";
        }

        if (options.page !== undefined && 'undefined') {
            url += "&page=" + options.page;
        }
        if (options.limit !== undefined && 'undefined') {
            url += "&limit=" + options.limit;
        }
        if (options.lat !== undefined && options.lat !== 'undefined' && options.lat !== 0) {
            url += "&lat=" + options.lat;
        }
        if (options.lng !== undefined && options.lng !== 'undefined' && options.lng !== 0) {
            url += "&lng=" + options.lng;
        }
        // fetch data from a url endpoint
        const response = await axios.get(url);

        //calculate distance from me
        if (response.hasOwnProperty('data')
            && response.data.hasOwnProperty('hits')
            && response.data.hits.hasOwnProperty('hits')) {
            let locations = response.data.hits.hits;
            let myLatLong = {latitude: 0, longitude: 0}

            if (options.lat !== undefined && options.lat !== 'undefined' && options.lat !== 0) {
                myLatLong.latitude = options.lat;
            } else {
                if (response.data.hasOwnProperty('latitude')) {
                    myLatLong.latitude = parseFloat(response.data.latitude);
                } else {
                    return response;
                }
            }
            if (options.lng !== undefined && options.lng !== 'undefined' && options.lng !== 0) {
                myLatLong.longitude = options.lng;
            } else {
                if (response.data.hasOwnProperty('longitude')) {
                    myLatLong.longitude = parseFloat(response.data.longitude);
                } else {
                    return response;
                }
            }
            if (Array.isArray(locations)) {
                locations = Helper.setDistanceFromMe(locations, myLatLong, 'M')
                response.data.hits.hits = locations;
            }
        }
        return response;
    },

    searchById: function (id) {
        return axios.get(SMR_ENDPOINT + '/places/' + id).then(response => {
            return response.data.data
        })
    },
    getQuestionPackById: function (id) {
        return axios.get(SMR_ENDPOINT + '/packs/' + id + '?include=questions').then(response => {
            return response.data;
        })
    },
    getSubmissionById: function (id) {
        return axios.get(SMR_ENDPOINT + '/submissions/' + id + '?include=answers').then(response => {
            return response.data.data.attributes;
        })
    },
    getScorableSubmissionsByLocationId: function (id, pageSize = null, pageNum = null) {
        return axios.get(
            SMR_ENDPOINT + '/submissions?filter[place_id]=' + id + (pageNum ? '&page[number]=' + pageNum : '') + (pageSize ? '&page[size]=' + pageSize : '')
        ).then(response => {
            //TODO Save this to state / localstorage, so we don't have to reload it each time
            //Note should use response.data because response.data.meta contains current page number, needed to allow scrolling for more results
            //@sam we can't save to state in this file.  Can the functions be moved to a component so we can just update the state object in there and not need to include
            //saving functionality in the view?
            let data = response.data.data;
            let scoreablePacks = [];
            _.each(data, function (sub) {
                _.each(sub.attributes.packs, function (pack) {
                    if (pack.is_scoreable === 1 && !scoreablePacks.includes(pack.id)) {
                        scoreablePacks.push(pack.id);
                    }
                });
            });

            _.each(data, function (sub) {
                sub.attributes.scores = _.filter(sub.attributes.scores, function (score) {
                    return scoreablePacks.includes(score.pack_id);
                });
                _.each(sub.attributes.packs, function (pack) {
                    if (pack.is_comments) {
                        _.each(sub.attributes.answers, function (answer) {
                            if (answer.question_option.question.pack_id === pack.id && answer.response_text && !sub.attributes.comment) {
                                sub.attributes.comment = answer.response_text;
                            }
                        });
                    }
                });
            });
            data.scoreablePacks = scoreablePacks;
            return data;
        })
    }
}
