import React, {useContext, useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";

import Blog from '../../assets/js/Blogs';
import {IonCard, IonCol, IonGrid, IonIcon, IonImg, IonRow} from "@ionic/react";
import _ from "underscore";
import {ContentContext} from "../../Content";
import * as moment from "moment-timezone";
import {calendar} from "ionicons/icons";
import Sidebar from "../../components/Sidebar";

const BlogPost = (props: any) => {

    const {content, dispatch} = useContext(ContentContext);

    let WebNav: any = navigator;

    let momentjs: any;
    momentjs = moment;

    // Declare empty object
    let init: any = {};

    const [blogId] = useState(props.match.params.id);
    const [blogDetails, setBlogDetails] = useState(init);

    useEffect(() => {
        let item = _.find(content.blogs.data, function (blog) {
            return blog.id == blogId;
        });
        setBlogDetails(item.attributes);

    }, [blogId]);

    return (
        <Serveometer page={'Blog'} showHeader={true} showBackButton={true} render={
            <div className={'single-blog container'}>
                <IonGrid>
                    <IonRow>
                        <IonCol size={'12'} size-md={'9'}>
                            <IonCard style={{
                                marginLeft: '0',
                                marginRight: '0'
                            }}>
                                <IonImg src={blogDetails.featured_image}/>
                                <div className={'m-10'}>
                                    <h1 className={'mb-0'}>{blogDetails.title}</h1>
                                    <hr/>
                                    <strong className={'vertical-align'}>
                                        <IonIcon slot="start" icon={calendar}/>&nbsp;
                                        {momentjs(blogDetails.created_at).tz('Europe/London').format('dddd, MMMM Do YYYY')}
                                    </strong>
                                    <hr/>
                                    <div dangerouslySetInnerHTML={{
                                        __html: blogDetails.content
                                    }}/>
                                </div>
                            </IonCard>
                        </IonCol>
                        <IonCol size={'12'} size-md={'3'}>
                            <Sidebar showShareIcons={'1'}/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        }/>
    )
}

export default BlogPost;