import React, {useContext, useState} from 'react'
import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonRow, IonSelect, IonSelectOption
} from "@ionic/react";
import Blocks from "../assets/js/Blocks";
import axios from "axios";
import {SMR_ENDPOINT} from "../constants";
import _ from "underscore";
import {ContentContext} from "../Content";

const checkboxList = [
    {val: 'Yes', isChecked: false},
    {val: 'No', isChecked: false},
    {val: 'Dont know', isChecked: false},
    {val: 'Rather not say', isChecked: false}
]


const DisabilityQuestion = (props: any) => {

    const [disabilityOption, setDisabilityOption] = useState('');
    const [disability, setDisability] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const {content, dispatch} = useContext(ContentContext);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setShowAlertText] = useState('');

    const addDisabilityBox = () => {
        let d = (document.getElementById('currentDisability') as HTMLInputElement).value;
        let boxes = disabilityBoxes.boxes;
        let thisBox = disabilityBoxes.boxes.length + 1;
        boxes.push(
            <IonRow key={thisBox} style={{margin: '20px 0px 10px 0px'}} >
                <IonCol>
                    <IonItem lines={'full'}>
                        <IonSelect placeholder="Please select one..." onIonChange={(e) => {
                            console.log("Old: ", d);
                            let newDisability = d + ", " + e.detail.value;
                            console.log("New: ", newDisability);
                            setDisability(newDisability);
                        }}>
                            <IonSelectOption value="Anxiety or mental health condition">Anxiety or mental health condition</IonSelectOption>
                            <IonSelectOption value="Dementia">Dementia</IonSelectOption>
                            <IonSelectOption value="Hearing impairment">Hearing impairment</IonSelectOption>
                            <IonSelectOption value="Learning difficulty or neurodivergence, such as dyslexia">Learning difficulty or neurodivergence, such as dyslexia</IonSelectOption>
                            <IonSelectOption value="Learning disability or other neurodivergence, such as autism">Learning disability or other neurodivergence, such as autism</IonSelectOption>
                            <IonSelectOption value="Medical condition">Medical condition</IonSelectOption>
                            <IonSelectOption value="Mobility scooter user">Mobility scooter user</IonSelectOption>
                            <IonSelectOption value="Parent with a pram">Parent with a pram</IonSelectOption>
                            <IonSelectOption value="Sign language user">Sign language user</IonSelectOption>
                            <IonSelectOption value="Visual impairment">Visual impairment</IonSelectOption>
                            <IonSelectOption value="Walking aid user">Walking aid user</IonSelectOption>
                            <IonSelectOption value="Wheelchair user">Wheelchair user</IonSelectOption>
                            <IonSelectOption value="Other/prefer not to say">Other/prefer not to say</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {
                        disabilityBoxes.boxes.length < 10 ?
                            <>
                                <div style={{textAlign: 'center'}} id={'box-' + (thisBox)} >
                                    <br />
                                    <a href={"#"} onClick={(e) => {
                                         e.preventDefault();
                                         addDisabilityBox();
                                         document.getElementById('box-' + (thisBox)).style.display = 'none';
                                    }} >Add another</a>
                                </div>
                            </>
                        :
                            ''
                    }

                </IonCol>
            </IonRow>
        )

        setDisabilityBoxes({"boxes": boxes});
    }

    const [disabilityBoxes, setDisabilityBoxes] = useState({"boxes": [
        <IonRow key={'1'} style={{margin: '20px 0px 10px 0px'}} >
            <IonCol>
                {/*<p>Tell us your disability so that we may be able to provide more relevant results and assist if we can.</p>
                            <IonItem lines={'full'}>
                                <IonInput id={'disability'} value={disability} onIonChange={e => setDisability(e.detail.value!)} placeholder="Your Disability" type={"text"}/>
                            </IonItem>*/}
                <IonItem lines={'full'}>
                    <IonSelect placeholder="Please select one..." onIonChange={(e) => {
                        setDisability(e.detail.value)
                    }} style={{marginTop: '20px'}}>
                        <IonSelectOption value="Anxiety or mental health condition">Anxiety or mental health condition</IonSelectOption>
                        <IonSelectOption value="Dementia">Dementia</IonSelectOption>
                        <IonSelectOption value="Hearing impairment">Hearing impairment</IonSelectOption>
                        <IonSelectOption value="Learning difficulty or neurodivergence, such as dyslexia">Learning difficulty or neurodivergence, such as dyslexia</IonSelectOption>
                        <IonSelectOption value="Learning disability or other neurodivergence, such as autism">Learning disability or other neurodivergence, such as autism</IonSelectOption>
                        <IonSelectOption value="Medical condition">Medical condition</IonSelectOption>
                        <IonSelectOption value="Mobility scooter user">Mobility scooter user</IonSelectOption>
                        <IonSelectOption value="Parent with a pram">Parent with a pram</IonSelectOption>
                        <IonSelectOption value="Sign language user">Sign language user</IonSelectOption>
                        <IonSelectOption value="Visual impairment">Visual impairment</IonSelectOption>
                        <IonSelectOption value="Walking aid user">Walking aid user</IonSelectOption>
                        <IonSelectOption value="Wheelchair user">Wheelchair user</IonSelectOption>
                        <IonSelectOption value="Other/prefer not to say">Other/prefer not to say</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <div style={{textAlign: 'center'}} id={'box-1'} >
                    <br />
                    <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        addDisabilityBox();
                        document.getElementById('box-1').style.display = 'none';
                    }} >Add another</a>
                </div>
            </IonCol>
        </IonRow>
    ]});

    /*const renderInputField = () => {
        if (disabilityOption !== 'Yes') {
            return null;
        } else {

        }
    }*/

    //This is a pain in the arse
    //updating the checkbox list doesn't updated the checkbox.
    //When calling setDisabilityOption all checkboxes trigger on change
    //So this function gets called once on click, then 5 times after change.
    const updateDisability = (e, i) => {
        //clear all checkboxes
        _.each(checkboxList, function (c) {
            c.isChecked = false;
        });

        checkboxList[i].isChecked = e.detail.checked;
        let selected = _.find(checkboxList, function (checkbox) {
            return checkbox.isChecked;
        });
        if (typeof selected !== "undefined" && 'val' in selected) {
            setDisabilityOption(selected.val);
            if (selected.val !== 'Yes') {
                setDisability('');
            } else {
                //document.getElementById("disability").focus();
            }
        }
    };

    const saveDisability = () => {
        setShowLoading(true);
        axios.patch(
            SMR_ENDPOINT + '/auth/update',
            {
                disability: (disabilityOption !== 'Yes' ? disabilityOption : disability)
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('SMR_ACCESS_TOKEN')
                }
            }).then((response) => {
            if (response.status === 202) {
                //Update success
                //window.location.replace('/search')
                setShowLoading(false);
                setShowAlertText("Your disability information has been saved successfully.")
                setShowAlert(true);
            } else {
                //Something went wrong
                setShowLoading(false);
            }
        }).catch((e) => {
            setShowLoading(false);
            if(e.response.status === 422){
                setShowAlertText("Please complete the disability form.")
                setShowAlert(true);
            }
        })
    };

    return (
        <div>
            <IonCard>

                <IonCardContent>
                    <IonList className={'mt-10 mb-10'}>
                        <h1>
                            Do you have a disability or accessibility needs when shopping?
                        </h1>
                        <div dangerouslySetInnerHTML={{__html: Blocks.getBlock(content, 'why-accessibility-matters')}}/>
                        {checkboxList.map(({val, isChecked}, i) => (
                            <IonItem key={i} lines={'full'} className={'ion-no-padding'}>
                                <IonLabel>{val}</IonLabel>
                                <IonCheckbox class="check-radio" slot="start" value={val} checked={props.disability && props.disability === val ? true : checkboxList[i].isChecked} onIonChange={(e) => {
                                    updateDisability(e, i)
                                }}/>
                            </IonItem>
                        ))}
                    </IonList>
                    {
                        disabilityOption !== 'Yes' ?
                                ''
                            :
                                disabilityBoxes.boxes.map(box => {
                                    return box
                                })
                    }
                    <IonButton expand={'full'}
                               className={'action primary'}
                               onClick={saveDisability}>Save Disability</IonButton>
                </IonCardContent>
                <input type={'hidden'} id={'currentDisability'} value={disability} />
            </IonCard>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Updating preferences...'}
                duration={20000}
            />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={'Disability Questions'}
                subHeader={''}
                message={alertText}
                buttons={[{
                    text: 'OK',
                    handler: () => {

                    }
                }]}
            />
        </div>
    )
};

export default DisabilityQuestion;