import React, {useContext, useState} from 'react';

import {IonCard, IonCardSubtitle, IonIcon} from "@ionic/react";
import {AppContext} from "../State";
import {arrowForwardSharp} from "ionicons/icons";

const UnVerified = (props: any) => {

    const {state} = useContext(AppContext);
    const [verifyText] = useState("Click here to enter your verification pin");
    let url = window.location.pathname;

    return (
        !state.userVerified && state.user.hasOwnProperty('user_id') && url !== '/verify' && url !== '/login' && url !== 'register' ?
            <IonCard onClick={() => {
                window.location.href = "/verify";
            }} className={'no-connection'}>
                <IonCardSubtitle style={{
                    color: '#fff'
                }}>
                    {verifyText} <IonIcon icon={arrowForwardSharp}/>
                </IonCardSubtitle>
            </IonCard>
            :
            <></>
    )

}

export default UnVerified;