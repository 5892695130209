import React, {useEffect, useState} from 'react';
import classNames from "classnames"
import Serveometer from "../../components/Serveometer";
import {IonButton, IonCol, IonGrid, IonLoading, IonRow} from "@ionic/react";
import PinField from "react-pin-field"
import Auth from '../../assets/js/Auth';

const VerifyAccount: React.FC = () => {

    const [pinVerified, setPinVerified] = useState(false)
    const [sendPinDisabled, setSendPinDisabled] = useState(false)

    const [showLoading, setShowLoading] = useState(false);

    const verifyPin = (pin) => {
        setSendPinDisabled(true);
        setShowLoading(true);
        Auth.verify(pin)
            .then(res => {
                if (res.status === 202) {
                    //YAY We validated
                    setPinVerified(true);
                    if (window.localStorage.getItem('SMR_ACTIVE_SUBMISSION_ID')) {
                        window.location.href = '/score/' + window.localStorage.getItem('SMR_ACTIVE_SUBMISSION_ID') + '/success';
                    } else if(window.localStorage.getItem('SMR_PROPOSING')) {
                        window.location.href = '/location/propose';
                    } else {
                        window.location.replace('/register-success');
                    }

                } else {
                    setSendPinDisabled(false);
                    return false;
                }
                setShowLoading(false);
            }).catch(e => {
            setShowLoading(false);
            if (e.response.status === 422) {
                setSendPinDisabled(false);
                alert(e.response.data.errors[0].detail);
                return false;
            }
            if (e.response.status === 401) {
                setSendPinDisabled(false);
                alert("The pin you have used is invalid.");
                return false;
            }
        });
    }

    const resendPin = () => {
        Auth.resetPin()
            .then(res => {
                if (res.status === 200) {
                    //Tell user email has been sent
                } else {
                    //Tell user there was an error
                    return false;
                }
            }).catch(e => {
            if (e.response.status === 422) {
                alert(e.response.data.errors[0].detail);
                return false;
            }
        });
    }

    useEffect(() => {
        let inputs = document.getElementsByClassName('a-reactPinField__input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i]['type'] = 'number';
        }
    }, [])

    return (
        <Serveometer page={'Verify Your Account'} showHeader={'true'} render={
            <>
                <div className={'main-container ion-text-center'}>
                    <h1>Check Your Email</h1>
                    <p>We have sent you a pin number to verify you are human, enter the number below.</p>
                    <IonGrid>
                        <IonRow>

                            <IonCol>
                                <PinField
                                    style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: "50%",
                                        border: "1px solid gray",
                                        outline: "none",
                                        textAlign: "center",
                                        margin: 10,
                                        backgroundColor: "#fff!important"
                                    }}
                                    className={classNames("field-a", {"field-a-complete": pinVerified})}
                                    onComplete={(pin) => verifyPin(pin)}
                                    format={k => k.toUpperCase()}
                                    length={4}
                                    autoFocus
                                    disabled={pinVerified}
                                    validate={/^[0-9]$/}
                                />
                            </IonCol>

                        </IonRow>
                    </IonGrid>

                    <IonButton class='mt-20' expand={'full'} disabled={sendPinDisabled} onClick={() => {
                        resendPin()
                    }}>Email me a new pin</IonButton>
                </div>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Verifying...'}
                    duration={20000}
                />
            </>
        }/>
    )
}

export default VerifyAccount;