import React from 'react';
import Serveometer from "../../components/Serveometer";
import {IonCard, IonCardContent} from "@ionic/react";

const RegisterConfirmation: React.FC = () => {
    return (
        <Serveometer showBackButton={true} page={'Registration Confirmation'} showHeader={'true'} render={
            <div>
                <IonCard>
                    <IonCardContent>
                        Please check your email to verify your account.
                    </IonCardContent>
                </IonCard>
            </div>
        }/>
    )
}

export default RegisterConfirmation;