import React, {useEffect, useState} from 'react';
import Serveometer from "../../components/Serveometer";
import Pages from "../../assets/js/Pages";
import {IonImg} from "@ionic/react";

const About: React.FC = () => {

    // Declare empty object
    let init: any = {};
    const [pageDetails, setPageDetails] = useState(init);
    const slug = 'about-serveometer';

    useEffect(() => {
        Pages.getPage(slug).then(response => {
            setPageDetails(response.data);
        }).catch(err => {

        })
    }, []);

    return (
        <Serveometer page={'About'} showbackButton={true} showHeader={'true'} render={
            <div>
                {pageDetails.header_image && pageDetails.header_image.length > 0 && <IonImg src={pageDetails.header_image}/>}
                <div className="ion-padding">
                    <h1>{pageDetails.title}</h1>
                    <div className="ion-padding" dangerouslySetInnerHTML={{
                        __html: pageDetails.content
                    }}/>
                </div>
            </div>
        }/>
    )
}

export default About;